import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { baseURL, successCode } from '../../configuration/url'
import axios from 'axios'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { primarybg, secondarybt } from '../../common/primary'
import './Reports.css'
import { getActivityLegend, getLocationLegend } from '../../slices/Reports/thunk'
import { setActivityLegend, setLocationLegend } from '../../slices/Reports/reducer'

interface Q15PatientReportViewProps {
    ReportData: any
    setLoading: any
}

const Q15PatientReportView: React.FC<Q15PatientReportViewProps> = ({ ReportData, setLoading }) => {
    const { orgData, shiftStartTime, shiftDuration } = useSelector((state: any) => state.Org)
    const org = useSelector((state: any) => state.Login.organization)
    const [OrgName, setOrgName] = useState<any>('')
    let [newTimeSlot, setNewTimeSlot] = useState(new Array<any>());
    const [expanded, setExpanded] = useState<string | false>('panel1');
    const [singleReportDate, setSingleReportDate] = useState<any>('')
    const { activityLegend, locationLegend } = useSelector((state: any) => state.Report)
    const dispatch = useDispatch<any>()

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    useEffect(() => {
        if (!orgData) return
        setOrgName(orgData.organizationdetails[0].name)
    }, [orgData])
    useEffect(() => {
        console.log(ReportData[0].patientName)
    }, [ReportData])
    // location & activity legend
    useEffect(() => {
        getLocationLegend(dispatch, org)
        getActivityLegend(dispatch, org)
        return () => {
            dispatch(setActivityLegend([]))
            dispatch(setLocationLegend([]))
        }
    }, [org])
    function formatDate(epoch: any) {
        let d = new Date(epoch);
        let hours = String((d.getHours())).padStart(2, '0');
        let mins = String((d.getMinutes())).padStart(2, '0');
        return `${hours}:${mins}`;
    }
    useEffect(() => {
        const createTimeSlots = (fromTime: string, toTime: string, slotLength = 15 * 60) => {
            let slotStart = new Date(fromTime).valueOf();
            let slotEnd = new Date(fromTime).valueOf() + slotLength * 1000;
            let endEpoch = new Date(toTime).valueOf();
            let ob = [];
            for (slotEnd; slotEnd <= endEpoch; slotEnd = slotEnd + slotLength * 1000) {
                ob.push(formatDate(slotStart));
                slotStart = slotEnd;
            }
            return ob;
        }
        const from = "2022-05-25 " + shiftStartTime;
        const to = "2022-05-26 " + shiftStartTime;
        const slotLength = 15 * 60;
        var r = createTimeSlots(from, to, slotLength);
        setNewTimeSlot(r);
    }, [])
    const decreaseOneMinute = (timeString: any) => {
        // Split the time string to extract hours and minutes
        if (!timeString) return
        const [hours, minutes] = timeString?.split(':').map(Number);

        // Create a new Date object for today with the given time
        let date = new Date();
        date.setHours(hours, minutes, 0); // Set the time (HH:MM:SS)

        // Subtract 1 minute
        date.setMinutes(date.getMinutes() - 1);

        // Format the new time back to HH:mm format
        const newHours = String(date.getHours()).padStart(2, '0');
        const newMinutes = String(date.getMinutes()).padStart(2, '0');

        return `${newHours}:${newMinutes}`;
    }
    const calculateDisplayTime = (time: string, startTime: string, index: number) => {
        // Parse hours and minutes from startTime
        const [startHour, startMinute] = startTime?.split(":").map(Number);

        // Calculate if the current time crosses midnight
        const currentHour = (startHour + Math.floor((startMinute + index * 15) / 60)) % 24;
        const isNextDay = (startHour + Math.floor((startMinute + index * 15) / 60)) >= 24;

        return `${time}${isNextDay ? " +1" : ""}`;
    };

    const handleDownload = async () => {
        if (ReportData) {
            try {
                setLoading(true);
                let index = 0;
                let myVar = setInterval(() => {
                    if (index < ReportData.length) {
                        const currentIndex = index;
                        setTimeout(() => { timeDealys(ReportData[currentIndex]); }, 0);
                        index++;
                    } else {
                        clearInterval(myVar);
                    }
                }, 0);

                async function timeDealys(reports: any) {
                    // Get all the sections
                    const sections = document.querySelectorAll(`.Q15${moment(reports.date).format("DD-MM-YYYY")}`);

                    // Create a PDF instance
                    const pdf = new jsPDF('p', 'px', 'a4', true);
                    const margin = 20;
                    const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                    const images: any[] = []; // Array to store images of each section
                    let currentY = margin;

                    const addSectionToPDF = (section: any) => {
                        return new Promise<void>((resolve) => {
                            html2canvas(section, {
                                useCORS: true,
                                logging: true,
                                scale: 2,
                                backgroundColor: null,
                            }).then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                images.push(imgData); // Push the image data into the images array
                                resolve();
                            }).catch((error) => {
                                console.error('Error rendering section', error);
                                resolve();
                            });
                        });
                    };

                    // Add all sections to images array
                    const promises = Array.from(sections).map(addSectionToPDF);
                    await Promise.all(promises);

                    // Add the first image to the second page (swap the first and second content)
                    pdf.addImage(images[1], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                    pdf.addPage(); // Create a new page

                    // Add the second image to the first page (swapped content)
                    pdf.addImage(images[0], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);

                    // Save the PDF with the swapped content
                    pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
                    setLoading(false);
                }
            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    };
    const handleSingltDownload = async (str: any) => {
        if (ReportData) {
            try {
                setLoading(true);
                let index = 0;
                let myVar = setInterval(() => {
                    if (index < ReportData.length) {
                        const currentIndex = index;
                        setTimeout(() => {
                            if (moment(ReportData[currentIndex].q15Date).format("DD-MM-YYYY") === str) {
                                timeDealys(ReportData[currentIndex]);
                            }
                        }, 0);
                        index++;
                    } else {
                        clearInterval(myVar);
                    }
                }, 0);

                async function timeDealys(reports: any) {
                    // Get all the sections
                    const sections = document.querySelectorAll(`.Q15report-section${moment(reports.q15Date).format("DD-MM-YYYY")}`);

                    // Create a PDF instance
                    const pdf = new jsPDF('p', 'px', 'a4', true);
                    const margin = 20;
                    const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                    const images: any[] = []; // Array to store images of each section
                    let currentY = margin;

                    const addSectionToPDF = (section: any) => {
                        return new Promise<void>((resolve) => {
                            html2canvas(section, {
                                useCORS: true,
                                logging: true,
                                scale: 2,
                                backgroundColor: null,
                            }).then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                images.push(imgData); // Push the image data into the images array
                                resolve();
                            }).catch((error) => {
                                console.error('Error rendering section', error);
                                resolve();
                            });
                        });
                    };

                    // Add all sections to images array
                    const promises = Array.from(sections).map(addSectionToPDF);
                    await Promise.all(promises);

                    // Add images to the PDF in the correct order
                    images.forEach((imgData, index) => {
                        if (index > 0) pdf.addPage(); // Add a new page for each subsequent section
                        pdf.addImage(imgData, 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                    });

                    // Save the PDF with the content in the correct order
                    pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
                    setLoading(false);
                }

                // async function timeDealys(reports: any) {
                //     // Get all the sections
                //     const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`);

                //     // Create a PDF instance
                //     const pdf = new jsPDF('p', 'px', 'a4', true);
                //     const margin = 20;
                //     const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                //     const images: any[] = []; // Array to store images of each section
                //     let currentY = margin;

                //     const addSectionToPDF = (section: any) => {
                //         return new Promise<void>((resolve) => {
                //             html2canvas(section, {
                //                 useCORS: true,
                //                 logging: true,
                //                 scale: 2,
                //                 backgroundColor: null,
                //             }).then((canvas) => {
                //                 const imgData = canvas.toDataURL('image/png');
                //                 images.push(imgData); // Push the image data into the images array
                //                 resolve();
                //             }).catch((error) => {
                //                 console.error('Error rendering section', error);
                //                 resolve();
                //             });
                //         });
                //     };

                //     // Add all sections to images array
                //     const promises = Array.from(sections).map(addSectionToPDF);
                //     await Promise.all(promises);

                //     // Add the first image to the second page (swap the first and second content)
                //     pdf.addImage(images[1], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                //     pdf.addPage(); // Create a new page

                //     // Add the second image to the first page (swapped content)
                //     pdf.addImage(images[0], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);

                //     // Save the PDF with the swapped content
                //     pdf.save(`${reports.patientName}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
                //     setLoading(false);
                // }
            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    }
    return (
        <div>
            {
                newTimeSlot.length > 0 && newTimeSlot !== null && newTimeSlot !== undefined && ReportData !== undefined && ReportData !== null && ReportData.length > 0 && ReportData?.map((data: any) => (
                    <Accordion expanded={expanded === 'panelmain' + data.q15Date} onChange={handleChange('panelmain' + data.q15Date)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: expanded === 'panelmain' + data.q15Date ? secondarybt : '' }}>
                            <Typography>
                                <div className='d-flex justify-content-between' style={{ width: '100vh' }}>
                                    <div style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                                        {moment(data.q15Date).format("MMM DD, YYYY")}
                                    </div>
                                </div>
                            </Typography>
                            <div>
                                <button className='btn' style={{ backgroundColor: primarybg, border: 'none', width: 'fit-content', marginLeft: '5px', color: 'white' }} onClick={() => {
                                    handleSingltDownload(moment(data.q15Date).format("DD-MM-YYYY"))
                                }} >Download</button>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className='hidden-scrollbar pb-3' style={{ overflow: 'auto', height: '70vh' }}>
                                <div className={`Q15report-section${moment(data.q15Date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}1`}>
                                    <div id="removePadding" style={{ textAlign: 'center' }} className="">
                                        {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null}
                                    </div>
                                    <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="">
                                        {OrgName}
                                    </div>
                                    <div id="removePadding" style={{ textAlign: 'right', width: "48%", fontSize: '17px', fontWeight: '600', display: "flex", justifyContent: "flex-start", position: "relative", top: "28px" }}>Date:<span style={{ fontWeight: '600', marginLeft: '5px' }}> {moment(data.q15Date).format("MMM DD, YYYY")}</span></div>
                                    <div style={{ display: 'flex', marginLeft: '70px' }}>
                                        <div id="removePadding" style={{ textAlign: 'right', width: "48%", fontSize: '17px', fontWeight: '600', marginLeft: '130px' }}>Patient Name: </div>
                                        <div style={{ width: '2%' }}></div>
                                        <div id="removePadding" style={{ textAlign: "left", width: "50%", fontSize: '17px', paddingLeft: '5px', fontWeight: '600' }} >{data.patientName}</div>
                                    </div>

                                    <div style={{ border: '1px solid #C9C9C9' }}>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                            <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                                <div>
                                                    CHECK PRECAUTIONS:
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Every 15 Minutes
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled checked style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        1:1
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Eyesight
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Agitated Behavior
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Arson
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Assault
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Fall
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Detox
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Elopement
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Homicide
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Sexual Acting Out
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Self Harm
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Other {`${'('}`}Describe{`${'):'}`}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                            <div>LOCATION CODES</div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                                    <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            {item.key}-{item.value}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                            <div>ACTIVITY CODES</div>
                                        </div>
                                        <div>
                                            <div className='d-flex flex-row flex-wrap py-1'
                                            //  style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}
                                            >
                                                {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                                    <div className="col-3 px-1 " style={{ height: '30px' }} title={item.value} key={inx}>
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            {inx + 1}.{item.value}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>


                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '15px', fontWeight: '600' }}>
                                        <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                        <div style={{ width: '42%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                        <div style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div>
                                        <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                        <div style={{ width: '42%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                        <div style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div>
                                        {/* <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div> */}
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', fontSize: '14px' }}>
                                        <div style={{ width: '50%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 0 && t <= 23 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '21.5%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                        <div style={{ width: '44.5%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}
                                                        </div>
                                                        <div style={{ width: '34%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}
                                                        </div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>

                                        <div style={{ width: '50%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 24 && t <= 47 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '21.5%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                        <div style={{ width: '44.5%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '34%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                        {/* <div style={{ width: '33%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 64 && t <= 79 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>   {calculateDisplayTime(s, shiftStartTime, t)}

                                                        </div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div> */}
                                    </div>
                                    {/* <div style={{ width: '100%', display: 'flex', fontSize: '14px' }}>
                                        <div style={{ width: '34%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 16 && t <= 31 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                        <div style={{ width: '33%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 48 && t <= 63 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>

                                        <div style={{ width: '33%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 80 && t <= 95 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s} +1</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1]).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1] && m.locationName !== "" && m.locationName !== null).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1] && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1] && m.activityName !== "" && m.activityName !== null).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                    </div> */}

                                    <div style={{ fontSize: '14px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                        </div>
                                        {[1, 2, 3, 4].map((t: any, indx: number) => (
                                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ fontSize: '14px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={`mt-5 Q15report-section${moment(data.q15Date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}2`}>
                                    <div id="removePadding" style={{ textAlign: 'center' }} className="">
                                        {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null}
                                    </div>
                                    <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="">
                                        {OrgName}
                                    </div>
                                    <div id="removePadding" style={{ textAlign: 'right', width: "48%", fontSize: '17px', fontWeight: '600', display: "flex", justifyContent: "flex-start", position: "relative", top: "28px" }}>Date:<span style={{ fontWeight: '600', marginLeft: '5px' }}> {moment(data.q15Date).format("MMM DD, YYYY")}</span></div>
                                    <div style={{ display: 'flex', marginLeft: '70px' }}>
                                        <div id="removePadding" style={{ textAlign: 'right', width: "48%", fontSize: '17px', fontWeight: '600', marginLeft: '130px' }}>Patient Name: </div>
                                        <div style={{ width: '2%' }}></div>
                                        <div id="removePadding" style={{ textAlign: "left", width: "50%", fontSize: '17px', paddingLeft: '5px', fontWeight: '600' }} >{data.patientName}</div>
                                    </div>

                                    <div style={{ border: '1px solid #C9C9C9' }}>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                            <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                                <div>
                                                    CHECK PRECAUTIONS:
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Every 15 Minutes
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled checked style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        1:1
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Eyesight
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Agitated Behavior
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Arson
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Assault
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Fall
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Detox
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Elopement
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Homicide
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Sexual Acting Out
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Self Harm
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Other {`${'('}`}Describe{`${'):'}`}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                            <div>LOCATION CODES</div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                                    <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            {item.key}-{item.value}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                            <div>ACTIVITY CODES</div>
                                        </div>
                                        <div>
                                            <div className='d-flex flex-row flex-wrap py-1'
                                            //  style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}
                                            >
                                                {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                                    <div className="col-3 px-1 " style={{ height: '30px' }} title={item.value} key={inx}>
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            {inx + 1}.{item.value}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>


                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', fontSize: '15px', fontWeight: '600' }}>
                                        <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                        <div style={{ width: '42%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                        <div style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div>
                                        <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                        <div style={{ width: '42%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                        <div style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div>
                                        {/* <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div> */}
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', fontSize: '14px' }}>
                                        <div style={{ width: '50%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 48 && t <= 71 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '21.5%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                        <div style={{ width: '44.5%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}
                                                        </div>
                                                        <div style={{ width: '34%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}
                                                        </div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>

                                        <div style={{ width: '50%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot?.map((s: any, t: number) => {
                                                return t >= 72 && t <= 95 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '21.5%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                        <div style={{ width: '44.5%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data?.data?.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '34%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                        {/* <div style={{ width: '33%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 64 && t <= 79 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>   {calculateDisplayTime(s, shiftStartTime, t)}

                                                        </div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div> */}
                                    </div>
                                    {/* <div style={{ width: '100%', display: 'flex', fontSize: '14px' }}>
                                        <div style={{ width: '34%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 16 && t <= 31 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                        <div style={{ width: '33%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 48 && t <= 63 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>

                                        <div style={{ width: '33%' }}>
                                            {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                return t >= 80 && t <= 95 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s} +1</div>
                                                        <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1]).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1] && m.locationName !== "" && m.locationName !== null).map((n: any) => { return n.locationName }) : ""}</div>
                                                        <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                            {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1] && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1] && m.activityName !== "" && m.activityName !== null).map((n: any) => { return n.activityName }) : ""}</div>
                                                    </div>
                                                </>)
                                            })}
                                        </div>
                                    </div> */}

                                    <div style={{ fontSize: '14px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                        </div>
                                        {[1, 2, 3, 4].map((t: any, indx: number) => (
                                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ fontSize: '14px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                                        </div>
                                    </div>
                                </div>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                ))

            }


            {/* {
                ReportData !== undefined && ReportData !== null && ReportData.length > 0 && ReportData?.map((data: any) => (
                    
                ))
            } */}



        </div>
    )
}

export default Q15PatientReportView