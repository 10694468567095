import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Typography } from "@mui/material";
import { Button } from "reactstrap";
import { FaInbox, FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import "./shiftAllocation.css";
import { baseURL, successCode } from "../../configuration/url";
import axios from "axios";
import { authorize } from "../../helpers/common";
import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";
import moment from "moment";
interface Shift {
  shift_name: string;
  start_time: string;
  end_time: string;
  dayShift: string;
}
const ShiftListTable = ({
  allTeamData,
  noOfShift,
  value,
  staffDropdown,
  getAllTeam,
  getAllStaffByTeam,
  selectedDate,
}: any) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { shiftStartTime } = useSelector((state: any) => state.Org);

  const [filteredData, setFilteredData] = useState<any>([]);
  const { header1 } = authorize();
  const [Loading, setLoading] = useState<boolean>(false);
  const [Data, setData] = useState<any>([]);
  const { organization } = useSelector((state: any) => state.Login);
  const [shifts, setShifts] = useState<any>([]);
  const startTime = new Date(`2000-01-01T${shiftStartTime}`);
  const { orgData } = useSelector((state: any) => state.Org);

  const generateShifts = (
    startTimeStr: any,
    duration: any,
    totalShifts: any
  ) => {
    const shifts = [];

    // Convert the start time string to a Date object
    let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

    // Function to convert 24-hour time to 12-hour time with AM/PM
    const to12HourFormat = (date: any) => {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";

      // Convert hour to 12-hour format
      hours = hours % 12;
      if (hours === 0) {
        hours = 12; // Midnight or noon case
      }

      // Add leading zero for minutes if needed
      minutes = minutes < 10 ? "0" + minutes : minutes;

      // Return formatted time with AM/PM
      return `${hours}:${minutes} ${ampm}`;
    };

    for (let i = 0; i < totalShifts; i++) {
      // Calculate the end time by adding the duration to the start time
      let endTime = new Date(
        startTime.getTime() + Number(duration) * 60 * 60 * 1000
      ); // duration in hours

      // Format start and end times into 12-hour format with AM/PM
      const startTimeFormatted = to12HourFormat(startTime);
      const endTimeFormatted = to12HourFormat(endTime);

      // Assign shift name (A, B, C, etc.)
      const shiftName = String.fromCharCode(65 + i); // 65 is the ASCII value of 'A'

      // Push shift details into the array
      shifts.push({
        shift_name: shiftName,
        start_time: startTimeFormatted,
        end_time: endTimeFormatted,
        dayShift: "",
      });

      // Update the start time for the next shift
      startTime = endTime;
    }
    const convertTo24Hour = (time: string) => {
      const [hour, minuteWithPeriod] = time.split(":");
      const [minute, period] = minuteWithPeriod.split(" ");
      let hour24 = parseInt(hour);

      // Handle AM/PM conversion
      if (period === "PM" && hour24 !== 12) hour24 += 12; // Convert PM to 24-hour
      if (period === "AM" && hour24 === 12) hour24 = 0; // Handle midnight (12 AM)

      return hour24 * 60 + parseInt(minute); // Convert to minutes for easier comparison
    };

    // Function to determine if a shift crosses midnight and should have +1 added
    const addDayShift = (shift: Shift, addShift: boolean): Shift => {
      const startInMinutes = convertTo24Hour(shift.start_time);
      const endInMinutes = convertTo24Hour(shift.end_time);

      // If the end time is earlier than the start time, it means it crosses midnight
      if (endInMinutes < startInMinutes) {
        return { ...shift, dayShift: "+1" };
      }

      // If previous shift had +1, add +1 to the current shift
      if (addShift) {
        return { ...shift, dayShift: "+1" };
      }

      // No need for +1 if the shift doesn't cross midnight and no previous shift had +1
      return { ...shift, dayShift: "" };
    };
    // A flag to track if we should start adding +1
    let addShift = false;

    // Apply the dayShift logic to each shift
    const updatedShifts = shifts.map((shift: any) => {
      // If we encounter a shift that crosses midnight, set addShift to true
      if (
        !addShift &&
        convertTo24Hour(shift.end_time) < convertTo24Hour(shift.start_time)
      ) {
        addShift = true;
      }
      return addDayShift(shift, addShift);
    });
    setShifts(updatedShifts);
  };

  const renderTabContent = () => {
    switch (value) {
      case "Shift-A":
        return 0;
      case "Shift-B":
        return 1;
      case "Shift-C":
        return 2;
      case "Shift-D":
        return 3;
      case "Shift-E":
        return 4;
      case "Shift-F":
        return 5;
      case "General":
        return 6;
      default:
        return "No Content Available";
    }
  };
  useEffect(() => {
    setLoading(true);
    generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);

    if (allTeamData?.length === 0 || allTeamData === undefined) {
      setData(staffDropdown);
      setLoading(false);
    } else {
      setData(allTeamData);
      setLoading(false);
    }
  }, [allTeamData, value, staffDropdown]);

  const handleCheckboxChange = (id: string) => {
    setData((prevData: any[]) =>
      prevData.map((item: any) =>
        item.id === id ? { ...item, checkIn: !item?.checkIn } : item
      )
    );

    setFilteredData((prevData: any[]) =>
      prevData.map((item: any) =>
        item.id === id ? { ...item, checkIn: !item?.checkIn } : item
      )
    );
  };

  const handleSaveStaff = async () => {
    let filteredData = Data?.filter((data: any) => data.checkIn === true);
    console.log(filteredData,'filteredData');
    const staffIds = Data?.map((staff: any) => ({
      staffId: staff.id,
      staffName: staff.name,
      checkIn: staff.checkIn,
    }));
    let formData = {
      organization: organization,
      shiftName: value,
      staff: staffIds,
      startDate: moment(selectedDate)?.format("YYYYMMDD"),
    };
    if(filteredData.length === 0){
      toast.error('Please select the staff');
    }else{
      try {
        setLoading(true);
  
        const response = await axios.post(`${baseURL}/shift/register`, formData, {
          headers: header1,
        });
        if (response.data.message && response.data.message.code === successCode) {
          toast.success(response.data.message.description);
          setLoading(false);
          getAllStaffByTeam();
          getAllTeam();
        }
      } catch (error) {
        setLoading(false);
      }
    }
   
  };

  const handleSearch = (e: any) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query) {
      const filtered = Data?.filter((item: any) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(Data);
    }
  };

  return (
    <div>
      {Loading && <Loader />}

      <div className="row" style={{ position: "relative" }}>
        <div
          className="col-md-8 d-flex align-items-center"
          style={{ fontSize: "14px" }}
        >
          <span>
            {renderTabContent() === 6
              ? shifts[0]?.start_time
              : shifts[renderTabContent()]?.start_time}{" "}
            {""} -
          </span>

          <span style={{ marginLeft: "5px" }}>
            {renderTabContent() === 6
              ? shifts[0]?.end_time
              : shifts[renderTabContent()]?.end_time}{" "}
            {""} {shifts[renderTabContent()]?.dayShift}
          </span>
        </div>
        <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
          <div className="mx-0 search-container d-flex align-items-center">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              className="search form-control"
              onChange={(e) => handleSearch(e)}
            />
            <FaSearch className="search-icon" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3"></div>
        </div>

        <div>
          <>
            <div className="row" style={{ overflow: "auto", height: "220px" }}>
              <div>
                <div
                  className="row"
                  style={{ width: "100%", flexWrap: "wrap" }}
                >
                  {filteredData?.length > 0 ? (
                    filteredData.map((item: any) => (
                      <div key={item.id} className="col-md-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            padding: "5px",
                          }}
                        >
                          <Checkbox
                            checked={item?.checkIn}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                          <div>{item.name}</div>
                        </div>
                      </div>
                    ))
                  ) : Data.length > 0 ? (
                    Data?.map((item: any, index: number) => (
                      <div key={item.id} className="col-md-3">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            padding: "5px",
                          }}
                        >
                          <Checkbox
                            checked={item?.checkIn}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                          <div>{item.name}</div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <Typography variant="body1" className="table_empty">
                        <FaInbox className="empty_svg" />
                        No Staff found for this Shift
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </div>
            <Divider />
            <Button
              style={{
                float: "right",
                marginTop: "10px",
                backgroundColor: "#0f3995",
              }}
              onClick={() => handleSaveStaff()}
            >
              Save Changes
            </Button>
          </>
        </div>
      </div>
    </div>
  );
};

export default ShiftListTable;
