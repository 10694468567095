import React, { useEffect, useState } from 'react'
import ReportView from './ReportView'
import axios from 'axios'
import { baseURL, successCode } from '../../configuration/url'
import { useDispatch, useSelector } from 'react-redux'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import moment from 'moment'
import './Reports.css'
import { primarybg, secondarybt } from '../../common/primary'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { getActivityLegend, getLocationLegend } from '../../slices/Reports/thunk'
import { setActivityLegend, setLocationLegend } from '../../slices/Reports/reducer'
interface OnetoOnePatientReportViewProps {
  onetooneReportData: any
  selectShiftView: any
  selectShift: any
  nextDate: any
  checkPrecaution: any
  checkBox: any
  legendcontrol: any
  setLoading: any
}

const OnetoOnePatientReportView: React.FC<OnetoOnePatientReportViewProps> = ({ onetooneReportData, selectShift, selectShiftView, checkBox, checkPrecaution, legendcontrol, nextDate, setLoading }) => {
  const { orgData, shiftStartTime, shiftDuration } = useSelector((state: any) => state.Org)
  const org = useSelector((state: any) => state.Login.organization)
  const [onetoOnedata, setOnetoOneData] = useState<any>([])
  const [OrgName, setOrgName] = useState<any>('')
  const [expanded, setExpanded] = useState<string | false>('panel1');

  const { activityLegend, locationLegend } = useSelector((state: any) => state.Report)
  const dispatch = useDispatch<any>()

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (!orgData) return
    setOrgName(orgData.organizationdetails[0].name)
  }, [orgData])

  useEffect(() => {
    if (onetooneReportData !== null && onetooneReportData.length > 0) {
      const filterData = onetooneReportData && onetooneReportData?.filter((item: any) => item.shiftAIncharge || item.shiftATime || item.shiftBIncharge || item.shiftBTime || item.shiftCIncharge || item.shiftCTime)
      setOnetoOneData(filterData)
    } else {
      setOnetoOneData([])
    }
  }, [onetooneReportData])


  useEffect(() => {
    getLocationLegend(dispatch, org)
    getActivityLegend(dispatch, org)
    return () => {
      dispatch(setActivityLegend([]))
      dispatch(setLocationLegend([]))
    }
  }, [org])

  const handleSingltDownload = async (str: any) => {
    if (onetooneReportData) {
      try {
        setLoading(true);
        let index = 0;
        let myVar = setInterval(() => {
          if (index < onetooneReportData.length) {
            const currentIndex = index;
            setTimeout(() => {
              if (moment(onetooneReportData[currentIndex].date).format("DD-MM-YYYY") === str) {
                timeDealys(onetooneReportData[currentIndex]);
              }
            }, 0);
            index++;
          } else {
            clearInterval(myVar);
          }
        }, 0);
        async function timeDealys(reports: any) {
          // Get all the sections
          const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`);

          // Create a PDF instance
          const pdf = new jsPDF('p', 'px', 'a4', true);
          const margin = 20;
          const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
          const images: any[] = []; // Array to store images of each section
          let currentY = margin;

          const addSectionToPDF = (section: any) => {
            return new Promise<void>((resolve) => {
              html2canvas(section, {
                useCORS: true,
                logging: true,
                scale: 2,
                backgroundColor: null,
              }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                images.push(imgData); // Push the image data into the images array
                resolve();
              }).catch((error) => {
                console.error('Error rendering section', error);
                resolve();
              });
            });
          };

          // Add all sections to images array
          const promises = Array.from(sections).map(addSectionToPDF);
          await Promise.all(promises);

          // Add images to the PDF in the correct order
          images.forEach((imgData, index) => {
            if (index > 0) pdf.addPage(); // Add a new page for each subsequent section
            pdf.addImage(imgData, 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
          });

          // Save the PDF with the content in the correct order
          pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
          setLoading(false);
        }

        // async function timeDealys(reports: any) {
        //     // Get all the sections
        //     const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`);

        //     // Create a PDF instance
        //     const pdf = new jsPDF('p', 'px', 'a4', true);
        //     const margin = 20;
        //     const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
        //     const images: any[] = []; // Array to store images of each section
        //     let currentY = margin;

        //     const addSectionToPDF = (section: any) => {
        //         return new Promise<void>((resolve) => {
        //             html2canvas(section, {
        //                 useCORS: true,
        //                 logging: true,
        //                 scale: 2,
        //                 backgroundColor: null,
        //             }).then((canvas) => {
        //                 const imgData = canvas.toDataURL('image/png');
        //                 images.push(imgData); // Push the image data into the images array
        //                 resolve();
        //             }).catch((error) => {
        //                 console.error('Error rendering section', error);
        //                 resolve();
        //             });
        //         });
        //     };

        //     // Add all sections to images array
        //     const promises = Array.from(sections).map(addSectionToPDF);
        //     await Promise.all(promises);

        //     // Add the first image to the second page (swap the first and second content)
        //     pdf.addImage(images[1], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
        //     pdf.addPage(); // Create a new page

        //     // Add the second image to the first page (swapped content)
        //     pdf.addImage(images[0], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);

        //     // Save the PDF with the swapped content
        //     pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
        //     setLoading(false);
        // }
      } catch (error: any) {
        console.error("Download Error: ", error);
        setLoading(false);
      }
    }
  }
  return (
    <div >
      {
        onetoOnedata !== null && onetoOnedata !== undefined && onetoOnedata.length > 0 && onetoOnedata?.map((data: any, inx: number) => (
          <Accordion expanded={expanded === 'panelmain' + data.date} onChange={handleChange('panelmain' + data.date)} style={{ width: '100%' }}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: expanded === 'panelmain' + data.date ? secondarybt : '' }} >
              <Typography>
                <div className='d-flex justify-content-between' style={{ width: '100vh' }}>
                  <div className='' style={{ fontFamily: "poppins", fontSize: "18px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                    {moment(data.date).format("MMM DD, YYYY")}
                  </div>
                </div>
              </Typography>
              <div>
                <button className='btn' style={{ backgroundColor: primarybg, border: 'none', width: 'fit-content', marginLeft: '5px', color: 'white' }} onClick={() => {
                  handleSingltDownload(moment(data.date).format("DD-MM-YYYY"))
                }}>Download</button>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='hidden-scrollbar pb-3' style={{ overflow: 'auto', height: '70vh' }} >
                <div className='container-fluid'>
                  {/* Report Time Am View Start */}
                  <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}1`}>
                    {data && <>
                      <div id="removePadding" style={{ textAlign: 'center' }} className="">
                        {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null}
                        {/* {orgData && orgData.logo ? (
                              <Avatar
                                sx={{ width: 50, height: 50, backgroundColor: '#9F9FA2', marginRight: '', textAlign: 'center' }}
                                src={orgData.logo}
                              >
                              </Avatar>) : <></>} */}
                      </div>
                      <div id="removePadding" style={{ fontSize: '26px', fontWeight: 'bold', textAlign: 'center' }} className="">
                        {OrgName && OrgName}
                      </div>
                      {/* <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="p-col-12 p-md-12">
                                                              Mettler Health Care Organization
                                                            </div> */}
                      <div className='d-flex justify-content-between'>
                        <div className='col-8'>
                          <div className='d-flex flex-row gap-3'>
                            <div><strong>Date: </strong>
                              {data.date && moment(data.date).format("MMM DD, YYYY")}
                              {selectShiftView ? selectShift === "Shift-C" && `, ${nextDate}` : ''}
                            </div>
                            {selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>}
                          </div>
                          <strong>Patient Name: </strong>{data.patientId}
                        </div>
                        <div className='d-flex justify-content-between col-4'>
                          <div>
                            <strong>Room No: </strong>
                            {data.assignedBed && data.assignedBed.split('-')[0]}
                          </div>
                          <div>
                            <strong className='pe-3'>AssignedBed:</strong>
                            {data.assignedBed && data.assignedBed.split('-')[1]}
                          </div>
                        </div>
                      </div>
                      <div style={{ border: '1px solid #C9C9C9' }}>
                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                          <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                            <div>
                              CHECK PRECAUTIONS:
                            </div>
                            <div style={{ display: 'flex', gap: '3px' }}>
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Every 15 Minutes
                              </label>
                              <div className="form-check" style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              </div>
                            </div>
                            <div style={{ display: 'flex', gap: '3px' }}>
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                1:1
                              </label>
                              <div className="form-check" style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled checked style={{ padding: '7px', border: '1.2px solid black' }} />
                              </div>
                            </div>
                            <div style={{ display: 'flex', gap: '3px' }}>
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Eyesight
                              </label>
                              <div className="form-check" style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Agitated Behavior
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Arson
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Assault
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Fall
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Detox
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Elopement
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Homicide
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Sexual Acting Out
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Self Harm
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Other {`${'('}`}Describe{`${'):'}`}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                          <div>LOCATION CODES</div>
                        </div>
                        <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                            {locationLegend && locationLegend?.map((item: any, inx: number) => (
                              <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  {item.key}-{item.value}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                          <div>ACTIVITY CODES</div>
                        </div>
                        <div>
                          {/* <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                                                        {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                                          <div className="  " style={{ height: '30px', width: '20%' }} key={inx}>
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                              {inx + 1}.{item.value}
                                                            </label>
                                                          </div>
                                                        ))}
                                                      </div> */}
                          <div className='d-flex flex-row flex-wrap py-1'
                          //  style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}
                          >
                            {activityLegend && activityLegend?.map((item: any, inx: number) => (
                              <div className="col-3 px-1 " style={{ height: '30px' }} title={item.value} key={inx}>
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  {inx + 1}.{item.value}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {!selectShiftView ? <>
                        <div className='' style={{ fontSize: '14px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                            <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                            <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                          </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                          <div style={{ width: '50%', fontSize: '14px' }}>
                            {data && data.configResponses?.map((t: any, ind: number) => {
                              return ind >= 0 && ind <= 23 && (<>
                                <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                  <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                  <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                  <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                  <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                  <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                </div>
                              </>);
                            })}
                          </div>
                          <div style={{ width: '50%', fontSize: '14px' }}>
                            {data && data.configResponses?.map((t: any, ind: number) => {
                              return ind >= 24 && ind <= 47 && (<>
                                <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                  <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                  <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                  <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                  <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                  <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                  <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                </div>
                              </>);
                            })}
                          </div>
                        </div>
                      </>
                        :
                        <>
                          {/*  Report Shift based View Start */}
                          <div>
                            <div className='mt-3' style={{ fontSize: '14px' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                              </div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                              <div style={{ width: '50%', fontSize: '14px' }}>
                                {data && data.configResponses?.map((t: any, ind: number) => {
                                  return ind >= 0 && ind <= 15 && (<>
                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                      <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]} {selectShift === "Shift-C" && t.timeSlot.split(' ')[1] === "AM" && "+1"}</div>
                                      <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                      <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                      <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                      <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                    </div>
                                  </>);
                                })}
                              </div>
                              <div style={{ width: '50%', fontSize: '14px' }}>
                                {data && data.configResponses?.map((t: any, ind: number) => {
                                  return ind >= 16 && ind <= 31 && (<>
                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                      <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}{selectShift === "Shift-C" && t.timeSlot.split(' ')[1] === "AM" && "+1"}</div>
                                      <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                      <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                      <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                      <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                      <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                    </div>
                                  </>);
                                })}
                              </div>
                            </div>
                          </div>
                          {/* Report Shift based View End */}
                        </>}

                      <div style={{ fontSize: '14px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                        </div>
                        {[1, 2, 3, 4].map((t: any, indx: number) => (
                          <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                          </div>
                        ))}
                      </div>
                      <div style={{ fontSize: '14px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                          <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                          <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                          <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                          <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                        </div>
                      </div>
                      {/* Previous Code Start */}
                      {/* <div style={{ width: '100%', backgroundColor: '#EAF2FA', display: 'flex', flexDirection: 'row', gap: '5px', paddingTop: '5px', paddingBottom: '5px' }}>
                                                              <div style={{ width: '45%', fontSize: '13px', paddingLeft: '10px', paddingRight: '5px' }}>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black', fontWeight: '600' }}>
                                                                  <div style={{ width: '25%' }}>Location</div>
                                                                  <div style={{ width: '25%' }}>Code</div>
                                                                  <div style={{ width: '25%' }}>Location</div>
                                                                  <div style={{ width: '25%' }}>Code</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>PT Room</div>
                                                                  <div style={{ width: '25%' }}>R</div>
                                                                  <div style={{ width: '25%' }}>Conf Rm</div>
                                                                  <div style={{ width: '25%' }}>C</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>Day Room</div>
                                                                  <div style={{ width: '25%' }}>DR</div>
                                                                  <div style={{ width: '25%' }}>Mid Line</div>
                                                                  <div style={{ width: '25%' }}>L</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>Dining RM</div>
                                                                  <div style={{ width: '25%' }}>D</div>
                                                                  <div style={{ width: '25%' }}>Quiet RM</div>
                                                                  <div style={{ width: '25%' }}>QR</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>Nurses ST.</div>
                                                                  <div style={{ width: '25%' }}>N</div>
                                                                  <div style={{ width: '25%' }}>Shower</div>
                                                                  <div style={{ width: '25%' }}>S</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>Telephone</div>
                                                                  <div style={{ width: '25%' }}>T</div>
                                                                  <div style={{ width: '25%' }}>Seclusion</div>
                                                                  <div style={{ width: '25%' }}>SC</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>BathRoom</div>
                                                                  <div style={{ width: '25%' }}>B</div>
                                                                  <div style={{ width: '25%' }}>Hall</div>
                                                                  <div style={{ width: '25%' }}>H</div>
                                                                </div>
                                                              </div>
                                                              <div style={{ width: '55%', fontSize: '13px', paddingLeft: '0px', paddingRight: '5px' }}>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black' }}>
                                                                  <div style={{ width: '100%', fontWeight: '600' }}>Behavior Code: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; See 1-10</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>1. Sleeping (3 respirations observed)</div>
                                                                  <div style={{ width: '44%' }}>7. Oppositional</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>2. Calm, interacting appropriately</div>
                                                                  <div style={{ width: '44%' }}>8. Bizarre behavior</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>3. Withdrawn/Quiet</div>
                                                                  <div style={{ width: '44%' }}>9. Shouting/loud/agitated</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>4. Crying, tearful</div>
                                                                  <div style={{ width: '44%' }}>10. Out of control, aggressive</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>5. Anxious, restless</div>
                                                                  <div style={{ width: '44%' }}></div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>6. Elated, hyperactive, manic</div>
                                                                  <div style={{ width: '44%' }}></div>
                                                                </div>
                                                              </div>
                                                            </div> */}

                      {/* <article style={{ width: '100%' }} className=''>
                                                              <div style={{ width: '100%', fontSize: '13px' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontWeight: '600' }}>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                                                                </div>
                                                              </div>
                                                              <div style={{ width: '100%', fontSize: '13px' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                </div>
                                                              </div>
                                                              <div style={{ width: '100%', fontSize: '13px' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                </div>
                                                              </div>
                                                              <div style={{ width: '100%', fontSize: '13px' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                </div>
                                                              </div>
                                                              <div style={{ width: '100%', fontSize: '13px' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'auto' }}>
                                                                  <div style={{ width: '100%', border: '1px solid #C9C9C9', paddingBottom: '10px' }}>
                                                                    <div style={{ padding: '1px 7px', fontWeight: '600' }}>PRECAUTIONS MEANING: </div>
                                                                    <div style={{ display: 'flex', flex: 'row', width: '100%' }}>
                                                                      <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEXUALLY ACTING OUT (SAO)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            SAO: SEXUALLY ACTING OUT
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ACTIVE SUICIDE PREC (ASP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            ASP: ACTIVE SUICIDE PREC.
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "FALL PREC (FP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            FP: FALL PREC.
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "GEN SAFETY PREC (GSP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            GSP: GEN SAFETY PREC.
                                                                          </label>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-ETOH")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            DETOX-ETOH
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-DRUG")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            DETOX-DRUG
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ASSAULT PREC (AP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            AP: ASSAULT PREC.
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ELOPEMENT PREC (EP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            EP: ELOPEMENT PREC
                                                                          </label>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ONE TO ONE (1:1)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            1:1: ONE TO ONE
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "OBSERVATION")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            OBSERVATION
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEIZURE PREC (SP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            SZ: SEIZURE PREC.
                                                                          </label>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                </div>
                                                              </div>
                                                            </article> */}

                      {/* Previous Code End */}
                    </>}
                  </section>
                  {/*  Report Time AM View End  */}
                  {/* Report Time PM View Start  */}
                  {!selectShiftView && <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}2`}>
                    {data && <>
                      <div id="removePadding" style={{ textAlign: 'center' }} className="">
                        {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null}
                        {/* {orgData && orgData.logo ? (
                                                        <Avatar
                                                          sx={{ width: 50, height: 50, backgroundColor: '#9F9FA2', marginRight: '', textAlign: 'center' }}
                                                          src={orgData.logo}
                                                        >
                                                        </Avatar>) : <></>} */}
                      </div>
                      <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="">
                        {OrgName && OrgName}
                      </div>
                      <div className='d-flex justify-content-between'>
                        <div className='col-8'>
                          <div className='d-flex flex-row gap-3'>
                            <div><strong>Date: </strong>{data.date && moment(data.date).format("ddd DD,YYYY")}</div>
                            {selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>}
                          </div>
                          <strong>Patient Name: </strong>{data.patientId}
                        </div>
                        <div className='d-flex justify-content-between col-4'>
                          <div>
                            <strong>Room No: </strong>
                            {data.assignedBed && data.assignedBed.split('-')[0]}
                          </div>
                          <div>
                            <strong className='pe-3'>AssignedBed:</strong>
                            {data.assignedBed && data.assignedBed.split('-')[1]}
                          </div>
                        </div>
                      </div>
                      <div style={{ border: '1px solid #C9C9C9' }}>
                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                          <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                            <div>
                              CHECK PRECAUTIONS:
                            </div>
                            <div style={{ display: 'flex', gap: '3px' }}>
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Every 15 Minutes
                              </label>
                              <div className="form-check" style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              </div>
                            </div>
                            <div style={{ display: 'flex', gap: '3px' }}>
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                1:1
                              </label>
                              <div className="form-check" style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled checked style={{ padding: '7px', border: '1.2px solid black' }} />
                              </div>
                            </div>
                            <div style={{ display: 'flex', gap: '3px' }}>
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Eyesight
                              </label>
                              <div className="form-check" style={{ height: '15px' }}>
                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Agitated Behavior
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Arson
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Assault
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Fall
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Detox
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Elopement
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Homicide
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Sexual Acting Out
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Self Harm
                              </label>
                            </div>
                            <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                              <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                              <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                Other {`${'('}`}Describe{`${'):'}`}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                          <div>LOCATION CODES</div>
                        </div>
                        <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                            {locationLegend && locationLegend?.map((item: any, inx: number) => (
                              <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  {item.key}-{item.value}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                          <div>ACTIVITY CODES</div>
                        </div>
                        <div>
                          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                            {activityLegend && activityLegend?.map((item: any, inx: number) => (
                              <div className="  " style={{ height: '30px', width: '20%' }} key={inx}>
                                <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                  {inx + 1}.{item.value}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className='' style={{ fontSize: '14px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                          <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                          <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                          <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                          <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                          <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                          <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                          <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                          <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                          <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                          <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                        </div>
                      </div>
                      <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                        <div style={{ width: '50%', fontSize: '14px' }}>
                          {data && data.configResponses?.map((t: any, ind: number) => {
                            return ind >= 48 && ind <= 71 && (<>
                              <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                              </div>
                            </>);
                          })}
                        </div>
                        <div style={{ width: '50%', fontSize: '14px' }}>
                          {data && data.configResponses?.map((t: any, ind: number) => {
                            return ind >= 72 && ind <= 95 && (<>
                              <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}{t.timeSlot.split(' ')[1] === "AM" ? "+1" : ""}</div>
                                <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                              </div>
                            </>);
                          })}
                        </div>
                      </div>
                      {/* <div style={{ width: '100%', backgroundColor: '#EAF2FA', display: 'flex', flexDirection: 'row', gap: '5px', paddingTop: '5px', paddingBottom: '5px' }}>
                                                              <div style={{ width: '45%', fontSize: '13px', paddingLeft: '10px', paddingRight: '5px' }}>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black', fontWeight: '600' }}>
                                                                  <div style={{ width: '25%' }}>Location</div>
                                                                  <div style={{ width: '25%' }}>Code</div>
                                                                  <div style={{ width: '25%' }}>Location</div>
                                                                  <div style={{ width: '25%' }}>Code</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>PT Room</div>
                                                                  <div style={{ width: '25%' }}>R</div>
                                                                  <div style={{ width: '25%' }}>Conf Rm</div>
                                                                  <div style={{ width: '25%' }}>C</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>Day Room</div>
                                                                  <div style={{ width: '25%' }}>DR</div>
                                                                  <div style={{ width: '25%' }}>Mid Line</div>
                                                                  <div style={{ width: '25%' }}>L</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>Dining RM</div>
                                                                  <div style={{ width: '25%' }}>D</div>
                                                                  <div style={{ width: '25%' }}>Quiet RM</div>
                                                                  <div style={{ width: '25%' }}>QR</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>Nurses ST.</div>
                                                                  <div style={{ width: '25%' }}>N</div>
                                                                  <div style={{ width: '25%' }}>Shower</div>
                                                                  <div style={{ width: '25%' }}>S</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>Telephone</div>
                                                                  <div style={{ width: '25%' }}>T</div>
                                                                  <div style={{ width: '25%' }}>Seclusion</div>
                                                                  <div style={{ width: '25%' }}>SC</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '25%' }}>BathRoom</div>
                                                                  <div style={{ width: '25%' }}>B</div>
                                                                  <div style={{ width: '25%' }}>Hall</div>
                                                                  <div style={{ width: '25%' }}>H</div>
                                                                </div>
                                                              </div>
                                                              <div style={{ width: '55%', fontSize: '13px', paddingLeft: '0px', paddingRight: '5px' }}>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', borderBottom: '1px solid black' }}>
                                                                  <div style={{ width: '100%', fontWeight: '600' }}>Behavior Code: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; See 1-10</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>1. Sleeping (3 respirations observed)</div>
                                                                  <div style={{ width: '44%' }}>7. Oppositional</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>2. Calm, interacting appropriately</div>
                                                                  <div style={{ width: '44%' }}>8. Bizarre behavior</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>3. Withdrawn/Quiet</div>
                                                                  <div style={{ width: '44%' }}>9. Shouting/loud/agitated</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>4. Crying, tearful</div>
                                                                  <div style={{ width: '44%' }}>10. Out of control, aggressive</div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>5. Anxious, restless</div>
                                                                  <div style={{ width: '44%' }}></div>
                                                                </div>
                                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                  <div style={{ width: '56%' }}>6. Elated, hyperactive, manic</div>
                                                                  <div style={{ width: '44%' }}></div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <article style={{ width: '100%' }} className=''>
                                                              <div style={{ width: '100%', fontSize: '13px' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', fontWeight: '600' }}>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }} className='border'>Printed Name/Signature of Staff Member/Title</div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }} className='border'>Staff Initials</div>
                                                                </div>
                                                              </div>
                                                              <div style={{ width: '100%', fontSize: '13px' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                </div>
                                                              </div>
                                                              <div style={{ width: '100%', fontSize: '13px' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                </div>
                                                              </div>
                                                              <div style={{ width: '100%', fontSize: '13px' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '22px' }}>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '47%', border: '1px solid #C9C9C9' }}></div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                </div>
                                                              </div>
                                                              <div style={{ width: '100%', fontSize: '13px' }}>
                                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'auto' }}>
                                                                  <div style={{ width: '100%', border: '1px solid #C9C9C9', paddingBottom: '10px' }}>
                                                                    <div style={{ padding: '1px 7px', fontWeight: '600' }}>PRECAUTIONS MEANING: </div>
                                                                    <div style={{ display: 'flex', flex: 'row', width: '100%' }}>
                                                                      <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEXUALLY ACTING OUT (SAO)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            SAO: SEXUALLY ACTING OUT
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ACTIVE SUICIDE PREC (ASP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            ASP: ACTIVE SUICIDE PREC.
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "FALL PREC (FP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            FP: FALL PREC.
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "GEN SAFETY PREC (GSP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            GSP: GEN SAFETY PREC.
                                                                          </label>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-ETOH")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            DETOX-ETOH
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "DETOX-DRUG")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            DETOX-DRUG
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ASSAULT PREC (AP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            AP: ASSAULT PREC.
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ELOPEMENT PREC (EP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            EP: ELOPEMENT PREC
                                                                          </label>
                                                                        </div>
                                                                      </div>
                                                                      <div style={{ width: '33.33%', fontSize: '13px', padding: '0px 6px' }} >
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "ONE TO ONE (1:1)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            1:1: ONE TO ONE
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "OBSERVATION")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            OBSERVATION
                                                                          </label>
                                                                        </div>
                                                                        <div className="form-check " style={{ height: '11px' }}>
                                                                          <input className="form-check-input" type="checkbox" disabled checked={data?.precaution?.find((item: any) => item === "SEIZURE PREC (SP)")} style={{ padding: '0.5px', border: '1px solid black' }} />
                                                                          <label className="" style={{ fontSize: '11.5px', fontWeight: 'normal' }} >
                                                                            SZ: SEIZURE PREC.
                                                                          </label>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div style={{ width: '6%', border: '1px solid #C9C9C9' }}></div>
                                                                </div>
                                                              </div>
                                                            </article> */}
                      <div style={{ fontSize: '14px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                          <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                        </div>
                        {[1, 2, 3, 4].map((t: any, indx: number) => (
                          <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                          </div>
                        ))}
                      </div>
                      <div style={{ fontSize: '14px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                          <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                          <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                          <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                          <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                        </div>
                      </div>
                    </>
                    }
                  </section>}
                  {/* Single Report Time PM View End  */}
                  {/* Notes Table Start */}
                  {/* <section className={`px-5 ${filterdata.length > 0 ? 'py-3': 'py-0' }`}>
                                                     {
                                                       (tableData && filterdata.length>0) && 
                                                       <>
                                                         <div className='mt-2' style={{width: '100%', fontSize: '13px'}}>
                                                           <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                             <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff</div>
                                                             <div style={{ width: '10%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time</div>
                                                             <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Notes</div>
                                                             <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff</div>
                                                             <div style={{ width: '10%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                                             <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Notes</div>
                                                           </div>
                                                         </div>
                                                         <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                                                           <div style={{ width: '50%', fontSize: '14px' }}>
                                                             { filterdata && filterdata?.map((item: any, inx: number) => {
                                                               const staffTime =  moment(item.time ,"YYYYMMDDHHmmss").format("HH:mm")
                                                               return inx >=0 && inx <= ((filterdata.length/2)-1) && (
                                                                 <>
                                                                   <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={inx}>
                                                                     <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.staff}</div>
                                                                     <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{staffTime && staffTime}</div>
                                                                     <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.note}</div>
                                                                   </div>
                                                                 </>  
                                                                )
                                                               })
                                                             }
                                                           </div>                    
                                                           <div style={{ width: '50%', fontSize: '14px' }}>
                                                             { filterdata && filterdata?.map((item: any, inx: number) => {
                                                               const staffTime =  moment(item.time ,"YYYYMMDDHHmmss").format("HH:mm")
                                                               return inx >= (filterdata.length/2) && inx <= (filterdata.length-1) && (
                                                                 <>
                                                                   <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={inx}>
                                                                     <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.staff}</div>
                                                                     <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{staffTime && staffTime}</div>
                                                                     <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{item.note}</div>
                                                                   </div>
                                                                 </>  
                                                                )
                                                               })
                                                             }
                                                           </div>
                                                         </div>
                                                       </> 
                                                     }
                                                   </section > */}
                  {/* Notes Table End */}
                </div>

              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      }
      {/* {
                onetoOnedata !== null && onetoOnedata !== undefined &&
                <ReportView reportData={onetoOnedata} selectShiftView={undefined} checkBox={undefined} selectShift={undefined} nextDate={undefined} dateID={undefined} checkPrecaution={''} activityLegend={activityLegend} locationLegend={locationLegend} legendcontrol={false} />
            } */}
    </div>
  )
}

export default OnetoOnePatientReportView