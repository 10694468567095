import React, { useEffect, useState } from 'react'
import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { primarybg, secondarybt } from '../../common/primary';
import axios from 'axios';
import { baseURL, successCode } from '../../configuration/url';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPatient, getAllStaff } from '../../slices/thunk';
import { toast } from 'react-toastify';
import moment from 'moment';
import { authorize } from '../../helpers/common';
import Q15PatientReportView from './Q15PatientReportView';
import OnetoOnePatientReportView from './OnetoOnePatientReportView';
import Loader from '../../components/loader/Loader';
import EyeSightPatientReportView from './EyeSightPatientReportView';
import './Reports.css'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { getActivityLegend, getLocationLegend } from '../../slices/Reports/thunk';
import printImage from '../../assets/images/printer.svg'
import { setActivityLegend, setLocationLegend } from '../../slices/Reports/reducer';

interface addpatient {
    patientid?: any
    startDate?: any
    endDate?: any
}

const PatientReport = () => {
    let tabcont = {
        q15Report: false,
        onetoone: false,
        eyesight: false,
        onehour: false
    }
    const org = useSelector((state: any) => state.Login.organization)
    const [Q15tabcontrol, setQ15Tabcontrol] = useState<boolean>(false)
    const [onetoonetabcontrol, setOnetooneTabcontrol] = useState<boolean>(false)
    const [Eyetabcontrol, setEyeTabcontrol] = useState<boolean>(false)
    const [value, setValue] = useState<any>('1');
    const handleChange = (event: any, newValue: any) => {
        setValue(newValue)
    };
    const dispatch = useDispatch<any>()
    const { staffData } = useSelector((state: any) => state.Staff);
    const { patientData } = useSelector((state: any) => state.Patient);
    const { activityLegend, locationLegend } = useSelector((state: any) => state.Report)
    const { orgData, shiftStartTime } = useSelector((state: any) => state.Org)
    let [getStaffData, setStaffData] = useState(new Array<any>());
    let [addNewStaff, setAddNewStaff] = useState(new Array<any>());
    const [addPatientName, setAddPatientName] = useState<any>('')
    let [data, setData] = useState(new Array<any>());
    let [addNewPatient, setAddNewPatient] = useState(new Array<any>());
    let [newInputData, setNewInputData] = useState<addpatient>();
    const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})
    const [Q15ReportData, setQ15ReportData] = useState<any>('')
    const [onetooneReportData, setonetooneReportData] = useState<any>([])
    const [EyesightReportData, setEyesightReportData] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false);
    const [legendcontrol, setLegendControl] = useState<boolean>(false)
    const [OrgName, setOrgName] = useState<any>('')
    let [newTimeSlot, setNewTimeSlot] = useState(new Array<any>());
    const [viewContent, setViewContent] = useState<boolean>(true)
    const [checkBox, setCheckBox] = useState<boolean>(false);
    const [checkLegend, setCheckLegend] = useState<boolean>(false)
    const [reportViewContent, setReportViewContent] = useState<any>('')
    const [addStaffName, setAddStaffName] = useState<any>('')
    const [loginStaffName, setLoginStaffName] = useState<any>('')
    const handlelegendshow = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLegendControl(e.target.checked)
    }
    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDisabled(e.target.checked);
    };

    const { useDetailProfile, userData } = useSelector((state: any) => state.Login);
    const decreaseOneMinute = (timeString: any) => {
        // Split the time string to extract hours and minutes
        if (!timeString) return
        const [hours, minutes] = timeString?.split(':').map(Number);

        // Create a new Date object for today with the given time
        let date = new Date();
        date.setHours(hours, minutes, 0); // Set the time (HH:MM:SS)

        // Subtract 1 minute
        date.setMinutes(date.getMinutes() - 1);

        // Format the new time back to HH:mm format
        const newHours = String(date.getHours()).padStart(2, '0');
        const newMinutes = String(date.getMinutes()).padStart(2, '0');

        return `${newHours}:${newMinutes}`;
    }
    function formatDate(epoch: any) {
        let d = new Date(epoch);
        let hours = String((d.getHours())).padStart(2, '0');
        let mins = String((d.getMinutes())).padStart(2, '0');
        return `${hours}:${mins}`;
    }
    useEffect(() => {
        const createTimeSlots = (fromTime: string, toTime: string, slotLength = 15 * 60) => {
            let slotStart = new Date(fromTime).valueOf();
            let slotEnd = new Date(fromTime).valueOf() + slotLength * 1000;
            let endEpoch = new Date(toTime).valueOf();
            let ob = [];
            for (slotEnd; slotEnd <= endEpoch; slotEnd = slotEnd + slotLength * 1000) {
                ob.push(formatDate(slotStart));
                slotStart = slotEnd;
            }
            return ob;
        }
        const from = "2022-05-25 " + shiftStartTime;
        const to = "2022-05-26 " + shiftStartTime;
        const slotLength = 15 * 60;
        var r = createTimeSlots(from, to, slotLength);
        setNewTimeSlot(r);
    }, [])
    useEffect(() => {
        if (!orgData) return
        console.log(useDetailProfile, userData, 'orgData')
        setOrgName(orgData.organizationdetails[0].name)
    }, [orgData])
    useEffect(() => {
        getLocationLegend(dispatch, org)
        getActivityLegend(dispatch, org)
        return () => {
            dispatch(setActivityLegend([]))
            dispatch(setLocationLegend([]))
        }
    }, [org])
    useEffect(() => {
        getAllStaff(dispatch, org, -1, '')
        getAllPatient(dispatch, org, -1, '');
    }, [org])
    useEffect(() => {
        if (staffData) {
            setAddNewStaff(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner"));
            setStaffData(staffData !== null && staffData !== undefined && staffData?.filter((t: any) => t.role === "Social Worker" || t.role === "Registered Nurse" || t.role === "Nurse Practitioner").map((k: any) => { return k?.name[0]?.given + " " + k?.name[0]?.family }))
        } else {
            // setAddNewPatient([])
            setStaffData([])
        }
    }, [staffData])

    useEffect(() => {
        if (patientData && useDetailProfile.userType === "Admin") {
            setAddNewPatient(patientData !== null && patientData !== undefined && patientData?.content);
            setData(
                patientData?.content?.map((k: any) => {
                    return k?.basicDetails[0]?.name[0]?.given + " " + k?.basicDetails[0]?.name[0]?.family;
                })
            );
        } else if (useDetailProfile.userType === "Staff") {
            setLoginStaffName(useDetailProfile.name[0].given + " " + useDetailProfile.name[0].family)
            setAddStaffName(useDetailProfile.name[0].given + " " + useDetailProfile.name[0].family)
            const handelPatientDropDown = async () => {
                try {
                    const response = await axios.get(`${baseURL}/report/patients?organization=${org}&staffId=${useDetailProfile.id}`)
                    console.log(response.data.data, '1', 'orgData')
                    if (response.data.message.code === successCode) {
                        setAddNewPatient(response?.data?.data);
                        setData(response.data.data.map((k: any) => {
                            return k.patientName
                        }));
                    } else {
                        setAddNewPatient([])
                        setData([])
                    }
                } catch (error: any) {
                    console.error("API ERROR: ", error)
                }
            }
            handelPatientDropDown()
        } else {
            setAddNewPatient([])
            setData([])
        }
    }, [patientData]);
    const handleSubmit = async () => {
        setLoading(true)
        let newErrors = {
            patientid: !newInputData?.patientid,
            startDate: !newInputData?.startDate,
        };
        setNameError(newErrors);
        const hasErrors = Object.values(newErrors)?.some(error => error);
        if (hasErrors) {
            setLoading(false)
            toast.error("Please Fill Required Field")
            return;
        }

        // const endDateRange = newInputData?.endDate ? moment(newInputData?.endDate).format("YYYYMMDD") : ''

        const apiDomian = newInputData?.endDate
            ? `${baseURL}/report/patientReports?endDate=${moment(newInputData?.endDate).format("YYYYMMDD")}&organization=${org}&patientId=${newInputData?.patientid}&startDate=${moment(newInputData?.startDate).format('YYYYMMDD')}`
            : `${baseURL}/report/patientReports?organization=${org}&patientId=${newInputData?.patientid}&startDate=${moment(newInputData?.startDate).format('YYYYMMDD')}`

        try {

            const { header1 } = authorize()
            const response = await axios.get(apiDomian, { headers: header1 })
            if (response.data.message.code === successCode) {
                setLoading(false)
                setCheckBox(disabled ? true : false)
                setCheckLegend(legendcontrol ? true : false)
                if (response.data.q15) {
                    setQ15Tabcontrol(response.data.q15 ? true : false)
                    setValue('1')
                    setQ15ReportData(response.data.q15Data)
                } else {
                    setQ15ReportData([])
                    setQ15Tabcontrol(false)
                }

                if (response.data.oneToOne) {
                    setOnetooneTabcontrol(response.data.oneToOne ? true : false)
                    setValue('2')
                    setonetooneReportData(response.data.oneToOneData)
                } else {
                    setonetooneReportData([])
                    setOnetooneTabcontrol(false)
                }

                if (response.data.eyeSight) {
                    setEyeTabcontrol(response.data.eyeSight ? true : false)
                    setValue('3')
                    setEyesightReportData(response.data.eyeSightData)
                } else {
                    setEyesightReportData([])
                    setEyeTabcontrol(false)
                }

                console.log(response.data, 'respData')
            } else {
                setCheckBox(false)
                setCheckLegend(false)
                setLoading(false)
                setQ15Tabcontrol(false)
                setOnetooneTabcontrol(false)
                setEyeTabcontrol(false)
                setQ15ReportData([])
                setonetooneReportData([])
                setonetooneReportData([])
                setValue('0')
            }
        } catch (error: any) {
            setLoading(false)
            console.error("API ERROR:", error)
        }

    }
    const handleQ15AllDownload = async (ReportData: any) => {
        if (ReportData) {
            try {
                setLoading(true);
                let index = 0;
                let myVar = setInterval(() => {
                    if (index < ReportData.length) {
                        const currentIndex = index;
                        setTimeout(() => { timeDealys(ReportData[currentIndex]); }, 0);
                        index++;
                    } else {
                        setLoading(false);
                        clearInterval(myVar);
                    }
                }, 0);

                async function timeDealys(reports: any) {
                    // Get all the sections
                    const sections = document.querySelectorAll(`.report-section${moment(reports.q15Date).format("DD-MM-YYYY")}`);

                    // Create a PDF instance
                    const pdf = new jsPDF('p', 'px', 'a4', true);
                    const margin = 20;
                    const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                    const images: any[] = []; // Array to store images of each section
                    let currentY = margin;

                    const addSectionToPDF = (section: any) => {
                        return new Promise<void>((resolve) => {
                            html2canvas(section, {
                                useCORS: true,
                                logging: true,
                                scale: 2,
                                backgroundColor: null,
                            }).then((canvas) => {
                                const imgData = canvas.toDataURL('image/png');
                                images.push(imgData); // Push the image data into the images array
                                resolve();
                            }).catch((error) => {
                                console.error('Error rendering section', error);
                                resolve();
                            });
                        });
                    };

                    // Add all sections to images array
                    const promises = Array.from(sections).map(addSectionToPDF);
                    await Promise.all(promises);

                    // Add images to the PDF in the correct order
                    images.forEach((imgData, index) => {
                        if (index > 0) pdf.addPage(); // Add a new page for each subsequent section
                        pdf.addImage(imgData, 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                    });

                    // Save the PDF with the content in the correct order
                    pdf.save(`${reports.patientName}-${moment(reports.q15Date).format("DD-MM-YYYY")}.pdf`);
                    setLoading(false);
                }

            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    }
    const handleAllDownload = async (reportData: any) => {
        if (reportData) {
            try {
                setLoading(true);
                let index = 0;
                let myVar = setInterval(() => {
                    if (index < reportData.length) {
                        const currentIndex = index;
                        setTimeout(() => { timeDealys(reportData[currentIndex]); }, 0);
                        index++;
                    } else {
                        clearInterval(myVar);
                    }
                }, 0);

                async function timeDealys(reports: any): Promise<void> {
                    const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`);

                    if (sections.length === 0) {
                        console.error('No sections found for the given selector');
                        setLoading(false);
                        return;
                    }

                    const pdf = new jsPDF('p', 'px', 'a4', true);
                    const margin = 20;
                    const pageHeight = pdf.internal.pageSize.height;
                    const images: string[] = [];
                    const currentY = margin;

                    const addSectionToPDF = (section: Element): Promise<void> => {
                        return new Promise<void>((resolve) => {
                            setTimeout(() => {
                                html2canvas(section as HTMLElement, {
                                    useCORS: true,
                                    logging: true,
                                    scale: 1.5,
                                    backgroundColor: null,
                                })
                                    .then((canvas) => {
                                        const imgData = canvas.toDataURL('image/png');
                                        if (imgData && imgData !== 'data:,') {
                                            images.push(imgData);
                                        } else {
                                            console.error('Invalid image data generated');
                                        }
                                        resolve();
                                    })
                                    .catch((error) => {
                                        console.error('Error rendering section', error);
                                        resolve();
                                    });
                            }, 100); // Add delay to stabilize rendering
                        });
                    };

                    try {
                        const promises = Array.from(sections).map((section) => addSectionToPDF(section));
                        await Promise.all(promises);

                        if (images.length < 2) {
                            console.error('Not enough valid images for PDF generation');
                            setLoading(false);
                            return;
                        }

                        try {
                            // Add the second image to the first page (swapped content)
                            pdf.addImage(
                                images[1],
                                'PNG',
                                margin,
                                currentY,
                                pdf.internal.pageSize.width - 2 * margin,
                                pageHeight - 2 * margin
                            );
                            pdf.addPage();

                            // Add the first image to the second page (swapped content)
                            pdf.addImage(
                                images[0],
                                'PNG',
                                margin,
                                currentY,
                                pdf.internal.pageSize.width - 2 * margin,
                                pageHeight - 2 * margin
                            );

                            // Save the PDF file
                            pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
                        } catch (error) {
                            console.error('Error adding images to PDF:', error);
                        }
                    } catch (error) {
                        console.error('Error rendering sections:', error);
                    } finally {
                        setLoading(false);
                    }
                }


                //   async function timeDealys(reports: any) {
                //     // Get all the sections
                //     const sections = document.querySelectorAll(`.report-section${moment(reports.date).format("DD-MM-YYYY")}`);

                //     // Create a PDF instance
                //     const pdf = new jsPDF('p', 'px', 'a4', true);
                //     const margin = 20;
                //     const pageHeight = pdf.internal.pageSize.height; // Get the height of the page
                //     const images: any[] = []; // Array to store images of each section
                //     let currentY = margin;

                //     const addSectionToPDF = (section: any) => {
                //       return new Promise<void>((resolve) => {
                //         html2canvas(section, {
                //           useCORS: true,
                //           logging: true,
                //           scale: 2,
                //           backgroundColor: null,
                //         }).then((canvas) => {
                //           const imgData = canvas.toDataURL('image/png');
                //           images.push(imgData); // Push the image data into the images array
                //           resolve();
                //         }).catch((error) => {
                //           console.error('Error rendering section', error);
                //           resolve();
                //         });
                //       });
                //     };

                //     // Add all sections to images array
                //     const promises = Array.from(sections).map(addSectionToPDF);
                //     await Promise.all(promises);

                //     // Add the first image to the second page (swap the first and second content)
                //     pdf.addImage(images[1], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);
                //     pdf.addPage(); // Create a new page

                //     // Add the second image to the first page (swapped content)
                //     pdf.addImage(images[0], 'PNG', margin, currentY, pdf.internal.pageSize.width - 2 * margin, pageHeight - 2 * margin);

                //     // Save the PDF with the swapped content
                //     pdf.save(`${reports.patientId}-${moment(reports.date).format("DD-MM-YYYY")}.pdf`);
                //     setLoading(false);
                //   }
            } catch (error: any) {
                console.error("Download Error: ", error);
                setLoading(false);
            }
        }
    }
    const handlePrint = (str: any) => {
        let printContents = document.getElementById(str)!.innerHTML;
        let printWindow = window.open('', '_blank');
        printWindow!.document.body.innerHTML = printContents;
        printWindow!.print();
        printWindow!.close();
    }
    return (
        <div className='hidden-scrollbar px-2' style={{ overflow: 'auto', height: 'auto' }}>
            {loading && <Loader />}
            {/* <div className='d-flex'>
                <h5 className='col-md-7 d-flex justify-content-end' style={{ float: 'right' }}>Patient Specific Report</h5>
                <div className='col-md-5'>
                    <button className='btn' style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right', marginLeft: '5px' }} onClick={() => { handleAllDownload(Q15ReportData) }}>All Download</button>
                </div>
            </div> */}
            <div>
                <h5 className='' style={{ textAlign: 'center' }}>Patient Specific Report</h5>
            </div>

            <div hidden={!viewContent}>
                <div className='mt-3 d-flex '  >
                    <div className='col-md-2' >
                        { /* Select Patient section start */}
                        <header>
                            <div className='d-flex flex-column gap-3 px-1'>
                                <div className='pt-2'>
                                    <Autocomplete
                                        options={data}
                                        getOptionLabel={(option) => option}
                                        value={addPatientName}
                                        onChange={(e, v) => {
                                            setAddPatientName(v);
                                            if (useDetailProfile.userType !== "Staff") {
                                                let newPatientData = addNewPatient && addNewPatient?.filter((m: any) => m.basicDetails[0].name[0].given + " " + m.basicDetails[0].name[0].family === v).map((k) => { return k.id });
                                                setNewInputData({ ...newInputData, patientid: newPatientData[0] });
                                                setNameError({ ...nameError, patientid: false })
                                            } else {
                                                let newPatientData = addNewPatient && addNewPatient?.filter((m: any) => m.patientName === v);
                                                setNewInputData({ ...newInputData, patientid: newPatientData[0]?.patientId });
                                                setNameError({ ...nameError, patientid: false })
                                            }
                                        }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Patient Name"
                                                placeholder="Patient Name"
                                                margin="none"
                                                fullWidth
                                                error={!!nameError.patientid}
                                            // helperText={nameError.patientid ? 'sad' : '' }
                                            />
                                        )}

                                    />
                                </div>
                                <div className=''>
                                    <Autocomplete
                                        options={getStaffData}
                                        getOptionLabel={(option) => option}
                                        value={addStaffName}
                                        disabled
                                        defaultValue={useDetailProfile.userType === "Staff" ? loginStaffName : ''}
                                        onChange={(e, v) => {
                                            console.log(e, 'ev', v)
                                            setAddStaffName(v)
                                            // let newStaffData = addNewStaff && addNewStaff?.filter((m: any) => m.name[0].given + " " + m.name[0].family === v).map((k) => { return k.id });

                                            // handleGetPatientData(newStaffData[0])

                                        }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Staff Name"
                                                placeholder="Staff Name"
                                                margin="none"
                                                fullWidth
                                            />
                                        )}
                                    />
                                </div>
                                <div className=''>
                                    <DatePicker
                                        className=""
                                        label="Start Date"
                                        // value={newInputData?.startDate ? newInputData?.startDate : null}
                                        onChange={(event: any) => {
                                            // const d = new Date(event)
                                            // let w = d.getFullYear().toString()
                                            // if (w.length !== 4) {
                                            setNewInputData({ ...newInputData, startDate: event?.$d })
                                            setNameError({ ...nameError, startDate: false })
                                            // }
                                        }}
                                        sx={{
                                            border: nameError.startDate ? "1px solid red" : "",
                                            borderRadius: "5px",
                                            width: '100%'
                                        }}

                                    />
                                </div>
                                <div className=''>
                                    <DatePicker
                                        className=""
                                        label="End Date"
                                        onChange={(event: any) => {
                                            setNewInputData({ ...newInputData, endDate: event?.$d })
                                        }}
                                        sx={{
                                            width: '100%'
                                        }}
                                    />
                                </div>
                                <div className=''>
                                    <FormControlLabel
                                        control={<Checkbox checked={disabled} onChange={handleCheck} />}
                                        label="Proximity"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={legendcontrol} onChange={handlelegendshow} />}
                                        label={legendcontrol ? 'Legends Name' : 'Legends Key'}
                                    />
                                </div>
                                <div className=' d-flex align-items-center justify-content-center '>
                                    <button className="btn" style={{ backgroundColor: primarybg, border: 'none', width: 'fit-content', float: 'right', marginLeft: '0px', color: 'white' }} onClick={handleSubmit}>Submit</button>
                                </div>
                            </div>
                        </header>
                        { /* Select Patient section End */}
                    </div>
                    {
                        (onetoonetabcontrol || Eyetabcontrol || Q15tabcontrol) ?
                            <div className=' col-md-10 '>
                                {
                                    (onetoonetabcontrol || Eyetabcontrol || Q15tabcontrol) && (
                                        <>
                                            <section>
                                                <Box>
                                                    <TabContext value={value}>
                                                        <Box>
                                                            <div className='row'>
                                                                <div className="col-md-8">
                                                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                                        {Q15tabcontrol && <Tab label="Q15-Report" value="1" />}
                                                                        {onetoonetabcontrol && <Tab label="One-to-One" value="2" />}
                                                                        {Eyetabcontrol && <Tab label="EyeSight" value="3" />}
                                                                    </TabList>
                                                                </div>
                                                                <div className="col-md-4 p-2">
                                                                    {/* <div className=''> */}
                                                                    <button className='btn ' style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content', float: 'right' }} onClick={() => {
                                                                        //  handleAllDownload(Q15ReportData)
                                                                        setReportViewContent(value === '2' ? onetooneReportData?.filter((item: any) => item.shiftAIncharge || item.shiftATime || item.shiftBIncharge || item.shiftBTime || item.shiftCIncharge || item.shiftCTime) : value === '3' ? EyesightReportData?.filter((item: any) => item.shiftAIncharge || item.shiftATime || item.shiftBIncharge || item.shiftBTime || item.shiftCIncharge || item.shiftCTime) : [])
                                                                        setViewContent(false)
                                                                    }}>View</button>
                                                                    {/* </div> */}
                                                                </div>
                                                            </div>
                                                        </Box>
                                                        <TabPanel value='1'>
                                                            <>
                                                                {(Q15ReportData !== null && Q15ReportData.length > 0) && <Q15PatientReportView ReportData={Q15ReportData} setLoading={setLoading} />}
                                                            </>
                                                        </TabPanel>
                                                        <TabPanel value='2'>
                                                            <>
                                                                {onetooneReportData !== null && onetooneReportData.length > 0 && <OnetoOnePatientReportView onetooneReportData={onetooneReportData} selectShiftView={undefined} selectShift={undefined} nextDate={undefined} checkPrecaution={undefined} checkBox={checkBox} legendcontrol={undefined} setLoading={setLoading} />}
                                                            </>
                                                        </TabPanel>
                                                        <TabPanel value='3'>
                                                            <>
                                                                {(EyesightReportData !== null && EyesightReportData.length > 0) && <EyeSightPatientReportView EyesightReportData={EyesightReportData} setLoading={setLoading} checkBox={checkBox} legendcontrol={legendcontrol} />}
                                                            </>
                                                        </TabPanel>
                                                    </TabContext>
                                                </Box>
                                            </section>
                                        </>
                                    )
                                }
                            </div>
                            :
                            <div className='col-md-10 d-flex justify-content-center align-items-center ' >
                                <h5 style={{ fontSize: '16px', fontWeight: '600' }}>
                                    No Reports Found
                                </h5>
                            </div>
                    }
                </div>
            </div>

            <div className='' hidden={viewContent}>
                <div className='row gap-2 px-3 justify-content-end '>
                    <button className='btn' style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content' }} onClick={() => {
                        if (value === '1') {
                            handleQ15AllDownload(Q15ReportData)
                        } else {
                            handleAllDownload(reportViewContent)
                        }
                    }} >Download</button>
                    <button className='btn' style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content' }} onClick={() => { handlePrint(value === '1' ? 'Q15Report' : value === '2' ? 'OneToOneReport' : value === '3' && 'EyeSightReport') }} ><img src={printImage} />Print</button>
                    <button className='btn' style={{ backgroundColor: secondarybt, border: 'none', width: 'fit-content' }} onClick={() => { setViewContent(true) }} >Close</button>
                </div>
                {
                    value === '1' &&
                    <div className='px-4 hidden-scrollbar' id='Q15Report' style={{ overflow: 'auto', height: '75vh' }}  >
                        {
                            Q15ReportData !== null && Q15ReportData !== undefined && Q15ReportData.length > 0 && Q15ReportData?.map((data: any) => (
                                <div>
                                    <div className={`report-section${moment(data.q15Date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}1`}>
                                        <div id="removePadding" style={{ textAlign: 'center' }} className="">
                                            {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null}
                                        </div>
                                        <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="">
                                            {OrgName}
                                        </div>
                                        <div id="removePadding" style={{ textAlign: 'right', width: "48%", fontSize: '17px', fontWeight: '600', display: "flex", justifyContent: "flex-start", position: "relative", top: "28px" }}>Date:<span style={{ fontWeight: '600', marginLeft: '5px' }}> {moment(data.q15Date).format("MMM DD, YYYY")}</span></div>
                                        <div style={{ display: 'flex', marginLeft: '70px' }}>
                                            <div id="removePadding" style={{ textAlign: 'right', width: "48%", fontSize: '17px', fontWeight: '600', marginLeft: '130px' }}>Patient Name: </div>
                                            <div style={{ width: '2%' }}></div>
                                            <div id="removePadding" style={{ textAlign: "left", width: "50%", fontSize: '17px', paddingLeft: '5px', fontWeight: '600' }} >{data.patientName}</div>
                                        </div>

                                        <div style={{ border: '1px solid #C9C9C9' }}>
                                            <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                                <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                                    <div>
                                                        CHECK PRECAUTIONS:
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '3px' }}>
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Every 15 Minutes
                                                        </label>
                                                        <div className="form-check" style={{ height: '15px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled checked style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '3px' }}>
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            1:1
                                                        </label>
                                                        <div className="form-check" style={{ height: '15px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '3px' }}>
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Eyesight
                                                        </label>
                                                        <div className="form-check" style={{ height: '15px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Agitated Behavior
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Arson
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Assault
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Fall
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Detox
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Elopement
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Homicide
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Sexual Acting Out
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Self Harm
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Other {`${'('}`}Describe{`${'):'}`}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                                <div>LOCATION CODES</div>
                                            </div>
                                            <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                    {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                                        <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                {item.key}-{item.value}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                                <div>ACTIVITY CODES</div>
                                            </div>
                                            <div>
                                                <div
                                                    // className='d-flex flex-row flex-wrap py-1'
                                                    style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}
                                                >
                                                    {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                                        <div className=" " style={{ height: '30px', width: '25%' }} title={item.value} key={inx}>
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                {inx + 1}.{item.value}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>


                                        <div style={{ display: 'flex', flexDirection: 'row', fontSize: '15px', fontWeight: '600' }}>
                                            <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                            <div style={{ width: '42%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                            <div style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div>
                                            <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                            <div style={{ width: '42%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                            <div style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div>
                                            {/* <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                                                    <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                                                    <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div> */}
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', fontSize: '14px' }}>
                                            <div style={{ width: '50%' }}>
                                                {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                    return t >= 0 && t <= 23 && (<>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ width: '21.5%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                            <div style={{ width: '44.5%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}
                                                            </div>
                                                            <div style={{ width: '34%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}
                                                            </div>
                                                        </div>
                                                    </>)
                                                })}
                                            </div>

                                            <div style={{ width: '50%' }}>
                                                {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                    return t >= 24 && t <= 47 && (<>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ width: '21.5%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                            <div style={{ width: '44.5%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                            <div style={{ width: '34%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                        </div>
                                                    </>)
                                                })}
                                            </div>
                                            {/* <div style={{ width: '33%' }}>
                                                                        {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                            return t >= 64 && t <= 79 && (<>
                                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                    <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>   {calculateDisplayTime(s, shiftStartTime, t)}
                            
                                                                                    </div>
                                                                                    <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                                        {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                                                    <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                                        {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                                                </div>
                                                                            </>)
                                                                        })}
                                                                    </div> */}
                                        </div>
                                        {/* <div style={{ width: '100%', display: 'flex', fontSize: '14px' }}>
                                                                    <div style={{ width: '34%' }}>
                                                                        {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                            return t >= 16 && t <= 31 && (<>
                                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                    <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                                                    <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                                        {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                                                    <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                                        {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                                                </div>
                                                                            </>)
                                                                        })}
                                                                    </div>
                                                                    <div style={{ width: '33%' }}>
                                                                        {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                            return t >= 48 && t <= 63 && (<>
                                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                    <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                                                    <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                                        {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                                                    <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                                        {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                                                </div>
                                                                            </>)
                                                                        })}
                                                                    </div>
                            
                                                                    <div style={{ width: '33%' }}>
                                                                        {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                                            return t >= 80 && t <= 95 && (<>
                                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                    <div style={{ width: '30.7%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s} +1</div>
                                                                                    <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                                        {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1]).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1] && m.locationName !== "" && m.locationName !== null).map((n: any) => { return n.locationName }) : ""}</div>
                                                                                    <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                                        {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1] && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + newTimeSlot[t === 95 ? t = 0 : t + 1] && m.activityName !== "" && m.activityName !== null).map((n: any) => { return n.activityName }) : ""}</div>
                                                                                </div>
                                                                            </>)
                                                                        })}
                                                                    </div>
                                                                </div> */}

                                        <div style={{ fontSize: '14px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                            </div>
                                            {[1, 2, 3, 4].map((t: any, indx: number) => (
                                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{ fontSize: '14px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                                <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`mt-5 report-section${moment(data.q15Date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}2`}>
                                        <div id="removePadding" style={{ textAlign: 'center' }} className="">
                                            {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null}
                                        </div>
                                        <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="">
                                            {OrgName}
                                        </div>
                                        <div id="removePadding" style={{ textAlign: 'right', width: "48%", fontSize: '17px', fontWeight: '600', display: "flex", justifyContent: "flex-start", position: "relative", top: "28px" }}>Date:<span style={{ fontWeight: '600', marginLeft: '5px' }}> {moment(data.q15Date).format("MMM DD, YYYY")}</span></div>
                                        <div style={{ display: 'flex', marginLeft: '70px' }}>
                                            <div id="removePadding" style={{ textAlign: 'right', width: "48%", fontSize: '17px', fontWeight: '600', marginLeft: '130px' }}>Patient Name: </div>
                                            <div style={{ width: '2%' }}></div>
                                            <div id="removePadding" style={{ textAlign: "left", width: "50%", fontSize: '17px', paddingLeft: '5px', fontWeight: '600' }} >{data.patientName}</div>
                                        </div>

                                        <div style={{ border: '1px solid #C9C9C9' }}>
                                            <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                                <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                                    <div>
                                                        CHECK PRECAUTIONS:
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '3px' }}>
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Every 15 Minutes
                                                        </label>
                                                        <div className="form-check" style={{ height: '15px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled checked style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '3px' }}>
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            1:1
                                                        </label>
                                                        <div className="form-check" style={{ height: '15px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '3px' }}>
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Eyesight
                                                        </label>
                                                        <div className="form-check" style={{ height: '15px' }}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Agitated Behavior
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Arson
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Assault
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Fall
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Detox
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Elopement
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Homicide
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Sexual Acting Out
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Self Harm
                                                        </label>
                                                    </div>
                                                    <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            Other {`${'('}`}Describe{`${'):'}`}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                                <div>LOCATION CODES</div>
                                            </div>
                                            <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                    {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                                        <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                                            <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                {item.key}-{item.value}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                                <div>ACTIVITY CODES</div>
                                            </div>
                                            <div>
                                                <div
                                                    //  className='d-flex flex-row flex-wrap py-1'
                                                    style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}
                                                >
                                                    {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                                        <div className="" style={{ height: '30px', width: '25%' }} title={item.value} key={inx}>
                                                            <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                                {inx + 1}.{item.value}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>


                                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', fontSize: '15px', fontWeight: '600' }}>
                                            <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                            <div style={{ width: '42%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                            <div style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div>
                                            <div style={{ width: '20%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                            <div style={{ width: '42%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                            <div style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div>
                                            {/* <div style={{ width: '30.7%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                                                    <div style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Loction</div>
                                                                    <div style={{ width: '31%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Activity</div> */}
                                        </div>
                                        <div style={{ width: '100%', display: 'flex', fontSize: '14px' }}>
                                            <div style={{ width: '50%' }}>
                                                {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot.map((s: any, t: number) => {
                                                    return t >= 48 && t <= 71 && (<>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ width: '21.5%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                            <div style={{ width: '44.5%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}
                                                            </div>
                                                            <div style={{ width: '34%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}
                                                            </div>
                                                        </div>
                                                    </>)
                                                })}
                                            </div>

                                            <div style={{ width: '50%' }}>
                                                {newTimeSlot !== null && newTimeSlot !== undefined && newTimeSlot.length > 0 && newTimeSlot?.map((s: any, t: number) => {
                                                    return t >= 72 && t <= 95 && (<>
                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <div style={{ width: '21.5%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{s}</div>
                                                            <div style={{ width: '44.5%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                {data?.data?.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.locationName !== "" && m.locationName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.locationName }) : ""}</div>
                                                            <div style={{ width: '34%', border: '1px solid #C9C9C9', textAlign: 'center' }}>
                                                                {data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1]) && m.activityName !== "" && m.activityName !== null).length > 0 ? data.data.filter((m: any) => m.q15Time === newTimeSlot[t] + "-" + decreaseOneMinute(newTimeSlot[t + 1])).map((n: any) => { return n.activityName }) : ""}</div>
                                                        </div>
                                                    </>)
                                                })}
                                            </div>
                                        </div>
                                        <div style={{ fontSize: '14px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                            </div>
                                            {[1, 2, 3, 4].map((t: any, indx: number) => (
                                                <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                    <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{ fontSize: '14px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                                <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                                <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                                <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                }
                {
                    value === '2' || value === '3' && reportViewContent.length > 0 && reportViewContent?.map((data: any) => (
                        <div className='container-fluid px-2' id={`${value === '2' ? 'OneToOneReport' : value === '3' ? 'EyeSightReport' : ''}`}>
                            {/* Report Time Am View Start */}
                            <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}1`}>
                                {data && <>
                                    <div id="removePadding" style={{ textAlign: 'center' }} className="">
                                        {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null}
                                        {/* {orgData && orgData.logo ? (
                                                    <Avatar
                                                      sx={{ width: 50, height: 50, backgroundColor: '#9F9FA2', marginRight: '', textAlign: 'center' }}
                                                      src={orgData.logo}
                                                    >
                                                    </Avatar>) : <></>} */}
                                    </div>
                                    <div id="removePadding" style={{ fontSize: '26px', fontWeight: 'bold', textAlign: 'center' }} className="">
                                        {OrgName && OrgName}
                                    </div>
                                    {/* <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="p-col-12 p-md-12">
                                                                                    Mettler Health Care Organization
                                                                                  </div> */}
                                    <div className='d-flex justify-content-between'>
                                        <div className='col-8'>
                                            <div className='d-flex flex-row gap-3'>
                                                <div><strong>Date: </strong>
                                                    {data.date && moment(data.date).format("MMM DD, YYYY")}
                                                    {/* {selectShiftView ? selectShift === "Shift-C" && `, ${nextDate}` : ''} */}
                                                </div>
                                                {/* {selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>} */}
                                            </div>
                                            <strong>Patient Name: </strong>{data.patientId}
                                        </div>
                                        <div className='d-flex justify-content-between col-4'>
                                            <div>
                                                <strong>Room No: </strong>
                                                {data.assignedBed && data.assignedBed.split('-')[0]}
                                            </div>
                                            <div>
                                                <strong className='pe-3'>AssignedBed:</strong>
                                                {data.assignedBed && data.assignedBed.split('-')[1]}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px solid #C9C9C9' }}>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                            <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                                <div>
                                                    CHECK PRECAUTIONS:
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Every 15 Minutes
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        1:1
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled checked={value === '2' ? true : false} style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Eyesight
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled checked={value === '3' ? true : false} style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Agitated Behavior
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Arson
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Assault
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Fall
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Detox
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Elopement
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Homicide
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Sexual Acting Out
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Self Harm
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Other {`${'('}`}Describe{`${'):'}`}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                            <div>LOCATION CODES</div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                                    <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            {item.key}-{item.value}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                            <div>ACTIVITY CODES</div>
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                                                {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                                    <div className="  " style={{ height: '30px', width: '25%' }} key={inx}>
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            {inx + 1}.{item.value}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            {/* <div className='d-flex flex-row flex-wrap py-1'
                                        //  style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}
                                        >
                                            {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                                <div className="col-3 px-1 " style={{ height: '30px' }} title={item.value} key={inx}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        {inx + 1}.{item.value}
                                                    </label>
                                                </div>
                                            ))}
                                        </div> */}
                                        </div>
                                    </div>

                                    <div className='' style={{ fontSize: '14px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                            <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                            <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                                        <div style={{ width: '50%', fontSize: '14px' }}>
                                            {data && data.configResponses?.map((t: any, ind: number) => {
                                                return ind >= 0 && ind <= 23 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                                        <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                                    </div>
                                                </>);
                                            })}
                                        </div>
                                        <div style={{ width: '50%', fontSize: '14px' }}>
                                            {data && data.configResponses?.map((t: any, ind: number) => {
                                                return ind >= 24 && ind <= 47 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', paddingLeft: '8px' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                                        <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                                    </div>
                                                </>);
                                            })}
                                        </div>
                                    </div>

                                    <div style={{ fontSize: '14px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                        </div>
                                        {[1, 2, 3, 4].map((t: any, indx: number) => (
                                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ fontSize: '14px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                                        </div>
                                    </div>

                                </>}
                            </section>
                            {/*  Report Time AM View End  */}
                            {/* Report Time PM View Start  */}
                            <section className={`mt-3 report-section${moment(data.date).format("DD-MM-YYYY")}`} id={`${moment(data.date).format("DD-MM-YYYY")}2`}>
                                {data && <>
                                    <div id="removePadding" style={{ textAlign: 'center' }} className="">
                                        {orgData.logo && orgData.logo !== '' ? (<img src={orgData.logo} alt="" style={{ height: '60px', width: '60px' }} />) : null}
                                        {/* {orgData && orgData.logo ? (
                                                                              <Avatar
                                                                                sx={{ width: 50, height: 50, backgroundColor: '#9F9FA2', marginRight: '', textAlign: 'center' }}
                                                                                src={orgData.logo}
                                                                              >
                                                                              </Avatar>) : <></>} */}
                                    </div>
                                    <div id="removePadding" style={{ textAlign: 'center', fontSize: '26px', fontWeight: 'bold' }} className="">
                                        {OrgName && OrgName}
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div className='col-8'>
                                            <div className='d-flex flex-row gap-3'>
                                                <div><strong>Date: </strong>{data.date && moment(data.date).format("ddd DD,YYYY")}</div>
                                                {/* {selectShiftView && <div><strong>Shift: </strong>{selectShift}</div>} */}
                                            </div>
                                            <strong>Patient Name: </strong>{data.patientId}
                                        </div>
                                        <div className='d-flex justify-content-between col-4'>
                                            <div>
                                                <strong>Room No: </strong>
                                                {data.assignedBed && data.assignedBed.split('-')[0]}
                                            </div>
                                            <div>
                                                <strong className='pe-3'>AssignedBed:</strong>
                                                {data.assignedBed && data.assignedBed.split('-')[1]}
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ border: '1px solid #C9C9C9' }}>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                            <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: ' 0px 20px', fontSize: '14px' }}>
                                                <div>
                                                    CHECK PRECAUTIONS:
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Every 15 Minutes
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        1:1
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled checked={value === '2' ? true : false} style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', gap: '3px' }}>
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Eyesight
                                                    </label>
                                                    <div className="form-check" style={{ height: '15px' }}>
                                                        <input className="form-check-input" type="checkbox" disabled checked={value === '3' ? true : false} style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', fontSize: '10px' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Agitated Behavior
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Arson
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Assault
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Fall
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Detox
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Elopement
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Homicide
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Sexual Acting Out
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Self Harm
                                                    </label>
                                                </div>
                                                <div className="form-check  " style={{ height: '15px', width: '20%', display: 'flex', gap: '3px' }}>
                                                    <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                    <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                        Other {`${'('}`}Describe{`${'):'}`}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                            <div>LOCATION CODES</div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 10px' }}>
                                                {locationLegend && locationLegend?.map((item: any, inx: number) => (
                                                    <div className="form-check  " style={{ height: '15px', width: '20%' }} key={inx}>
                                                        <input className="form-check-input" type="checkbox" disabled style={{ padding: '7px', border: '1.2px solid black' }} />
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            {item.key}-{item.value}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div style={{ borderBottom: '1px solid #C9C9C9', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>
                                            <div>ACTIVITY CODES</div>
                                        </div>
                                        <div>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', padding: '0px 3px' }}>
                                                {activityLegend && activityLegend?.map((item: any, inx: number) => (
                                                    <div className="  " style={{ height: '30px', width: '20%' }} key={inx}>
                                                        <label className="" style={{ fontSize: '14px', fontWeight: 'normal', color: 'black' }} >
                                                            {inx + 1}.{item.value}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='' style={{ fontSize: '14px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                            <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Time </div>
                                            <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location</div>
                                            <div hidden={checkBox} style={{ width: '45.1%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Behavior</div>
                                            <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Location/Behavior</div>
                                            <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Proximity</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initials</div>
                                        </div>
                                    </div>
                                    <div style={{ width: '100%', display: 'flex', fontSize: '13px' }}>
                                        <div style={{ width: '50%', fontSize: '14px' }}>
                                            {data && data.configResponses?.map((t: any, ind: number) => {
                                                return ind >= 48 && ind <= 71 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px' }} key={ind}>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}</div>
                                                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                                        <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                                    </div>
                                                </>);
                                            })}
                                        </div>
                                        <div style={{ width: '50%', fontSize: '14px' }}>
                                            {data && data.configResponses?.map((t: any, ind: number) => {
                                                return ind >= 72 && ind <= 95 && (<>
                                                    <div style={{ display: 'flex', flexDirection: 'row', fontSize: '13px', maxHeight: '21.5px', overflow: 'hidden' }} key={ind}>
                                                        <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.timeSlot.split(' ')[0]}{" " + t.timeSlot.split(' ')[1]}{t.timeSlot.split(' ')[1] === "AM" ? "+1" : ""}</div>
                                                        <div hidden={checkBox} style={{ width: '32%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.location && (legendcontrol ? t.location : t.locationName)}</div>
                                                        <div hidden={checkBox} style={{ width: '45%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity && (legendcontrol ? t.activity : t.activityName)}</div>
                                                        <div hidden={!checkBox} style={{ width: '39%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.activity}</div>
                                                        <div hidden={!checkBox} style={{ width: '40%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.proximity}</div>
                                                        <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>{t.staff && t.staff}</div>
                                                    </div>
                                                </>);
                                            })}
                                        </div>
                                    </div>

                                    <div style={{ fontSize: '14px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }}>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Name</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Initial</div>
                                            <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center' }}>Staff Signature</div>
                                        </div>
                                        {[1, 2, 3, 4].map((t: any, indx: number) => (
                                            <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600' }} key={indx}>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                                <div style={{ width: '30%', border: '1px solid #C9C9C9', textAlign: 'center', height: '25px' }}></div>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ fontSize: '14px' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', fontWeight: '600', height: '60px' }}>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', textAlign: 'center' }}>PATIENT LABEL HERE</div>
                                            <div style={{ width: '35%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Charge Nurse Signature</div>
                                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>Start Date:</div>
                                            <div style={{ width: '15%', border: '1px solid #C9C9C9', paddingLeft: '10px' }}>End Date:</div>
                                        </div>
                                    </div>
                                </>
                                }
                            </section>
                            {/* Single Report Time PM View End  */}
                        </div>

                    ))
                }
            </div>

        </div>
    )
}

export default PatientReport