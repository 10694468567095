import { useEffect } from "react"
import { FormControl, InputLabel, MenuItem, Select, TextField, CircularProgress } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { getOrgByID, updateOrganizationDetails } from "../../slices/thunk"
import { Button } from "primereact/button"
import { formatPhoneNumber } from "../../helpers/common"
import { toast } from "react-toastify";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import Loader from "../../components/loader/Loader"
import useZipcode from "../../hooks/usefetch"

const labelStyle = "original"
const timezones = {
    ...allTimezones,
    "Europe/Berlin": "Frankfurt",
}
interface FormData {
    id: string
    name: string
    email: string
    mobileNumber: string
    websiteUrl: string
    type: string
    hippaPrivacyOfficerFirstName: string
    hippaPrivacyOfficerLastName: string
    proximityVerification: string
    geofencing: string
    officemobileNumber: string
    q15Access: string
    duration: string
    startTime: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    country: string
    zip: string
    cFPerson: string
    cLPerson: string
    cEmail: string
    cPhone: string
    npi: string
    tin: string
    timeZone: string
    sos: boolean
    q15OneHour: boolean
    q15OneToOne: boolean
    eyeSight: boolean
    updateRequest: boolean
    patientsCountForStaff: Number
}

const ParticularOrgUpdate = () => {

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const { options } = useTimezoneSelect({ labelStyle, timezones })
    const dispatch = useDispatch<any>()
    const params = useParams()
    const { orgData } = useSelector(
        (state: any) => state.Org
    )
    const { organization } = useSelector(
        (state: any) => state.Login
    )
    const { loading: OrgLoading } = useSelector((state: any) => state.Organization)
    const [orgdatabyId, setOrgdatabyId] = useState<any>(orgData);
    const [editControl, setEditControl] = useState<boolean>(true)
    const [formData, setFormData] = useState<FormData>({
        id: "",
        name: "",
        email: "",
        mobileNumber: "",
        websiteUrl: "",
        type: "",
        hippaPrivacyOfficerFirstName: "",
        hippaPrivacyOfficerLastName: "",
        proximityVerification: "",
        geofencing: "",
        q15Access: "",
        officemobileNumber:"",
        duration: "",
        startTime: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        cFPerson: "",
        cLPerson: "",
        cEmail: "",
        cPhone: "",
        npi: "",
        tin: "",
        timeZone: "",
        sos: false,
        q15OneHour: false,
        q15OneToOne: false,
        eyeSight: false,
        updateRequest: false,
        patientsCountForStaff: 0,
    })

    

    const [zipCode, setZipCode] = useState("");
    const { data, zipLoading } = useZipcode(zipCode);

    useEffect(() => {
        const locationData = data.data?.[0];
      
        setFormData((prevFormData) => ({
          ...prevFormData,
          state: locationData?.state || "",
          country: locationData?.countryISO || "",
          city: locationData?.city || "",
        }));
      }, [data]);
      

    useEffect(() => {
        if (!params?.id) return
        getOrgByID(dispatch, organization)
    }, [params?.id, dispatch, organization])

    useEffect(() => {
        setOrgdatabyId(orgData)
    }, [orgData])

    useEffect(() => {
        setFormData({
            id: orgdatabyId?.id,
            name: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0]?.name) || "",
            email: orgdatabyId?.email || "",
            mobileNumber: orgdatabyId?.mobileNumber || "",
            websiteUrl: orgdatabyId?.websiteUrl || "",
            type: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0].type) || "",
            hippaPrivacyOfficerFirstName: (orgdatabyId?.hippaprivacyofficer && orgdatabyId?.hippaprivacyofficer[0]?.firstName) || "",
            hippaPrivacyOfficerLastName: (orgdatabyId?.hippaprivacyofficer && orgdatabyId?.hippaprivacyofficer[0]?.lastName) || "",
            startTime: orgdatabyId?.shift?.startTime || "",
            duration: orgdatabyId?.shift?.duration || "",
            proximityVerification: orgdatabyId?.proximityVerification || "",
            q15Access: orgdatabyId?.q15Access || "",
            geofencing: orgdatabyId?.geofencing || "",
            addressLine1: (orgdatabyId?.contact && orgdatabyId.contact[0]?.addressLine1) || "",
            addressLine2: (orgdatabyId?.contact && orgdatabyId.contact[0]?.addressLine2) || "",
            officemobileNumber: (orgdatabyId?.contact && orgdatabyId.contact[0]?.officemobileNumber) || "",
            city: (orgdatabyId?.contact && orgdatabyId.contact[0]?.city) || "",
            state: (orgdatabyId?.contact && orgdatabyId.contact[0]?.state) || "",
            country: (orgdatabyId?.contact && orgdatabyId.contact[0]?.country) || "",
            zip: (orgdatabyId?.contact && orgdatabyId.contact[0]?.zip) || "",
            cFPerson: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.firstName) || "",
            cLPerson: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.lastName) || "",
            cEmail: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.email) || "",
            cPhone: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.phoneNumber) || "",
            npi: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0]?.npi) || "",
            tin: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0]?.tin) || "",
            timeZone: orgdatabyId?.timeZone || "",
            sos: orgdatabyId?.sos || false,
            q15OneHour: orgdatabyId?.q15OneHour,
            q15OneToOne: orgdatabyId?.q15OneToOne,
            eyeSight: orgdatabyId?.eyeSight,
            updateRequest: orgdatabyId?.updateRequest,
            patientsCountForStaff: orgdatabyId?.patientsCountForStaff,
        })
        setEditControl(orgdatabyId?.updateRequest)
    }, [orgdatabyId])

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }
    const handlePostalCodeChange = async (e: any) => {
        formData.zip = e.target.value;
        setFormData({ ...formData });
    setZipCode(e.target.value);

        if (e.target.value.length === 5) {
        } else {
            formData.state = "";
            formData.country = "";
            formData.city = "";
        }
        setFormData({ ...formData });
    };

    const handleinputchange = (event: any) => {
        if (event.target.id === 'OrganizationName') {
            formData.name = event.target.value;
        } else if (event.target.id === 'OrganizationEmail') {
            formData.email = event.target.value;
        } else if (event.target.id === 'NPI') {
            formData.npi = event.target.value;
        } else if (event.target.id === 'TIN') {
            formData.tin = event.target.value;
        } else if (event.target.id === 'OrganizationType') {
            formData.type = event.target.value;
        } else if (event.target.id === 'AddressLine1') {
            formData.addressLine1 = event.target.value;
        } else if (event.target.id === 'AddressLine2') {
            formData.addressLine2 = event.target.value;
        } else if (event.target.id === 'city') {
            formData.city = event.target.value;
        } else if (event.target.id === 'state') {
            formData.state = event.target.value;
        } else if (event.target.id === 'country') {
            formData.country = event.target.value;
        } else if (event.target.id === 'MobileNumber') {
            formData.mobileNumber = event.target.value;
        } else if (event.target.id === 'WebsiteURL') {
            formData.websiteUrl = event.target.value;
        } else if (event.target.id === "ContactOffice") {
            formData.officemobileNumber = event.target.value;
        } else if (event.target.id === 'ContactpersonFirstName') {
            formData.cFPerson = event.target.value;
        } else if (event.target.id === 'ContactpersonLastName') {
            formData.cLPerson = event.target.value;
        } else if (event.target.id === 'ContactMobile') {
            formData.cPhone = event.target.value;
        } else if (event.target.id === 'Contactemail') {
            formData.cEmail = event.target.value;
        }
        setFormData({ ...formData });
    }

    const handleSaveChanges = () => {
        if (!params?.id) return
        else if (formData.zip.length > 0 && formData.city === "" && formData.state === "") {
            formData.city = "";
            formData.state = "";
            formData.country = "";
            toast.error("Please Enter Valid Zip Code");
            return;
        }
        const updatedFields = {
            id: params?.id,
            organizationdetails: [
                {
                    name: formData.name,
                    type: formData.type,
                    npi: formData.npi,
                    tin: formData.tin,
                },
            ],
            email: formData.email,
            websiteUrl: formData.websiteUrl,
            shift: {
                duration: formData.duration,
                startTime: formData.startTime,
            },
            timeZone: formData.timeZone,
            contact: [
                {
                    addressLine1: formData.addressLine1,
                    addressLine2: formData.addressLine2,
                    city: formData.city,
                    state: formData.state,
                    country: formData.country,
                    zip: formData.zip
                }
            ],
            pointofcontact: [
                {
                    firstName: formData.cFPerson,
                    lastName: formData.cLPerson,
                    email: formData.cEmail,
                    phoneNumber: formData.cPhone,
                }
            ],
            proximityVerification: formData.proximityVerification,
            geofencing: formData.geofencing,
            q15Access: formData.q15Access,
            sos: formData.sos,
            q15OneHour: formData.q15OneHour,
            q15OneToOne: formData.q15OneToOne,
            eyeSight: formData.eyeSight,
            updateRequest: formData.updateRequest,
            hippaprivacyofficer: [
                {
                    firstName: formData.hippaPrivacyOfficerFirstName,
                    lastName: formData.hippaPrivacyOfficerLastName,
                },
            ],
            mobileNumber: formData.mobileNumber,
        }
        dispatch(updateOrganizationDetails(params?.id, updatedFields, 0))
    }
    const handleSelectChange = (fieldName: string, value: any) => {
        setFormData({ ...formData, [fieldName]: value });
    };

    const handleCancelChanges = () => {
        setFormData({
            id: orgdatabyId?.id,
            name: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0]?.name) || "",
            email: orgdatabyId?.email || "",
            mobileNumber: orgdatabyId?.mobileNumber || "",
            websiteUrl: orgdatabyId?.websiteUrl || "",
            type: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0].type) || "",
            hippaPrivacyOfficerFirstName: (orgdatabyId?.hippaprivacyofficer && orgdatabyId?.hippaprivacyofficer[0]?.firstName) || "",
            hippaPrivacyOfficerLastName: (orgdatabyId?.hippaprivacyofficer && orgdatabyId?.hippaprivacyofficer[0]?.lastName) || "",
            startTime: orgdatabyId?.shift?.startTime || "",
            duration: orgdatabyId?.shift?.duration || "",
            proximityVerification: orgdatabyId?.proximityVerification || "",
            q15Access: orgdatabyId?.q15Access || "",
            geofencing: orgdatabyId?.geofencing || "",
            addressLine1: (orgdatabyId?.contact && orgdatabyId.contact[0]?.addressLine1) || "",
            addressLine2: (orgdatabyId?.contact && orgdatabyId.contact[0]?.addressLine2) || "",
            officemobileNumber: (orgdatabyId?.contact && orgdatabyId.contact[0]?.officemobileNumber) || "",
            city: (orgdatabyId?.contact && orgdatabyId.contact[0]?.city) || "",
            state: (orgdatabyId?.contact && orgdatabyId.contact[0]?.state) || "",
            country: (orgdatabyId?.contact && orgdatabyId.contact[0]?.country) || "",
            zip: (orgdatabyId?.contact && orgdatabyId.contact[0]?.zip) || "",
            cFPerson: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.firstName) || "",
            cLPerson: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.lastName) || "",
            cEmail: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.email) || "",
            cPhone: (orgdatabyId?.pointofcontact && orgdatabyId.pointofcontact[0]?.phoneNumber) || "",
            npi: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0]?.npi) || "",
            tin: (orgdatabyId?.organizationdetails && orgdatabyId?.organizationdetails[0]?.tin) || "",
            timeZone: orgdatabyId?.timeZone || "",
            sos: orgdatabyId?.sos || false,
            q15OneHour: orgdatabyId?.q15OneHour,
            q15OneToOne: orgdatabyId?.q15OneToOne,
            eyeSight: orgdatabyId?.eyeSight,
            updateRequest: orgdatabyId?.updateRequest,
            patientsCountForStaff: orgdatabyId?.patientsCountForStaff,
        })
    }

    return (
        <>
            {OrgLoading && <Loader />}
            <div className="row w-100" style={{ overflow: 'auto', maxHeight: '89vh' }}>
                <div className="col-md-2"></div>
                <div className="col-md-8" style={{color: !editControl ? "gray" : ''}}>
                    <h5>Organization: {formData.name}</h5>
                    <div className="row w-100 " style={{ marginTop: "20px" }}>
                        <div className="col-md-6 mb-4">
                            <TextField
                                id="OrganizationName" disabled label="Organization Name" variant="outlined" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.name} name="name" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <TextField id="OrganizationEmail" disabled label="Organization Email" variant="outlined" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.email} name="email" />
                        </div>
                    </div>
                    <div className="row w-100 ">
                        <div className="col-md-6 mb-4">
                            <TextField
                                id="NPI" label="NPI#" disabled variant="outlined" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.npi} name="npi" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <TextField id="TIN" label="TIN" disabled variant="outlined" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.tin} name="tin" />
                        </div>

                    </div>
                    <div className="row w-100 ">
                        <div className="col-md-4 mb-4">
                            <TextField id="OrganizationType" disabled label="Organization Type" variant="outlined" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.type} name="type" />
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField
                                id="MobileNumber"
                                disabled
                                label="Mobile Number"
                                variant="outlined"
                                fullWidth
                                value={formatPhoneNumber(formData.mobileNumber)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="mobileNumber"
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField id="WebsiteURL" disabled label="Website URL" variant="outlined" fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.websiteUrl} name="websiteUrl" />
                        </div>
                    </div>
                    <div className="row w-100 ">
                        <div className="col-md-6 mb-4">
                            <TextField id="HippaPrivacyOfficerFirstName" disabled label="Hippa Privacy Officer First Name" variant="outlined" fullWidth onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.hippaPrivacyOfficerFirstName} name="hippaPrivacyOfficerFirstName" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <TextField id="HippaPrivacyOfficerLastName" disabled label="Hippa Privacy Officer Last Name" variant="outlined" fullWidth onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.hippaPrivacyOfficerLastName} name="hippaPrivacyOfficerLastName" />
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-md-4">
                            <TextField
                                id="duration"
                                name="duration"
                                label="Shift Duration"
                                placeholder="Enter Duration Time"
                                value={formData.duration}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                disabled
                            />
                        </div>
                        <div className="col-md-4" style={{ position: 'relative', top: '16px' }} >
                            <div className="form">
                                <input type="time"
                                    autoComplete="off"
                                    className="form__input"
                                    value={formData.startTime}
                                    onChange={handleinputchange}
                                    id="outlined_success"
                                    style={{ border: '1px solid #c6c6c6', height: '70%', color: !editControl ? "gray" : '', width: '92%', marginLeft: '12px', borderRadius: '4px' }}
                                    placeholder=" "
                                    disabled
                                    />
                                <label style={{ color: "#6c6a6a" }} htmlFor="outlined_success" className="form__label" >
                                    Start Time</label>
                            </div>
                        </div>
                         <div className="col-md-4 mt-3">
                            <TextField
                            id="patientsCountForStaff"
                            label="Patient Count For Staff"
                            type="number"
                            variant="outlined"
                            fullWidth
                            value={formData.patientsCountForStaff}
                            onChange={(e) => {setFormData({ ...formData, patientsCountForStaff: Number(e.target.value)})}}
                            required
                            disabled
                            />
                        </div>
                    </div>
                    <div className="row w-100 ">
                        <div className="mt-0">
                            <label htmlFor="contact person" className="label">
                                Contact Person
                            </label>
                        </div>
                    </div>
                    <div className="row w-100 ">
                        <div className="col-md-6 mb-4">
                            <TextField id="ContactpersonFirstName" label="First Name" disabled={!editControl} variant="outlined" fullWidth onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.cFPerson} name="cFPerson" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <TextField id="ContactpersonLastName" label="Last Name" disabled={!editControl} variant="outlined" fullWidth onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.cLPerson} name="cLPerson" />
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-md-6 mb-4">
                            <TextField id="ContactMobile" label="Mobile Number" disabled={!editControl} variant="outlined" fullWidth onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formatPhoneNumber(formData.cPhone)} name="cPhone" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <TextField id="Contactemail" label="Email" variant="outlined" disabled={!editControl} fullWidth onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }} value={formData.cEmail} name="cEmail" />
                        </div>
                        <div className="col-md-6 mb-4">
                            <TextField id="ContactOffice" label="Office Number" variant="outlined" disabled={!editControl} fullWidth onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }} value={formatPhoneNumber(formData.officemobileNumber)} name="officemobileNumber" />
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-md-4 mb-4">
                            <TextField
                                id="AddressLine1"
                                label="Address Line 1"
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.addressLine1}
                                name="addressLine1"
                                disabled={!editControl}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField
                                id="AddressLine2"
                                label="Address Line 2"
                                variant="outlined"
                                fullWidth
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formData.addressLine2}
                                name="addressLine2"
                                disabled={!editControl}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField
                                id="zipcode"
                                label="Zip/Postal Code"
                                variant="outlined"
                                fullWidth
                                value={formData.zip}
                                onChange={handlePostalCodeChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    maxLength: 5,
                                    pattern: "\\d{5}",
                                }}
                                disabled={!editControl}
                            />
                            {(zipLoading && !formData.city) && <CircularProgress size={'25px'} />}
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-md-4 mb-4">
                                <TextField
                                    id="city"
                                    label="City"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.city}
                                    onChange={handleinputchange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={!editControl}
                                /> 
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField
                                id="state"
                                label="State"
                                variant="outlined"
                                fullWidth
                                value={formData.state}
                                disabled={!editControl}
                                onChange={handleinputchange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <TextField
                                id="country"
                                label="Country"
                                variant="outlined"
                                fullWidth
                                value={formData.country}
                                disabled={!editControl}
                                onChange={handleinputchange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                     
                    </div>
                    <div className="col-md-5 mb-4">
                            <FormControl fullWidth disabled>
                                <InputLabel id="timeZone-label">Time Zone</InputLabel>
                                <Select
                                    labelId="timeZone-label"
                                    id="timeZone"
                                    value={formData.timeZone}
                                    onChange={(e) => handleSelectChange("timeZone", e.target.value)}
                                    label="Time Zone"
                                    MenuProps={MenuProps}
                                >
                                    {options?.length > 0 ? options.map((option: any) => (
                                        <MenuItem style={{ fontSize: '11px' }} key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    )) :
                                        <MenuItem>
                                            No Time Zone Data
                                        </MenuItem>
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    
                    <div className="row w-100">
                        <div className="mt-0">
                            <label htmlFor="organizationType" className="label">
                                Access Control
                            </label>
                        </div>

                        <div className="col-md-4 mt-2">
                            <FormControl fullWidth variant="outlined" disabled>
                                <InputLabel id="q15-access-label">Q15</InputLabel>
                                <Select
                                    labelId="q15-access-label"
                                    id="q15Access"
                                    label="Q15"
                                    disabled
                                    onChange={handleChange}
                                    value={formData.q15Access}
                                    name="q15Access"
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-md-4 mt-2">
                            <FormControl fullWidth variant="outlined" disabled>
                                <InputLabel id="sos-label">SOS</InputLabel>
                                <Select
                                    labelId="sos-label"
                                    id="sos"
                                    label="SOS"
                                    disabled
                                    onChange={(event) => {
                                        const value = event.target.value === 'true';
                                        setFormData((prevData) => ({ ...prevData, sos: value }));
                                    }}
                                    value={formData.sos.toString()}
                                    name="sos"
                                >
                                    <MenuItem value="true">Yes</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-md-4 mt-2">
                            <FormControl fullWidth variant="outlined" disabled>
                                <InputLabel id="geofencing-label">Geo Fencing</InputLabel>
                                <Select
                                    labelId="geofencing-label"
                                    id="geofencing"
                                    label="Geo Fencing"
                                    disabled
                                    onChange={handleChange}
                                    value={formData.geofencing}
                                    name="geofencing"
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        {formData.q15Access === "Yes" && (
                            <div className="col-md-4 mt-2">
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel id="proximity-label">Proximity</InputLabel>
                                    <Select
                                        labelId="proximity-label"
                                        id="proximity"
                                        label="Proximity"
                                        disabled
                                        onChange={handleChange}
                                        value={formData.proximityVerification}
                                        name="proximityVerification"
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        )}

                        {formData.q15Access === "Yes" && (
                            <div className="col-md-4 mt-2">
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel id="q15OneToOne-label">One To One</InputLabel>
                                    <Select
                                        labelId="q15OneToOne-label"
                                        id="q15OneToOne"
                                        label="One To One"
                                        disabled
                                        onChange={(event) => {
                                            const value = event.target.value === 'true';
                                            setFormData((prevData) => ({ ...prevData, q15OneToOne: value }));
                                        }}
                                        value={formData.q15OneToOne?.toString()}
                                        name="q15OneToOne"
                                    >
                                        <MenuItem value="true">Yes</MenuItem>
                                        <MenuItem value="false">No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        )}

                        {formData.q15Access === "Yes" && (
                            <div className="col-md-4 mt-2">
                                <FormControl fullWidth variant="outlined" disabled>
                                    <InputLabel id="q15OneHour-label">One Hour</InputLabel>
                                    <Select
                                        labelId="q15OneHour-label"
                                        id="q15OneHour"
                                        label="One Hour"
                                        disabled
                                        onChange={(event) => {
                                            const value = event.target.value === 'true';
                                            setFormData((prevData) => ({ ...prevData, q15OneHour: value }));
                                        }}
                                        value={formData.q15OneHour?.toString()}
                                        name="q15OneHour"
                                    >
                                        <MenuItem value="true">Yes</MenuItem>
                                        <MenuItem value="false">No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        {formData.q15Access === 'Yes' && (
                            <div className="col-md-4 mt-2">
                            <FormControl fullWidth variant="outlined"  disabled>
                                <InputLabel id="eyeSight-label">Eye Sight</InputLabel>
                                <Select
                                    labelId="eyeSight-label"
                                    id="eyeSight"
                                    label="Eye Sight"
                                    onChange={(event) => {
                                        const value = event.target.value === 'true';
                                        setFormData((prevData) => ({ ...prevData, eyeSight: value }));
                                    }}
                                    value={formData.eyeSight ? 'true' : 'false'}
                                    name="eyeSight"
                                >
                                    <MenuItem value="true">Yes</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        )}
                    </div>

                    <div className="row w-100">
                    </div>
                    <div className="d-flex gap-3 justify-content-end mt-4">
                        <Button
                            label="Cancel"
                            onClick={handleCancelChanges}
                            severity="secondary"
                            style={{
                                cursor: 'pointer',
                                color: "#000",
                                backgroundColor: "#fff",
                                border: "2px solid #0f3995",
                            }}
                            hidden={!editControl}
                        />
                        <Button
                            label="Save"
                            style={{ backgroundColor: "#0f3995" }}
                            onClick={handleSaveChanges}
                            hidden={!editControl}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default ParticularOrgUpdate