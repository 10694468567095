// const baseURL = 'https://www.vitalszoom.com/loginapi/api';
const baseURL = 'http://47.32.254.89:6001/api';

const webSocketURL = 'http://47.32.254.89:6001/ws';

const webSocketClient = '/topic/sosAlarm';

const successCode = 'MHC - 0200';



export { successCode, baseURL ,webSocketURL ,webSocketClient };