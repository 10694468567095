import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import './staff.css'
import { DatePicker } from '@mui/x-date-pickers';
import { formatDateToYYYYMMDD, formatPhoneNumber, formatSSN, formatEmail } from '../../helpers/common';
import { toast } from 'react-toastify';
import { createStaff } from '../../slices/thunk';
import Loader from '../../components/loader/Loader';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Button } from 'primereact/button';
import { CircularProgress } from '@material-ui/core';
import uploadImage from "../../assets/images/rectangle-59991.svg";
import useZipcode from '../../hooks/usefetch';
import { getStaffSuccess } from '../../slices/staff/reducer';

interface DropdownItem {
  id: string;
  value: string;
  type: string;
}

interface Dropdown {
  id: string;
  dropdown: string;
  list: DropdownItem[];
}

interface StaffCreationFormProps {
  modal: boolean;
  toggle: () => void;
  currentPage: number;
}
const StaffCreationForm: React.FC<StaffCreationFormProps> = ({ modal, toggle, currentPage }) => {
  const [userType, setUserType] = React.useState('');
  const [zipCode, setZipCode] = useState("");
  const { data, zipError, zipLoading } = useZipcode(zipCode);
  const [loading1, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(getStaffSuccess([]))
    }
  }, []);
  useEffect(() => {
      const locationData = data.data?.[0];
  
      if (data) 
        if (locationData) {
          formData.state = locationData?.state || "";
            formData.country = locationData?.countryISO || "";
            formData.city = locationData?.city || "";
          setLoading(false);
          setNameError({
            ...nameError,
            state: false,
            country: false,
            city: false,
          });
  
        } else {
          formData.state = "";
          formData.country = "";
          formData.city = "";
        
          setLoading(false);
        }
        setFormData({ ...formData });
  
    }, [data]);

  const { organization } = useSelector(
    (state: any) => state.Login
  );
  const { loading, genderList, specialityList, rolesList } = useSelector(
    (state: any) => state.Staff
  );
  const dispatch = useDispatch<any>()

  const handleChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value as string);
  };

  const initFormData = {
    firstName: '',
    middleName: '',
    lastName: '',
    dateofBirth: '',
    ssn: '',
    npi: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
    email: '',
    startDate: '',
    userType: '',
    country: '',
    gender: '',
    speciality: '',
    roles: '',
    profile: ''
  }
  let [formData, setFormData] = useState(initFormData);
  const [nameError, setNameError] = useState<{ [key: string]: boolean }>({})

  const [disabled, setDisabled] = useState<boolean>(false);
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisabled(e.target.checked);
  };
 
  const handlePostalCodeChange = async (e: any) => {
    const newZip = e.target.value;
    formData.postalCode = newZip;
    setFormData({ ...formData });
    setZipCode(e.target.value);

    if (newZip.length === 5) {
      setLoading(true);
     
    } else {
      formData.state = "";
      formData.country = "";
      formData.city = "";
      setLoading(false);
    }
    setFormData({ ...formData });
  };

  const handleDateChange = (date: any) => {
    setFormData({ ...formData, dateofBirth: formatDateToYYYYMMDD(date) })
  };
  const handleinputchange = (event: any) => {
    if (event.target.id === 'firstname') {
      formData.firstName = event.target.value;
    } else if (event.target.id === 'middlename') {
      formData.middleName = event.target.value;
    } else if (event.target.id === 'lastname') {
      formData.lastName = event.target.value;
    } else if (event.target.id === 'state') {
      formData.state = event.target.value;
    } else if (event.target.id === 'Email') {
      formData.email = event.target.value;
    } else if (event.target.id === 'SSN') {
      formData.ssn = event.target.value;
    } else if (event.target.id === 'addressline1') {
      formData.addressLine1 = event.target.value;
    } else if (event.target.id === 'addressline2') {
      formData.addressLine2 = event.target.value;
    } else if (event.target.id === 'city') {
      formData.city = event.target.value;
    } else if (event.target.id === 'state') {
      formData.state = event.target.value;
    } else if (event.target.id === 'PhoneNumber') {
      formData.phoneNumber = event.target.value;
    } else if (event.target.id === 'npi') {
      formData.npi = event.target.value;
    }
    setFormData({ ...formData });
  }
  const handleSaveClick = async () => {
    let newErrors = {
      firstName: !formData.firstName,
      middleName: !formData.middleName,
      lastName: !formData.lastName,
      dateofBirth: !formData.dateofBirth,
      ssn: !formData.ssn,
      npi: !formData.npi,
      addressLine1: !formData.addressLine1,
      addressLine2: !formData.addressLine2,
      city: !formData.city,
      state: !formData.state,
      postalCode: !formData.postalCode,
      phoneNumber: !formData.phoneNumber,
      email: !formData.email,
      country: !formData.country,
      speciality: !formData.speciality,
      roles: !formData.roles,
      usertype: !userType
    }
     if (formData.npi.length !== 10) {
          toast.error("NPI must be exactly 10 digits");
          return;
        }
    setNameError(newErrors)
    const hasErrors = Object.values(newErrors).some(error => error);
    if (hasErrors) {
      toast.error("Please Fill Required Field")
      return;
    }
    if (formData.postalCode.length > 0 && formData.city === "" && formData.state === "") {
      formData.city = "";
      formData.state = "";
      formData.country = "";
      toast.error("Please Enter Valid Zip Code");
      return;
    }

    const requestBody = {
      id: '',
      name: [
        {
          use: formData.middleName,
          given: formData.firstName,
          family: formData.lastName,
        },
      ],
      gender: !disabled ? formData?.gender : '',
      email: formData.email,
      role: formData?.roles || '',
      organization,
      userType: userType,
      startDate: formData.startDate,
      speciality: [
        formData?.speciality || ''
      ],
      dateofBirth: formData.dateofBirth || '',
      ssn: (formData.ssn).slice(0, 9),
      npi: formData.npi,
      contact: [
        {
          address: [{
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            city: formData.city,
            country: formData.country || '',
            state: formData.state || '',
            zip: formData.postalCode
          }],
          mobilePhone: formData.phoneNumber
        }
      ]
    };
    dispatch(createStaff(requestBody, handleCancel, organization, currentPage))
  //  handleCancel()
  };

  const handleSaveCancel = () => {
    setFormData(initFormData);
    setUserType('');
    setNameError({})
    setDisabled(false)
  }

  const handleCancel = () => {
    setFormData(initFormData);
    // setSelectedValues(initdrop);
    setDisabled(false)
    toggle();
    setUserType('');
    setNameError({})
  }
  const handleSelectChange = (fieldName: string, value: any) => {
    setFormData({ ...formData, [fieldName]: value });
  };

  const hiddenFileInput = useRef<any>(null)
  const handleProfileChange = () => {
    if (hiddenFileInput !== null) {
      hiddenFileInput.current.click();
    }
  }
  const handleImageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file instanceof Blob) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result !== null ? reader.result.toString() : "";
          setFormData(prevValues => ({ ...prevValues, profile: result }));
        }
        reader.readAsDataURL(file);
      }
    } else {
      console.error("No file selected");
    }
  }

  return (
    <Modal isOpen={modal}
      // toggle={handleCancel} 
      centered size='lg'>
      <div className="d-flex align-items-center justify-content-center">
        {loading && <Loader />}
        <div className="row">
          <div className="container col-md-12">
            <ModalHeader toggle={handleCancel}>
              Staff Register
              <span>
                <div onClick={handleProfileChange} hidden={formData.profile !== null && formData.profile !== "" && formData.profile !== "string"} style={{ left: "unset", width: "156px" }} className="vector-group">
                  <img className="frame-child4" style={{ cursor: 'pointer' }} alt="" src={uploadImage} />
                  <div className="upload-profile-pic1" style={{ fontSize: '13px', color: 'blue', cursor: 'pointer' }}>UPLOAD PROFILE PIC</div>
                </div>
                <div hidden={formData.profile === null || formData.profile === "" || formData.profile === "string"} style={{ left: "unset", width: "182px" }} className="vector-group">
                  <div style={{ position: 'absolute', top: "2px" }}><img style={{ height: '50px', width: '50px', borderRadius: "60px" }} alt='' src={formData.profile} onClick={handleProfileChange} /><input className="vector-group" style={{ position: 'absolute', top: '12px', display: 'none' }} type="file" ref={hiddenFileInput} onChange={handleImageChange} /></div>
                </div>
              </span>
            </ModalHeader>
            <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
              <div className="row w-100 " style={{ marginTop: '10px' }}>
                <div className='col-md-4 mb-2'>
                  <TextField
                    id="firstname"
                    label="First Name"
                    variant="outlined"
                    value={formData.firstName}
                    fullWidth
                    onChange={(e) => {
                      handleinputchange(e)
                      setNameError({ ...nameError, firstName: false })
                    }}
                    error={!!nameError.firstName}
                    helperText={nameError.firstName ? 'First Name is required' : ''}
                    required
                  />
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField
                    id="middlename"
                    label="Middle Name"
                    variant="outlined"
                    value={formData.middleName}
                    fullWidth
                    onChange={(e: any) => {
                      handleinputchange(e)
                      setNameError({ ...nameError, middleName: false })
                    }}
                    error={!!nameError.middleName}
                    helperText={nameError.middleName ? 'Middle Name is required' : ''}
                    required
                  />
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField
                    id="lastname"
                    label="Last Name"
                    variant="outlined"
                    value={formData.lastName}
                    fullWidth
                    onChange={(e) => {
                      handleinputchange(e)
                      setNameError({ ...nameError, lastName: false })
                    }}
                    error={!!nameError.lastName}
                    helperText={nameError.lastName ? 'Last Name is required' : ''}
                    required
                  />
                </div>
              </div>
              <div className="row w-100">
                <div className='col-md-4'>
                  <Autocomplete
                    id='gender'
                    options={genderList?.map((item: any) => item.value)}
                    value={formData.gender}
                    onChange={(e, v) => handleSelectChange('gender', v)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Gender'
                        variant="outlined"
                        error={!disabled && !!nameError.gender}
                        helperText={!disabled && nameError.gender ? nameError.gender ? "Gender is Required" : "" : ""}
                      />
                    )}
                    disabled={disabled}
                  />
                  <div style={{ display: 'flex', marginLeft: '15px', alignItems: 'center', marginTop: '-9px' }}>
                    <FormControlLabel
                      control={<Checkbox checked={disabled} onChange={handleCheckboxChange} />}
                      label="Declined to specify"
                    />
                  </div>
                </div>

                <div className='col-md-4'>
                  <DatePicker
                    // sx={{
                    //   border: nameError.dateofBirth ? "1px solid red" : "none",
                    //   borderRadius: "5px"
                    // }}
                    // value={formData?.dateofBirth === "" ? null: moment(formData?.dateofBirth).format("MM-DD-YYYY")}
                    label={'Date Of Birth'}
                    format='MM-DD-YYYY'
                    onChange={handleDateChange}
                  />
                </div>
                <div className='col-md-4'>
                  <TextField
                    id="SSN"
                    label="SSN"
                    variant="outlined"
                    value={formatSSN(formData.ssn)}
                    fullWidth
                    onChange={(e) => {
                      handleinputchange(e)
                      setNameError({ ...nameError, ssn: false })
                    }}
                    error={!!nameError.ssn}
                    helperText={nameError.ssn ? 'SSN is required' : ''}
                    required
                  />
                </div>
              </div>
              <div className="row w-100 mt-2">
                <div className='col-md-6 mb-2'>
                  <TextField
                    id="Email"
                    label="Email"
                    variant="outlined"
                    value={formData.email}
                    fullWidth
                    onChange={(e) => {
                      handleinputchange(e)
                      setNameError({ ...nameError, email: false })
                    }}
                    onBlur={() => {
                      try {
                        const formattedEmail = formatEmail(formData.email);
                        setFormData({ ...formData, email: formattedEmail });
                        setNameError({ ...nameError, email: false });
                      } catch (error) {
                        setNameError({ ...nameError, email: true });
                      }
                    }}
                    error={!!nameError.email}
                    helperText={nameError.email ? 'Email is invalid' : ''}
                    required
                  />
                </div>
                <div className='col-md-6 mb-2'>
                  <TextField
                    id="PhoneNumber"
                    label="Phone Number"
                    value={formatPhoneNumber(formData.phoneNumber)}
                    variant="outlined"
                    fullWidth
                    onChange={(e: any) => {
                      handleinputchange(e)
                      setNameError({ ...nameError, phoneNumber: false })
                    }}
                    error={!!nameError.phoneNumber}
                    helperText={nameError.phoneNumber ? 'Phone Number is invalid' : ''}
                  />
                </div>
              </div>
              <div className="row w-100 ">
                {/* <div className='col-md-6 mb-2'>
                  <TextField id="npi" label="NPI#" value={formData.npi} variant="outlined" fullWidth onChange={handleinputchange} />
                </div> */}
                <div className="col-md-6 mb-2">
                  <TextField
                    id="npi"
                    label="NPI#"
                    value={formData.npi}
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d{0,10}$/.test(inputValue)) {
                        setFormData({ ...formData, npi: inputValue });
                        setNameError({ ...nameError, npi: false })
                      }
                    }}
                    error={!!nameError.npi}
                    helperText={nameError.npi ? 'Npi is invalid' : ''}
                  />
                </div>

                <div className='col-md-6 mb-2'>
                  <FormControl fullWidth required error={!!nameError.roles} >
                    <InputLabel >Role</InputLabel>
                    <Select
                      value={userType}
                      label="Role"
                      onChange={(e) => {
                        handleChange(e)
                        setNameError({ ...nameError, userType: false })
                      }}
                    >
                      <MenuItem value={'Admin'}>Supervisor</MenuItem>
                      <MenuItem value={'Staff'}>Staff</MenuItem>
                      <MenuItem value={'Receptionist-Q15'}>Receptionist-Q15</MenuItem>
                      <MenuItem value={'Receptionist-sos'}>Receptionist-SOS</MenuItem>
                    </Select>
                    {nameError.usertype && <p style={{ color: 'red', fontSize: '12px' }}>Role is required</p>}
                  </FormControl>
                </div>

              </div>
              <div className='row w-100'>
                <div className='col-md-6 mb-2'>
                  <Autocomplete
                    id='speciality'
                    options={specialityList?.map((item: any) => item.value)}
                    value={formData.speciality}
                    onChange={(e, v) => {
                      handleSelectChange('speciality', v)
                      setNameError({ ...nameError, speciality: false })
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Speciality'
                        variant="outlined"
                        error={!!nameError.speciality}
                        helperText={nameError.speciality ? 'Speciality is invalid' : ''}
                      />
                    )}

                  />
                </div>
                <div className='col-md-6 mb-2'>
                  <Autocomplete
                    id='roles'
                    options={rolesList?.map((item: any) => item.value)}
                    value={formData.roles}
                    onChange={(e, v) => {
                      handleSelectChange('roles', v)
                      setNameError({ ...nameError, roles: false })
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Job Title'
                        variant="outlined"
                        error={!!nameError.roles}
                        helperText={nameError.roles ? 'Job Title is invalid' : ''}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row w-100 ">
                <div className='col-md-4 mb-2'>
                  <TextField
                    id="addressline1"
                    label="Address Line 1"
                    value={formData.addressLine1}
                    variant="outlined"
                    fullWidth
                    onChange={(e: any) => {
                      handleinputchange(e)
                      setNameError({ ...nameError, addressLine1: false })
                    }}
                    error={!!nameError.addressLine1}
                    helperText={nameError.addressLine1 ? 'AddressLine1 is invalid' : ''}
                    required
                  />
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField
                    id="addressline2"
                    label="Address Line 2"
                    value={formData.addressLine2}
                    variant="outlined"
                    fullWidth
                    onChange={(e: any) => {
                      handleinputchange(e)
                      setNameError({ ...nameError, addressLine2: false })
                    }}
                    error={!!nameError.addressLine2}
                    helperText={nameError.addressLine2 ? 'AddressLine2 is invalid' : ''}
                    required
                  />
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField
                    id="zipcode"
                    label="Zip/Postal Code"
                    variant="outlined"
                    fullWidth
                    value={formData.postalCode}
                    onChange={(e) => {
                      handlePostalCodeChange(e)
                      setNameError({ ...nameError, postalCode: false })
                    }}
                    error={!!nameError.postalCode}
                    helperText={nameError.postalCode ? 'Zip/Postal Code is required' : ''}
                    required
                    inputProps={{
                      maxLength: 5,
                      pattern: "\\d{5}",
                    }}
                  />
                  {(zipLoading && formData.postalCode.length === 5) && <CircularProgress size={'25px'} />}
                </div>
              </div>
              <div className="row w-100 ">
                <div className='col-md-4 mb-2'>
                    <TextField
                      id="city"
                      label="City"
                      variant="outlined"
                      fullWidth
                      value={formData.city}
                      onChange={(e: any) => {
                        handleinputchange(e)
                        setNameError({ ...nameError, city: false })
                      }}
                      error={!!nameError.city}
                      helperText={nameError.city ? 'City is invalid' : ''}
                      required
                    /> 
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField
                    id="state"
                    label="State"
                    variant="outlined"
                    fullWidth
                    required
                    value={formData.state}
                    onChange={(e: any) => {
                      handleinputchange(e)
                      setNameError({ ...nameError, state: false })
                    }}
                    error={!!nameError.state}
                    helperText={nameError.state ? 'City is invalid' : ''}
                  />
                </div>
                <div className='col-md-4 mb-2'>
                  <TextField
                    id="country"
                    label="Country"
                    required
                    variant="outlined"
                    fullWidth
                    value={formData.country}
                    onChange={(e: any) => {
                      handleinputchange(e)
                      setNameError({ ...nameError, country: false })
                    }}
                    error={!!nameError.country}
                    helperText={nameError.country ? 'City is invalid' : ''}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="">
              <div className="d-flex gap-3 justify-content-center">
                <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={handleCancel}></Button>
                {/* <Button label="Save" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => handleSaveClick(true)}></Button> */}
                <Button label="Save" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => handleSaveClick()}></Button>
              </div>
            </ModalFooter>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StaffCreationForm;