import React, { useEffect, useState } from "react";
import Calendar from "../../components/calendar";
import './../organizationDetails/form.css'
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import {
    Modal, ModalBody, ModalHeader
} from "reactstrap";
import { Paginator } from 'primereact/paginator'
import { getOrgByID } from "../../slices/organization/thunk";
import { saveOrganization } from "../../slices/login/reducer";
import { HttpLogin } from "../../utils/Http";
import { baseURL, successCode } from "../../configuration/url";
import { authorize } from "../../helpers/common";
import { fetchPatientData, getPatientDropdown, getStaffDropdown, getStaffDropdown1 } from "../../slices/thunk";
import { Tooltip } from "@mui/material";
import { FaSearch } from "react-icons/fa";
import localStorage from "redux-persist/es/storage";
import { grey, primarybg, primarytext } from "../../common/primary";
import AddOneToOne from "./AddOneToOne";
import EditOneToOneRoutineModal from "./editOneToOneRoutineModal";
import Loader from "../../components/loader/Loader";
import moment from "moment";
import DateBox from "../../components/calendar/dateBox";
import { Box, Tabs, Typography } from "@mui/material";

type ShiftName = 'Shift-A' | 'Shift-B' | 'Shift-C';
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: any;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: "85%", backgroundColor: '#f2f5f9', borderLeft: '1px solid #0303031f' }}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: "14px 24px 24px 24px" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const OneToOne = () => {
    const [ldate, setLdate] = useState<any>()
    const getCurrentFormattedDate = () => {
        const currentDate = new Date();
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: '2-digit', year: 'numeric' };
        return currentDate.toLocaleDateString('en-US', options);
    };

    const { oneData, oneDataElement, loading: thunkLoading } = useSelector((state: any) => state.OneToOne)
    const [value, setValue] = React.useState(0);
    const handleChange = (newValue: number) => {
        setValue(newValue);
    };

    const dispatch = useDispatch<any>();
    const initFormData = [{
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        oneToOneObserverName: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: "",
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    }];
    const [PatientDate, setPatientDate] = useState<any[]>(initFormData);
    const { organization } = useSelector((state: any) => state.Login);
    const [selectedDate, setSelectedDate] = useState<any>();
    const [totalElements, setTotalElements] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [date, setDate] = useState(getCurrentFormattedDate);
    const [currentPage, setCurrentPage] = useState(0);
    const [Loading, setLoading] = useState<boolean>(false)
    const { orgTimeZone } = useSelector((state: any) => state.LargeScreen)
    const [noOfShift, setNoOfShift] = useState<any>(0);
    const { orgData, shiftStartTime } = useSelector((state: any) => state.Org)
    const formatDate = (date: any) => {
        const options = { day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };
    const { patientDropdown } = useSelector((state: any) => state.Patient);
    const handleSearchChange = (e: any) => {
        setSearchQuery(e.target.value);
    };


    const [selectedTab, setSelectedTab] = useState<ShiftName>('Shift-A');

    // useEffect(() => {
    //     if (value) {
    //         alert(value)
    //       setSelectedDate(value)
    //     }
    // }, [])
    useEffect(() => {
        const handleStartTime = () => {
            const originalLocalStorageGetItem = window.localStorage.getItem.bind(window.localStorage);
            const crtime = originalLocalStorageGetItem("LTime");

            if (crtime) {
                const formattedDate = moment(crtime, "MM/DD/YYYY HH:mm a").format("YYYY-MM-DD");
                setSelectedDate(formattedDate);
            }
        };

        handleStartTime();
    }, []);
    // useEffect(() => {
    //     if (!selectedDate) return
    //     // alert(selectedDate)
    // }, [selectedDate])
    useEffect(() => {
        saveOrganization(dispatch);
        getPatientDropdown(dispatch, organization);
        getOrgByID(dispatch, organization);
    }, [dispatch, selectedDate]);

    const [editModal1, setEditModal1] = useState(false);
    const [editModalview, setEditModalview] = useState(false);
    const [SelectView, setSelectView] = useState<any>('')
    const [shifts, setShifts] = useState([
        {
            date: "",
            id: "",
            organization: "",
            pid: "",
            shift: [
                {
                    shiftName: "",
                    shiftTime: "",
                    shiftIncharge: "",
                    record: [
                        {
                            distance: "",
                            endTime: "",
                            note: [
                                {
                                    note: "",
                                    time: ""
                                }
                            ],
                            staffId: "",
                            staffName: "",
                            startTime: ""
                        }
                    ]
                }
            ]
        }
    ]);

    const [formValues, setFormValues] = useState({
        date: "",
        endTime: "",
        id: "",
        oneToOneObserver: "",
        organization: "",
        patientId: "",
        patientName: "",
        patientIncharge: "",
        patientInchargeName: '',
        prescribedby: "",
        prescribedbyName: "",
        startTime: ""
    });

    const editChange = (patient: any) => {
        const { header1 } = authorize();
        if (patient) {
            setFormValues({
                date: patient.date || '',
                endTime: patient.endTime || '',
                id: patient.id || '',
                oneToOneObserver: patient.oneToOneObserver || '',
                organization: patient.organization || '',
                patientId: patient.patientId || '',
                patientName: patient.patientName || '',
                patientIncharge: patient.shiftIncharge || '',
                patientInchargeName: patient.patientIncharge || '',
                prescribedby: patient.staffId || '',
                prescribedbyName: patient.staffName || '',
                startTime: patient.startTime || ''
            });
            setEditModal1(true);
            setLoading(true);
            const shiftName = renderTabContent()
            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOnePatientList?date=${moment(selectedDate).format('YYYYMMDD')}&organization=${organization}&page=${currentPage}&pageSize=5&shiftName=${shiftName}&search=${searchQuery}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        setPatientDate(response.data.data.content || []);
                        setTotalElements(response.data.data.totalElements);
                    } else {
                        setPatientDate([""]);
                    }
                    setLoading(false);
                });

            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOneByPidAndDateAndOrganizationAndShift?date=${moment(selectedDate).format('YYYYMMDD')}&organization=${organization}&patientId=${patient.patientId}&shiftName=${shiftName}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        const responseData = response.data.data;
                        const transformedData = [
                            {
                                date: moment(selectedDate).format('YYYYMMDD'),
                                id: responseData.id,
                                organization: organization,
                                pid: responseData.patientId,
                                shift: [
                                    {
                                        shiftName: responseData.shiftName,
                                        shiftTime: responseData.shiftTime,
                                        shiftIncharge: responseData.shiftIncharge,
                                        record: responseData.record
                                    }
                                ]
                            }
                        ];
                        setShifts(transformedData);  // Update the shifts data
                    } else {
                        setShifts([{
                            date: "",
                            id: "",
                            organization: "",
                            pid: "",
                            shift: [{
                                shiftName: "",
                                shiftTime: "",
                                shiftIncharge: "",
                                record: [{
                                    distance: "",
                                    endTime: "",
                                    note: [{ note: "", time: "" }],
                                    staffId: "",
                                    staffName: "",
                                    startTime: "",
                                }]
                            }]
                        }]);
                    }
                    setLoading(false);
                });
        }
    };
    
    const Viewstaff = (patient: any) => {
        if (patient) {
            setFormValues({
                date: patient.date || '',
                endTime: patient.endTime || '',
                id: patient.id || '',
                oneToOneObserver: patient.oneToOneObserver || '',
                organization: patient.organization || '',
                patientId: patient.patientId || '',
                patientName: patient.patientName || '',
                patientIncharge: patient.shiftIncharge || '',
                patientInchargeName: patient.patientIncharge || '',
                prescribedby: patient.staffId || '',
                prescribedbyName: patient.staffName || '',
                startTime: patient.startTime || ''
            });
            setEditModalview(true);
        }
        setLoading(true);
        const { header1 } = authorize();
        try {
            const shiftName = renderTabContent();
            HttpLogin.axios().get(`${baseURL}/oneToOne/oneToOneByPidAndDateAndOrganizationAndShift?date=${moment(selectedDate).format('YYYYMMDD')}&organization=${organization}&patientId=${patient.patientId}&shiftName=${shiftName}`, { headers: header1 })
                .then((response) => {
                    if (response.data.message.code === successCode) {
                        const responseData = response.data.data;
                        const transformedData = [
                            {
                                date: moment(selectedDate).format('YYYYMMDD'),
                                id: responseData.id,
                                organization: organization,
                                pid: responseData.patientId,
                                shift: [
                                    {
                                        shiftName: responseData.shiftName,
                                        shiftTime: responseData.shiftTime,
                                        shiftIncharge: responseData.shiftIncharge,
                                        record: responseData.record
                                    }
                                ]
                            }
                        ];
                        setShifts(transformedData);
                    } else {
                        setShifts([{
                            date: "",
                            id: "",
                            organization: "",
                            pid: "",
                            shift: [{
                                shiftName: "",
                                shiftTime: "",
                                shiftIncharge: "",
                                record: [{
                                    distance: "",
                                    endTime: "",
                                    note: [{ note: "", time: "" }],
                                    staffId: "",
                                    staffName: "",
                                    startTime: "",
                                }]
                            }]
                        }]);
                    }
                    setLoading(false);
                });
            setLoading(false);
        } catch (error: any) {
            console.error('API Error:', error)
            setLoading(false);
        }
        //     }
        // }, [searchQuery, currentPage, forDate, organization, selectedTab, year, month, date]);
    }

    const getNumberOfShifts = (duration: number): number => {
        if (duration <= 0) {
            throw new Error("Duration must be a positive number.");
        }
        return 24 / duration; // Return the number of shifts
    };
    useEffect(() => {
        saveOrganization(dispatch);
        getStaffDropdown(dispatch, organization);
        getStaffDropdown1(dispatch, organization);
        getOrgByID(dispatch, organization);

        let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
        setNoOfShift(numShift);

        return () => {
            setPatientDate(initFormData);
        };
    }, []);

    const [modal, setModal] = useState(false);
    const [Fecthcheck, setFecthcheck] = useState<boolean>(false)
    const toggle = () => {
        setModal(!modal);
        setFecthcheck(!Fecthcheck)
        setErrors({});
    };

    useEffect(() => {
        const tabs = renderTabContent()
        fetchPatientData(dispatch, organization, currentPage, searchQuery ? searchQuery : '', moment(selectedDate).format('YYYYMMDD'), tabs)
    }, [value, Fecthcheck, searchQuery, selectedDate]);

    const fetchPatientDropdownByDate = async (date: any, tab: any) => {
        const { header1 } = authorize();
        setLoading(true);
        try {
            const response = await axios.get(`${baseURL}/oneToOne/oneToOnePatientList?date=${date}&organization=${organization}&page=${currentPage}&pageSize=5&shiftName=${tab}`, { headers: header1 });
            if (response.data.message.code === successCode) {
                setPatientDate(response.data.data.content || []);
                setLoading(false);
            } else {
                setPatientDate([]);
                console.error('No Record:');
            }
        } catch (error) {
            setPatientDate([]);
            console.error('No Record:');
        }
    };

    const handleDateChange = (event: any) => {
        const newDate = event.target.value;
        if (newDate) {
            setSelectedDate(newDate);
        }
    };


    // useEffect(() => {
    //     const currentDate = `${moment(selectedDate, "YYYY-MM-DD").format('YYYYMMDD')}`;
    //     fetchPatientDropdownByDate(currentDate, selectedTab);
    // }, [selectedDate, selectedTab]);

    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});

    const renderTabContent = () => {
        switch (value) {
            case 0:
                return "Shift-A";
            case 1:
                return "Shift-B";
            case 2:
                return "Shift-C";
            case 3:
                return "Shift-D";
            case 4:
                return "Shift-E";
            case 5:
                return "Shift-F";
            default:
                return "No Content Available";
        }
    };

    const [shiftsTime, setShiftsTime] = useState<any>([]);
    const generateShifts = (
        startTimeStr: any,
        duration: any,
        totalShifts: any
    ) => {
        const shifts = [];

        let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

        for (let i = 0; i < totalShifts; i++) {
            let endTime = new Date(
                startTime.getTime() + Number(duration) * 60 * 60 * 1000
            );
            const startTimeFormatted = startTime.toTimeString().slice(0, 5);
            const endTimeFormatted = endTime.toTimeString().slice(0, 5);
            const shiftName = `Shift-${String.fromCharCode(65 + i)}`;
            shifts.push({
                shift_name: shiftName,
                start_time: startTimeFormatted,
                end_time: endTimeFormatted,
            });
            startTime = endTime;
        }
        setShiftsTime(shifts);
    };
    useEffect(() => {
        generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
    }, [orgData])
    const [dateRange, setDateRange] = useState<any>()
    useEffect(() => {
        if (!shiftsTime) return
        setDateRange(shiftsTime[value])
    }, [shiftsTime, value])
    return (
        <div>
            {Loading && <Loader />}
            {thunkLoading && <Loader />}

            <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
                <DateBox selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleDateChange={handleDateChange} countCheck={null} dateRange={dateRange} />
            </div>
            <div>
                <div style={{ display: "flex", backgroundColor: "#eaf2fa", borderRight: "1px groove #ccd2d8", height: "79vh" }}>
                    <div style={{ width: '15%', display: "flex", flexDirection: "column", paddingTop: "20px", alignItems: "center" }}>
                        {
                            Array.from({ length: noOfShift }).map((_, index) => (
                                <button
                                    className={`nav-link ${value === index ? 'active' : ''}`}
                                    id={`Shift-${String.fromCharCode(65 + index)}-tab`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`Shift-${String.fromCharCode(65 + index)}`}
                                    aria-selected={value === index}
                                    onClick={() => handleChange(index)}
                                    style={{ backgroundColor: value === index ? '#cddcfa' : '', color: value === index ? '' : 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                                >
                                    {`Shift-${String.fromCharCode(65 + index)}`}
                                </button>
                            ))
                        }
                    </div>
                    {Array.from({ length: noOfShift }).map((_, index) => (
                        <TabPanel value={value} index={index}>
                            {/* <ShiftView selectedTab={value} selectedDate={selectedDate} noOfShift={noOfShift} rnInchargeList={rnInchargeList} incharge={incharge} setIncharge={setIncharge} socialWorkerList={socialWorkerList} setLoading={setLoading} /> */}
                            <div style={{ width: "100%", backgroundColor: "#f2f5f9" }} >
                                <div style={{ textAlign: "start", borderBottom: "1px groove #ccd2d8", height: "70px", lineHeight: 3.5, padding: "3px 0px 0px 10px", display: "flex", justifyContent: "space-between" }}>
                                    <span style={{ fontSize: "19px", fontWeight: 600, fontFamily: "poppins" }}>{moment(selectedDate).format("ddd DD") + " "}{selectedTab} 1:1 Assignment </span>
                                    <div className="col-md-4 d-flex justify-content-end align-items-center gap-2 mb-2">
                                        <div style={{ backgroundColor: primarybg, borderRadius: "4px", cursor: "pointer", fontSize: '30px', color: 'white', display: "flex", justifyContent: "center", alignItems: "center", width: "35px", height: "35px" }}>
                                            <i style={{ fontSize: "36px", fontWeight: "lighter" }} className="material-icons" onClick={toggle}>add</i>
                                        </div>
                                        <div className="mx-0 search-container d-flex align-items-center">
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                className="search form-control"
                                                value={searchQuery}
                                                onChange={handleSearchChange}

                                            />
                                            <FaSearch className="search-icon mt-1" />
                                        </div>

                                    </div>
                                </div>
                                <table className="table table-bordered" style={{ fontSize: '13px',  fontFamily: "poppins" }}>
                                    <thead style={{ backgroundColor: grey }}>
                                        <tr>
                                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
                                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Patient Name</th>
                                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Start Date & Time</th>
                                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>End Date & Time</th>
                                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Prescribed by</th>
                                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Technician</th>
                                            <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {oneData && oneData.length > 0 ? (
                                            oneData.map((patient: any, index: number) => (
                                                <tr key={index}>
                                                    <td className="text text-center">{currentPage * 5 + index + 1}</td>
                                                    <td
                                                        style={{ cursor: "pointer", textAlign: "center" }}
                                                        onClick={() => editChange(patient)}
                                                        className="text"
                                                    >
                                                        {patient.patientName}
                                                    </td>
                                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.startTime}</td>
                                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.endTime}</td>
                                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.shiftInchargeName}</td>
                                                    <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{patient.staffName}</td>
                                                    <td className="text" style={{ display: "flex", justifyContent: "space-around", height: "35px", alignItems: "center" }}>
                                                        <Tooltip title="Edit" arrow>
                                                            <div className="text-primary">
                                                                <i
                                                                    style={{ fontSize: "18px", fontWeight: "lighter", cursor: "pointer", color: "#a5a5aa" }}
                                                                    onClick={() => { setEditModal1(true); editChange(patient); }}
                                                                    className="material-icons">create</i>
                                                            </div>
                                                        </Tooltip>
                                                        <Tooltip title="View" arrow>
                                                            <div className="text-primary">
                                                                <i
                                                                    style={{ fontSize: "18px", fontWeight: "lighter", cursor: "pointer", color: "#a5a5aa" }}
                                                                    onClick={() => { setEditModalview(true); Viewstaff(patient); setSelectView(patient)}}
                                                                    className="material-icons">preview</i>
                                                            </div>

                                                        </Tooltip>
                                                    </td>

                                                </tr>

                                            ))


                                        ) : (

                                            <tr>
                                                <td colSpan={8} className="text-center" style={{ fontFamily: "poppins"}}>There are no records....</td>
                                            </tr>

                                        )}
                                    </tbody>

                                </table>
                                <div className="pagination-container">
                                    <div className="d-flex justify-content-center">
                                        <Paginator
                                            first={currentPage * 5}
                                            rows={5}
                                            totalRecords={oneDataElement}
                                            onPageChange={(e: any) => {
                                                setCurrentPage(e.page);
                                                fetchPatientData(dispatch, organization, e.page, searchQuery ? searchQuery : '', moment(selectedDate).format('YYYYMMDD'), selectedTab)
                                                // fetchPatientData(dispatch, organization, e.page, searchQuery ? searchQuery : '', moment(selectedDate).format('YYYYMMDD'), selectedTab)
                                            }}
                                            currentPageReportTemplate={`Page ${currentPage} of ${oneDataElement / 5}`}
                                        />
                                    </div>
                                </div>
                                <Modal isOpen={editModalview} centered size='lg'>
                                    <div className="d-flex align-items-center justify-content-center vh-90">
                                        <div className='row' style={{ width: "100%" }}>
                                            <div className='container col-md-12'>
                                                <ModalHeader toggle={() => setEditModalview(false)}>
                                                    <span>{formValues.patientName}</span>&nbsp;&nbsp;&nbsp;
                                                    {/* <span>{moment(selectedDate).format('DD MMM YYYY') + " "}</span> */}
                                                </ModalHeader>
                                                <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto', display: "flex", justifyContent: "center" }}>
                                                    <table className="table table-bordered" style={{ fontSize: '13px' }}>
                                                        <thead style={{ backgroundColor: grey }}>
                                                            <tr>
                                                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey, width: '13px' }}>S.No</th>
                                                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Assigned Technician</th>
                                                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>Start Date & Time</th>
                                                                <th scope="col" className="text-center" style={{ color: primarytext, backgroundColor: grey }}>End Date & Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {shifts.length > 0 && shifts[0].shift && shifts[0].shift.length > 0 && shifts[0].shift[0].record && shifts[0].shift[0].record.length > 0 ? (
                                                                shifts[0].shift[0].record.map((record: any, recordIndex: any) => (
                                                                    <>
                                                                        <tr
                                                                        >
                                                                            <td className="text text-center">{currentPage * 5 + recordIndex + 1}</td>
                                                                            <td style={{ cursor: "pointer", textAlign: "center" }} className="text" >
                                                                                {record.staffName}
                                                                            </td>
                                                                            <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>
                                                                                {record.startTime}
                                                                            </td>
                                                                            <td className="text" style={{ cursor: "pointer", textAlign: "center" }}>{record.endTime}</td>
                                                                        </tr>
                                                                    </>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={4} className="text-center">There are no records...</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </ModalBody>
                                            </div>
                                        </div>
                                    </div >
                                </Modal >
                                <AddOneToOne
                                    IsOpen={modal}
                                    Close={toggle}
                                    errors={errors}
                                    setErrors={setErrors}
                                    setPatientDate={setPatientDate}
                                    selectedDate={selectedDate}
                                    selectedTab={renderTabContent}
                                    shifts={shifts}
                                    patientDropdown={patientDropdown}
                                />
                                <EditOneToOneRoutineModal IsOpen={editModal1} closeModal={setEditModal1} formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors} shifts={shifts} setShifts={setShifts} forDate={moment(selectedDate).format('YYYYMMDD')} editChange={editChange} selectedTab={renderTabContent} Fecthcheck={Fecthcheck} setFecthcheck={setFecthcheck} />
                                {/* <EditOneToOneRoutineModal IsOpen={editModal1} closeModal={setEditModal1} formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors} shifts={shifts} setShifts={setShifts} forDate={moment(selectedDate).format('YYYYMMDD')} editChange={editChange} selectedTab={selectedTab} /> */}

                            </div>
                        </TabPanel>
                    ))}
                </div>
            </div>
        </div >

    )
}

export default OneToOne