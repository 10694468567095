import React, { useEffect, useState } from 'react'
import { Autocomplete, MenuItem, Select, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Modal, ModalFooter, ModalBody, ModalHeader } from "reactstrap";
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { baseURL, successCode } from '../../configuration/url';
import axios from 'axios';
import { authorize } from '../../helpers/common';
import { toast } from 'react-toastify';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import dayjs from 'dayjs';
interface EditOneToOneRoutineModalProps {
    IsOpen: any
    closeModal: any
    formValues: any
    setFormValues: any
    errors: any
    setErrors: any
    shifts: any
    setShifts: any
    forDate: any
    editChange: any
    selectedTab: any
    Fecthcheck: any
    setFecthcheck: any
}


type RecordStringFields = 'startTime' | 'endTime';

const EditOneToOneRoutineModal: React.FC<EditOneToOneRoutineModalProps> = ({ IsOpen, closeModal, formValues, setFormValues, errors, setErrors, shifts, setShifts, forDate, editChange, selectedTab, Fecthcheck, setFecthcheck }) => {

    const { organization } = useSelector((state: any) => state.Login)
    const { patientDropdown } = useSelector((state: any) => state.Patient);
    const { staffDropdown, staffDropdown1 } = useSelector((state: any) => state.Staff);

    const handleRecordChange = (e: any, recordIndex: any) => {
        const { value } = e.target;
        setShifts((prevShifts: any) => {
            const updatedShifts = [...prevShifts];
            updatedShifts[0].shift[0].record[recordIndex].staffId = value;
            return updatedShifts;
        });
    };
 
    const handleInputChange = (
        e: any,
        recordIndex: number,
        field: string
    ) => {
        const value = e?.$d ? moment(e.$d).format('MMM DD, YYYY HH:mm') : ""; 
        const updatedShifts = [...shifts];
        updatedShifts[0].shift[0].record[recordIndex][field] = value || null; 
        setShifts(updatedShifts);
    };
    const addRecord = () => {
        setShifts((prevShifts: any) => {
            const newRecord = {
                distance: "",
                endTime: "",
                note: [
                    {
                        note: "",
                        time: ""
                    }
                ],
                staffId: "",
                staffName: "",
                startTime: "",
            };
            const updatedShifts = [...prevShifts];
            updatedShifts[0].shift[0].record.push(newRecord);
            return updatedShifts;
        });
    };
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const [record, setRecord] = useState<any>(null);

    const confirmDeleteRecord = (index: number, record: any) => {
        setDeleteIndex(index);
        setRecord(record)
        setConfirmDelete(true);
    };

    const removeRecord = async (index: any) => {
        if (deleteIndex !== null) {
            const url = `${baseURL}/eyesight/deleteStaff?date=${forDate}&endTime=${record?.endTime.split(" ")[3]?.slice(0, 5)}&organization=${organization}&pid=${formValues.patientId}&staffId=${record?.staffId}&startTime=${record?.startTime.split(" ")[3]?.slice(0, 5)}`;

            await axios.delete(url)
                .then(response => {
                    setShifts((prevShifts: any) => {
                        const updatedShifts = [...prevShifts];
                        updatedShifts[0].shift[0].record.splice(deleteIndex, 1);
                        return updatedShifts;
                    });
                    setFormValues({ ...formValues, endTime: '', startTime: '', prescribedby: '', prescribedbyName: '' })
                })
                .catch(error => {
                    console.error('There was an error deleting the record:', error);
                });
            setConfirmDelete(false);
            setDeleteIndex(null);
        }
    };
    const cancelDelete = () => {
        setConfirmDelete(false);
        setDeleteIndex(null);
    };


    const savePatients = async (patient: any) => {
        const { header1 } = authorize();
        try {
            let newErrors = {
                patientIncharge: !formValues.patientIncharge,
            };
            setErrors(newErrors);
            const hasErrors = Object.values(newErrors).some(error => error);
            if (hasErrors) {
                return;
            }

            let bodyData = {
                date: forDate,
                id: "",
                organization,
                pid: formValues.patientId,
                shift: shifts?.map((k: any) => ({
                    record: k.shift[0].record?.map((l: any) => ({
                        distance: "",
                        endTime: l.endTime.split(" ")[3]?.slice(0, 5) || "",
                        note: [{ note: "", time: "" }],
                        staffId: l.staffId,
                        startTime: l.startTime.split(" ")[3]?.slice(0, 5) || "",
                    })),
                    shiftIncharge: formValues.patientIncharge,
                    shiftName: "",
                    shiftTime: k.shift[0].shiftTime,
                })),
            };
            // switch (selectedTab) {
            //     case "Shift-A":
            //         bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-A");
            //         break;
            //     case "Shift-B":
            //         bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-B");
            //         break;
            //     case "Shift-C":
            //     default:
            //         bodyData.shift.forEach((shift: any) => shift.shiftName = "Shift-C");
            //         break;
            // }
            bodyData.shift.forEach((shift: any) => shift.shiftName = selectedTab());
            const response = await axios.post(`${baseURL}/eyesight/register`, bodyData, { headers: header1 });
            if (response.data.message.code === successCode) {
                toast.success(response.data.message.description)
                editChange(patient);
                closeModal(false);
                setFecthcheck(!Fecthcheck)
            } else {
                toast.error(response.data.message.description);
            }

        } catch (error) {
            toast.error("An error occurred during register.");
        }
    }

    const handlePrescribedchange = (e: any, value: any) => {
        setFormValues({
            ...formValues,
            patientIncharge: value ? value.id : null,
            patientInchargeName: value ? value.name : ''

        });

    }

    return (
        <div>
            <Modal isOpen={IsOpen} centered size='lg'>
                <div className="d-flex align-items-center justify-content-center vh-90">
                    <div className='row'>
                        <div className='container col-md-12'>
                            <ModalHeader toggle={() => {closeModal(false); setFecthcheck(!Fecthcheck)}}>
                                Edit Eye Sight Routine
                            </ModalHeader>
                            <ModalBody style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                                <div className="row w-100 " style={{ marginTop: '0px' }}>
                                    <div className='col-md-6 mb-2' >
                                        <label htmlFor="">Patient Name</label>
                                        <Autocomplete
                                            id='PatientName'
                                            options={patientDropdown}
                                            value={formValues.patientName}
                                            onChange={(e, v: any) => { formValues.patientId = v; setFormValues({ ...formValues }); }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" />
                                            )}
                                            disabled
                                        />
                                    </div>

                                    <div className='col-md-6 mb-2'>
                                        <label htmlFor="">Prescribed By</label>
                                        <Autocomplete
                                            id='Prescribedby'
                                            options={staffDropdown1?.length > 0 ? staffDropdown1 : []}
                                            getOptionLabel={(option) => `${option.name} - ${option.role}`}
                                            value={staffDropdown1?.find((staff: any) => staff.id === formValues.patientIncharge)}
                                            onChange={(event, value) => handlePrescribedchange(event, value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder={staffDropdown1?.length > 0 ? "Select Prescribed By" : "No Options"}
                                                />
                                            )}
                                        />
                                    </div>

                                    {shifts.length > 0 && shifts[0].shift && shifts[0].shift.length > 0 && shifts[0].shift[0].record && shifts[0].shift[0].record.length > 0 ? (
                                        shifts[0].shift[0].record.map((record: any, recordIndex: any) => (
                                            <>
                                                <div className='col-md-6 mb-2' >
                                                    <label htmlFor="">Technician</label>
                                                    <Select
                                                        style={{ height: "57px", paddingRight: '8px' }}
                                                        className="form-select"
                                                        fullWidth
                                                        value={record.staffId}
                                                        onChange={(e) => handleRecordChange(e, recordIndex)}
                                                    >
                                                        {staffDropdown?.length > 0 ? staffDropdown?.map((staff: any) => (
                                                            <MenuItem key={staff.id} value={staff.id}>
                                                                {staff.name}   - {staff.role}

                                                            </MenuItem>
                                                        )) :
                                                            <MenuItem>
                                                                No Data Found
                                                            </MenuItem>
                                                        }
                                                    </Select>
                                                </div>

                                                <div className="col-md-6 d-flex">
                                                    <div className='px-2'>
                                                        <label htmlFor="">Start Time</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            
                                                            <TimePicker

                                                                value={record.startTime === "" ? null : dayjs(record.startTime.split(" ")[3]?.slice(0, 5), 'HH:mm')}
                                                                // value={record.startTime}
                                                                onChange={(e: any) => { handleInputChange(e, recordIndex, 'startTime') }}
                                                                ampm={false}
                                                                format='HH:mm'
                                                                name='startTime'
                                                                slotProps={{
                                                                    textField: {
                                                                        id: "startTime",
                                                                        placeholder: "hh:mm",
                                                                        style: {
                                                                            height: "57px",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className='px-2'>
                                                        <label htmlFor="">End Time</label>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <TimePicker
                                                                value={record.endTime === "" ? null : dayjs(record.endTime.split(" ")[3]?.slice(0, 5), 'HH:mm')}
                                                                // value={record.endTime || ""}
                                                                onChange={(e: any) => { handleInputChange(e, recordIndex, 'endTime') }}
                                                                ampm={false}
                                                                format='HH:mm'
                                                                name='endTime'
                                                                slotProps={{
                                                                    textField: {
                                                                        id: "endTime",
                                                                        placeholder: "HH:mm",
                                                                        style: {
                                                                            height: "57px",
                                                                        },
                                                                    },
                                                                }}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        {shifts[0].shift[0].record.length - 1 === recordIndex && (
                                                            <div>
                                                                <i
                                                                    style={{ backgroundColor: "#d7dee5", borderRadius: "4px", marginRight: '8px' }}
                                                                    onClick={addRecord}
                                                                    className="material-icons"
                                                                >
                                                                    add
                                                                </i>
                                                            </div>
                                                        )}
                                                        {shifts[0].shift[0].record.length !== 1 && (
                                                            <div>
                                                                <i
                                                                    style={{ backgroundColor: "#d7dee5", borderRadius: "4px" }}
                                                                    onClick={() => confirmDeleteRecord(recordIndex, record)}
                                                                    className="material-icons"
                                                                >
                                                                    remove
                                                                </i>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <Dialog open={confirmDelete} onClose={cancelDelete} style={{ backgroundColor: "#ffffff36" }}>
                                                    <DialogTitle>Confirm Delete</DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            Are you sure you want to delete this Staff?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <div className="d-flex gap-3 justify-content-center">
                                                            <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={cancelDelete} ></Button>
                                                            <Button label="Delete Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={() => removeRecord(recordIndex)}></Button>
                                                        </div>
                                                    </DialogActions>
                                                </Dialog>
                                            </>
                                        ))
                                    ) : (
                                        <div>No records available</div>
                                    )}
                                </div>
                            </ModalBody>
                        </div>
                        <ModalFooter>
                            <div className="d-flex gap-3 justify-content-center">
                                <Button label="Cancel" severity="secondary" style={{ color: '#000', backgroundColor: '#94a0b7', fontSize: '12px', fontWeight: 'bold' }} onClick={() => { closeModal(false); setFecthcheck(!Fecthcheck) }} ></Button>
                                <Button label="Save Changes" style={{ backgroundColor: '#0f3995', fontSize: '12px', fontWeight: 'bold' }} onClick={(e: any) => {
                                    // setShifts((prevShifts: any) => {
                                    //     const newRecord = {
                                    //         distance: "",
                                    //         endTime: formValues.endTime,
                                    //         note: [
                                    //             {
                                    //                 note: "",
                                    //                 time: ""
                                    //             }
                                    //         ],
                                    //         staffId: formValues.prescribedby,
                                    //         staffName: "",
                                    //         startTime: formValues.startTime,
                                    //     };
                                    //     const updatedShifts = [...prevShifts];
                                    //     updatedShifts[0].shift[0].record.push(newRecord);
                                    //     return updatedShifts;
                                    // });                                    
                                    savePatients(e)
                                }}></Button>
                            </div>
                        </ModalFooter>
                    </div>
                </div>
            </Modal >
        </div>
    )
}

export default EditOneToOneRoutineModal