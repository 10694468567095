import React, { useEffect, useState } from "react";
import { FaRegDotCircle, FaPhoneAlt, FaEye, FaClipboardCheck, FaClipboardList } from "react-icons/fa";
import {
  FaBars,
  FaSpellCheck,
  FaBuilding,
  FaHospitalUser,
  FaUserFriends,
  FaBroadcastTower,
  FaReceipt,
  FaBed,
  FaUserCog,
  FaHourglassHalf,
  FaUser,
  FaChartBar,
  FaFileCode,
  FaTachometerAlt,
  FaAsymmetrik,
  FaWeixin,
  FaStopwatch,
} from "react-icons/fa";
import { SiRescuetime } from "react-icons/si";
import { GiTeamUpgrade } from "react-icons/gi";
import { MdWorkHistory } from "react-icons/md";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "./sidebar.css";
import Header from "../header";
import { useDispatch, useSelector } from "react-redux";
import { ListItemIcon, MenuItem } from "@mui/material";
import { Logout } from "@mui/icons-material";
import LogoutConfirmationModal from "../LogoutModel";
import { handleLogout } from "../../slices/thunk";
import logo192 from "./../../assets/images/Subtract.png";
import { white } from "../../common/primary";
import { TbReportAnalytics, TbReportMedical } from "react-icons/tb";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { RiMapPinTimeFill } from "react-icons/ri";

interface SidebarProps {
  children: any;
}

const ResizeHandler = ({ setIsOpen }: { setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {
  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 1068);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return null;
};

const Sidebar = (props: SidebarProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch<any>()
  const { userType, organization, jwt } = useSelector((state: any) => state.Login);
  const username = useSelector((state: any) => state.Login.userDetails);
  const { orgData } = useSelector((state: any) => state.Org); 
  const [activeSubmenu, setActiveSubmenu] = useState<number | null>(
    userType === "Admin" ? 8 : userType === "Staff" ? 12 : userType === "System Admin" ? 0 : userType === "Super Admin" ? 0 : 2
   );
  const { children } = props;
  const [isOpen, setIsOpen] = useState(window.innerWidth > 768);
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const isSecretKeyPage = location.pathname === '/secret-key';
  const isForgotPage = location.pathname === '/forgot-password';
  const ischangePage = location.pathname === '/change-password';
  const isResetSecret = location.pathname === '/resetSecretKey';
  const isRecreatePass = location.pathname === '/recreatePassword';
  const isotp = location.pathname === '/verify-otp';
  const patient = location.pathname === '/patient-view';
  const staffView = location.pathname === '/sos-view';
  const unblock = location.pathname === '/account-unblock';
  const superAdmin1 = location.pathname === "/super-admin";
  const patientView = patient || staffView
  const showSideBar = !isLoginPage && !unblock && !superAdmin1 && !isSecretKeyPage && !isForgotPage && !isResetSecret && !isRecreatePass && !ischangePage && !isotp;

  if (!showSideBar) {
    return null;
  }

  const oneToOneS = (userType === "Admin" || userType === "Staff") && orgData?.q15OneToOne
  const oneToOne = userType === "Admin" && orgData?.q15OneToOne
  const toggle = () => setIsOpen(!isOpen);

  const superAdmin = [{
    path: "/organization-details",
    name: "Master Organization",
    icon: <FaBuilding />,
    show: userType === "Super Admin",
  },
  ]
  const baseMenu = [
    (orgData?.q15Access === 'No' && orgData?.sos ?
      ({
        path: "/sos-view",
        name: "Dashboard",
        icon: <FaTachometerAlt />,
        show: userType === "Admin" || userType === 'Receptionist-sos',
        newTab: true,
        fullScreen: true
      }) :
      ({
        path: "/patient-view",
        name: "Dashboard",
        icon: <FaTachometerAlt />,
        show: userType === "Admin" || userType === 'Receptionist-Q15',
        newTab: true,
        fullScreen: true
      })),
    {
      path: `/organization-update/${organization}`,
      name: "Organization Details",
      icon: <FaBuilding />,
      show: userType === "System Admin",
    },
    {
      path: "/staff-table",
      name: "Staff Details",
      icon: <FaHospitalUser />,
      show: userType === "System Admin",
    },
    {
      path: "/beacon-table",
      name: "Device Manager",
      icon: <FaBroadcastTower />,
      show: userType === "System Admin",
    },
    {
      path: "/device-status",
      name: "Device Status",
      icon: <FaRegDotCircle />,
      show: userType === 'System Admin' && orgData.sos,
    }
  ];

  const menu = [

    {
      path: "/staff-configuration",
      name: "SOS Staff",
      icon: <FaFileCode />,
      show: userType === "Admin" || userType === "Receptionist-SOS",
    },
    {
      path: "/sos-Report",
      name: "SOS Call",
      icon: <FaPhoneAlt />,
      show: userType === "Admin",
    },
  ]

  const q15AccessMenu = [
    {
      name: "Patient & Bed",
      icon: <FaUserCog />,
      show: userType === "Admin",
      submenu: [
        {
          path: "/all-patient-table",
          name: "All Patient",
          icon: <FaUserFriends />,
          show: userType === "Admin",
        },
        {
          path: "/patient-table",
          name: "Active Patient",
          icon: <FaUser />,
          show: userType === "Admin",
        },
        {
          path: "/bed-table",
          name: "Bed",
          icon: <FaBed />,
          show: userType === "Admin",
        }
      ],
    },
    
      
    
    {
      name: "Staff Assignment",
      icon: <FaAsymmetrik />,
      show: userType === "Admin",
      submenu: [
        {
          path: "/shift-allocation",
          name: "Shift Allocation",
          icon: <MdWorkHistory />,
          show: userType === "Admin",
        },
        {
          path: "/q15-staff-configuration",
          name: "Q15 Slot",
          icon: <FaSpellCheck />,
          show: userType === "Admin",
        },
        {
          path: "/q15-shift-configuration",
          name: "Q15 Shift",
          icon: <RiMapPinTimeFill />,
          show: userType === "Admin",
        },
        {
          path: "/One-To-One",
          name: "1:1",
          icon: <FaWeixin />,
          show: oneToOne,
        },
        {
          path: "/one-hour-staff-configuration",
          name: "One Hour",
          icon: <FaWeixin />,
          show: userType === "Admin" && orgData?.q15OneHour,
        },
        {
          path: "/eye-sight",
          name: "Eyesight",
          icon: <FaEye />,
          show: userType === "Admin" && orgData?.eyeSight,
        }
      ]
    },
  ];
  const menu1 = [
    {
      path: "/add-bed-table",
      name: "Bed Master Configuration",
      icon: <FaBed />,
      show: userType === "System Admin",
    },
    // {
    //   path: "/q15-slot-assign",
    //   name: "Verify Q15 Routine",
    //   icon: <FaHourglassHalf />,
    //   show: userType === "Admin" || userType === "Staff",
    // },
    {
      path: "/q15-report-view",
      name: "Q15 Summary",
      icon: <FaChartBar />,
      show: userType === "Admin" || userType === "Staff",
    },
    // {
    //   path: "/q15-report",
    //   name: "Q15 Report",
    //   icon: <FaReceipt />,
    //   show: userType === "Admin" || userType === "Staff",
    // },

    // {
    //   path: "/one-to-one-report",
    //   name: "One To One Report",
    //   icon: <FaReceipt />,
    //   show: oneToOneS,
    // },
    {
      // path: "/all-report-view",
      name: "All Report",
      icon: <FaClipboardList />,
      show: userType === "Admin" || userType === "Staff",
      submenu: [
        {
          path: "/all-report-view",
          name: "Reports",
          icon: <FaReceipt />,
          show: userType === "Admin" || userType === "Staff",
        },
        {
          path: "/patient-report-view",
          name: "Specific Report",
          icon: <FaClipboardCheck />,
          show: userType === "Admin" || userType === "Staff",
        }
      ]
    },
    // {
    //   path: "/restraint-seculation",
    //   name: "Restraint/Seculation Order",
    //   icon: <FaNewspaper />,
    //   show: userType === "Admin" || userType === "Staff",
    // },

  ]
  const sos1 = [
    {
      path: "/sos-staff",
      name: "Staff SOS Assignment",
      icon: <FaStopwatch />,
      show: userType === "Staff" && orgData.sos,
    },
  ]
  const Receptionist = [
    {
      path: "/sos-view",
      name: "Dashboard",
      icon: <FaTachometerAlt />,
      show: userType === "Receptionist-sos" && orgData?.q15 && orgData.proximity && orgData.sos,
      newTab: true,
      fullScreen: true
    }
  ];
  const menuItem = ((orgData?.q15Access === 'Yes' && orgData?.sos) || (orgData?.q15Access === 'Yes' && !orgData?.sos))
    ? [
      ...Receptionist,
      ...superAdmin,
      ...baseMenu,
      ...q15AccessMenu,
      ...menu.filter(item => item.show && (orgData?.sos || item.name !== "SOS Staff" && item.name !== "SOS Call")),
      ...sos1,
      ...menu1
    ]
    : (orgData?.q15Access === 'No' && orgData?.sos)
      ? [
        ...superAdmin,
        ...baseMenu,
        ...menu.filter(item => item.show && orgData?.sos),
        ...sos1,
        ...Receptionist
      ]
      : [...superAdmin];
  const handleSubMenuClick = (index: number) => {
    setActiveSubmenu((prevActiveSubmenu) => {
      return prevActiveSubmenu === index ? null : index;
    });
  };
  const currentPageName = menuItem.find((item: any) => item.path === location.pathname)?.name;
  const subPageName = menuItem.reduce((subname: any, item: any) => {
    if (item.submenu) {
      const subItem = item.submenu.find((sub: any) => sub.path === location.pathname);
      if (subItem) {
        subname = subItem.name;
      }
    }
    return subname;
  }, '');

  const handleLogoutClick = () => {
    setOpen(true);
  };

  const handleConfirmLogout = () => {
    const body = { jwt, username };
    handleLogout(body, navigate, dispatch);
  };
  const handleClo = () => {
    setOpen(false)
  }

  return (
    <>
      {showSideBar && (
        <div className="container1">
          <div style={{ width: isOpen ? "230px" : "60px", display: patientView ? 'none' : 'flex', justifyContent: 'space-between', flexDirection: 'column' }} className="sidebar">
            <div>
              <div className="top_section" style={{ justifyContent: isOpen ? 'space-between' : 'center', height: '55px', borderBottom: '2px solid #0f3995' }}>
                <h1 style={{ display: isOpen ? "block" : "none", fontSize: '18px', marginTop: '5px' }} className="logo">
                  M H C
                </h1>
                <div style={{ marginLeft: isOpen ? "65px" : "0px", fontSize: '18px' }} className="bars">
                  <FaBars onClick={toggle} style={{ cursor: 'pointer' }} />
                </div>
              </div>
              <div className="hidden-scrollbar" style={{overflowY: 'scroll',height:'430px'}} >
              {menuItem.map((item: any, index: any) =>
                item.show ? (
                  <React.Fragment key={index}>
                    {item.submenu ? (
                      <div
                        onClick={() => handleSubMenuClick(index)}
                        className="link"
                        style={{ justifyContent: isOpen ? 'left' : 'center' }}
                      >
                        <div
                          className="icon"
                          style={{ fontSize: '15px' }}
                          title={item.name}
                        >
                          {item.icon}
                        </div>
                        <div
                          className="link_text"
                          style={{
                            display: isOpen ? 'block' : 'none',
                            fontSize: '13px',
                            position: 'relative',
                          }}
                          title={item.name}
                        >
                          {item.name}
                        </div>
                      </div>
                    ) : (
                      <NavLink
                        to={item.path}
                        key={index}
                        className="link"
                        target={item.newTab ? "_new" : "_self"}
                        rel={item.newTab ? "noopener noreferrer" : ""}
                        style={{ justifyContent: isOpen ? 'left' : 'center' }}
                      >
                        <div
                          className="icon"
                          style={{ fontSize: '15px' }}
                          title={item.name}
                        >
                          {item.icon}
                        </div>
                        <div
                          className="link_text"
                          style={{
                            display: isOpen ? 'block' : 'none',
                            fontSize: '13px',
                            position: 'relative',
                          }}
                          title={item.name}
                        >
                          {item.name}
                        </div>
                      </NavLink>
                    )}
                    {(activeSubmenu === index) &&
                      item.submenu &&
                      item.submenu.length > 0 && (
                        <div
                          className="submenu"
                          style={{ marginLeft: isOpen ? '30px' : '12px' }}
                        >
                          {item.submenu.map((subItem: any, subIndex: any) => (
                            subItem.show && (
                              <NavLink
                                to={subItem.path}
                                key={subIndex}
                                className="link"
                                target={subItem.newTab ? "_new" : "_self"}
                                rel={subItem.newTab ? "noopener noreferrer" : ""}
                              >
                                <div
                                  className="icon"
                                  style={{ fontSize: '15px' }}
                                  title={subItem.name}
                                >
                                  <span>{subItem.icon}</span>
                                </div>
                                <div
                                  className="link_text"
                                  style={{
                                    display: isOpen ? 'block' : 'none',
                                    fontSize: '13px',
                                    position: 'relative',
                                    top: '4px',
                                  }}
                                  title={subItem.name}
                                >
                                  {subItem.name}
                                </div>
                              </NavLink>
                            )
                          ))}
                        </div>
                      )}
                  </React.Fragment>
                ) : null
              )}
              </div>
            </div>
            <div  >
              {
                isOpen ?
                  <>
                    <MenuItem style={{ justifyContent: 'center', backgroundColor: white, color: '#FF0000', margin: '20px', borderRadius: '6px' }} onClick={handleLogoutClick}>
                      <ListItemIcon>
                        <Logout fontSize="small" style={{ color: '#FF0000' }} />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                    <div style={{ justifyContent: 'center', marginLeft: '15px' }}>
                      <ListItemIcon>
                        <img
                          src={logo192}
                          alt="Logo"
                          style={{ width: '15px', marginBottom: '10px' }}
                        />
                        &nbsp;
                        <span style={{
                          color: '#FFF', textAlign: 'center',
                          fontSize: '12px',
                          marginBottom: '10px',
                        }}>{`\u00A9 2024 Mettler Health Tech Inc.`}</span>
                      </ListItemIcon>
                    </div>
                  </>
                  :
                  <MenuItem style={{ justifyContent: 'center', backgroundColor: white, color: '#FF0000', margin: '10px', borderRadius: '6px' }} onClick={handleLogoutClick}>
                    <ListItemIcon style={{ justifyContent: 'center' }}>
                      <Logout fontSize="small" style={{ color: '#FF0000' }} />
                    </ListItemIcon>
                  </MenuItem>
              }

            </div>
            <LogoutConfirmationModal
              open={open}
              handleConfirm={() => handleConfirmLogout()}
              handleClose={handleClo}
              message="Are you sure you want to logout?"
            />
          </div>
          <div
            className="w-100 hidden-scrollbar"
            style={{
              marginLeft: patientView ? '0px' : (isOpen ? "230px" : "66px"),
              marginTop: 0,
              overflowY: "auto",
              height: '100vh'
            }}
          >
            <Header currentPage={currentPageName || ""} subPage={subPageName} isSidebarOpen={isOpen} />
            {children}
          </div>
        </div>
      )}
      <ResizeHandler setIsOpen={setIsOpen} />
    </>
  );
};

export default Sidebar;