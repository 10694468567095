import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import React, { useEffect, useState } from "react";
import ShiftListTable from "./shiftListTable";
import { Button } from "reactstrap";
import { primarybg } from "../../common/primary";
import { DatePicker } from "@mui/x-date-pickers";
import { getStaffDropdown } from "../../slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";
import { authorize } from "../../helpers/common";
import axios from "axios";
import { baseURL, successCode } from "../../configuration/url";
import { toast } from "react-toastify";
import DateBox from "../../components/calendar/dateBox";
import moment from "moment";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "0 24px 24px 24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const TeamAllocation = () => {
  const [value, setValue] = React.useState(0);
  const [openAddStaff, setOpenAddStaff] = useState(false);
  const [Loading, setLoading] = useState<boolean>(false);
  const [allTeamData, setAllTeamData] = useState<any>([]);
  const { header1 } = authorize();
  const [selectedDate, setSelectedDate] = useState<any>();
  const [noOfShift, setNoOfShift] = useState<any>(0);
  const dispatch = useDispatch<any>();
  const { organization } = useSelector((state: any) => state.Login);
  const [staffDropdown, setStaffDropdown] = useState<any>([]);
  const org = useSelector((state: any) => state.Login.organization);
  const { orgData, shiftStartTime } = useSelector((state: any) => state.Org);

  const handleNewClose = () => {
    setOpenAddStaff(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [selectedValues, setSelectedValues] = useState<any>([]);
  const [selectTeam, setSelectTeam] = useState("");
  const [staffError, setStaffError] = useState(false);
  const [teamError, setTeamError] = useState(false);

  const isAllSelected =
    staffDropdown?.length > 0 &&
    selectedValues.length === staffDropdown?.length;
  const handleselectChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      let allData = staffDropdown?.map((data: any) => data);
      setSelectedValues(
        selectedValues.length === staffDropdown?.length ? [] : allData
      );
      return;
    }
    if (value.length === 0) {
      setStaffError(true);
    } else {
      setStaffError(false);
    }
    setSelectedValues(value);
  };

  const handleSelectTeam = (event: any) => {
    setSelectTeam(event.target.value);
    if (event.target.value === 0) {
      setTeamError(true);
    } else {
      setTeamError(false);
    }
  };

  const getAllStaffByTeam = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/shift/getshiftStaffDropdowns?organization=${org}&date=${moment(
          selectedDate
        ).format("YYYYMMDD")}&shiftName=${renderTabContent()}`,
        { headers: header1 }
      );
      if (response.data.message.code === successCode) {
        setStaffDropdown(response.data.data);
      } else {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  function formatDate(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 as months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0"); // Padding day to ensure 2 digits

    return `${year}${month}${day}`;
  }
  const getAllTeam = async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${baseURL}/shift/getshiftStaffDropdowns?organization=${org}&date=${moment(
          selectedDate
        ).format("YYYYMMDD")}&shiftName=${renderTabContent()}`,
        { headers: header1 }
      );
      if (response.data.message.code === successCode) {
        // const teams = ["Shift-A", "Shift-B", "Shift-C", "General"];
        // const filteredData: any = {};

        // teams.forEach((shiftName) => {
        //   const team = response.data.data.content?.filter(
        //     (data: any) => data?.shiftName === shiftName
        //   );
        //   filteredData[shiftName] = team?.length > 0 ? team : [];
        // });
        const sortedData = [...response.data.data].sort(
          (a, b) => b?.checkIn - a?.checkIn
        );
        setAllTeamData(sortedData);
        setValue(value);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCreateTeam = async () => {
    setLoading(true);
    let valid = true;

    if (selectTeam === "") {
      setTeamError(true);
      valid = false;
    }

    if (selectedValues.length === 0) {
      setStaffError(true);
      valid = false;
    }

    if (valid) {
      try {
        setLoading(true);

        const staffIds = selectedValues.map((staff: any) => ({
          staffId: staff.id, // Renaming 'id' to 'staffId'
        }));

        let formData = {
          organization: organization,
          staff: staffIds,
          teamName: selectTeam,
        };

        const response = await axios.post(
          `${baseURL}/shift/register`,
          formData,
          { headers: header1 }
        );
        if (
          response.data.message &&
          response.data.message.code === successCode
        ) {
          toast.success(response.data.message.description);
          handleNewClose();
          setLoading(false);
          setTeamError(false);
          setStaffError(false);
          setSelectedValues([]);
          setSelectTeam("");
          getAllTeam();
          getAllStaffByTeam();
        } else {
          console.error("Error:", response.data.message);
        }
      } catch (error) {
        console.error("API Request Error:", error);
      }
    }

    setLoading(false);
  };

  const getNumberOfShifts = (duration: number): number => {
    if (duration <= 0) {
      throw new Error("Duration must be a positive number.");
    }

    return 24 / duration; // Return the number of shifts
  };
  useEffect(() => {
    const handlestartTime = () => {
      const crtime: any = localStorage.getItem("LTime");
      if (crtime) {
        const formattedDate = moment(crtime).format("YYYY-MM-DD");
        setSelectedDate(formattedDate);
      }
    };
    handlestartTime();
    let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
    setNoOfShift(numShift);
  }, []);

  useEffect(() => {
    getAllStaffByTeam();
    getAllTeam();
  }, [selectedDate, value]);

  const handleDateChange = (e: any) => {
    const newDate = e.target.value;
    setSelectedDate(e.target.value);

    if (!newDate) {
      if (!newDate) {
        return;
      }

      try {
        getAllTeam();
        getAllStaffByTeam();
      } catch (error) {
        console.error("API Error : ", error);
        console.error("API Error : ", error);
      }
    }
  };

  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };

  const renderTabContent = () => {
    switch (value) {
      case 0:
        return "Shift-A";
      case 1:
        return "Shift-B";
      case 2:
        return "Shift-C";
      case 3:
        return "Shift-D";
      case 4:
        return "Shift-E";
      case 5:
        return "Shift-F";
      case 6:
        return "General";
      default:
        return "No Content Available";
    }
  };

  const [shifts, setShifts] = useState<any>([]);
  const generateShifts = (
    startTimeStr: any,
    duration: any,
    totalShifts: any
  ) => {
    const shifts = [];

    let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

    for (let i = 0; i < totalShifts; i++) {
      let endTime = new Date(
        startTime.getTime() + Number(duration) * 60 * 60 * 1000
      );
      const startTimeFormatted = startTime.toTimeString().slice(0, 5);
      const endTimeFormatted = endTime.toTimeString().slice(0, 5);
      const shiftName = `Shift-${String.fromCharCode(65 + i)}`;
      shifts.push({
        shift_name: shiftName,
        start_time: startTimeFormatted,
        end_time: endTimeFormatted,
      });
      startTime = endTime;
    }
    setShifts(shifts);
  };
  useEffect(() => {
    generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
  }, [orgData, noOfShift])
  const [dateRange, setDateRange] = useState<any>()
  useEffect(() => {
    if (!shifts) return
    setDateRange(shifts[value])
  }, [shifts, value])
  return (
    <>
      {Loading && <Loader />}

      <div style={{ marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
            width: "90%",
            position: "relative",
            left: "66px",
          }}
        >
          <DateBox
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleDateChange={handleDateChange}
            countCheck={null}
            dateRange={dateRange}
          />
        </div>

        <Box
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            height: "auto",
            marginTop: "20px",
          }}
        >
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            {Array.from({ length: noOfShift }).map((_, index) => (
              <Tab
                key={index}
                label={`Shift-${String.fromCharCode(65 + index)}`} // Shift-A, Shift-B, etc.
                value={index}
                {...a11yProps(index)}
              />
            ))}

            {/* )} */}

            <Tab label="General" value={6} {...a11yProps(6)} />
          </Tabs>

          {Array.from({ length: noOfShift }).map((_, index) => (
            <TabPanel value={value} index={index}>
              <ShiftListTable
                value={renderTabContent()}
                allTeamData={allTeamData}
                selectedDate={selectedDate}
                staffDropdown={staffDropdown}
                getAllStaffByTeam={getAllStaffByTeam}
                getAllTeam={getAllTeam}
                noOfShift={noOfShift}
              />
            </TabPanel>
          ))}

          <TabPanel value={value} index={6}>
            <ShiftListTable
              value={renderTabContent()}
              allTeamData={allTeamData}
              selectedDate={selectedDate}
              staffDropdown={staffDropdown}
              getAllStaffByTeam={getAllStaffByTeam}
              getAllTeam={getAllTeam}
              noOfShift={noOfShift}
            />
          </TabPanel>
        </Box>

        <Dialog
          maxWidth={"lg"}
          PaperProps={{
            sx: {
              width: "595px",
              maxHeight: "calc(100% - 40px)",
              top: 25,
            },
          }}
          className="p-5"
          open={openAddStaff}
          onClose={handleNewClose}
        >
          <DialogTitle>Add Shift</DialogTitle>
          <DialogContent style={{ overflowY: "auto" }} className="p-4">
            <DialogContentText style={{ paddingLeft: "15px" }}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel color="primary">Staff Name</InputLabel>
                    <Select
                      error={staffError}
                      placeholder="Select staff"
                      multiple
                      value={selectedValues} // This should be an array of selected values
                      onChange={handleselectChange}
                      color="primary"
                      size="medium"
                      label="Staff Name"
                      name="staff_name"
                      renderValue={(selectedValues) =>
                        selectedValues
                          .map((value: any) => value?.name)
                          .join(", ")
                      }
                    >
                      {/* Select All / Unselect All MenuItem */}
                      <MenuItem value="all">
                        <ListItemIcon>
                          <Checkbox
                            checked={isAllSelected}
                            indeterminate={
                              selectedValues.length > 0 &&
                              selectedValues.length < staffDropdown?.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary="Select All" />
                      </MenuItem>

                      {/* Render other staff options */}
                      {staffDropdown?.map((newData: any, i: number) => (
                        <MenuItem key={i} value={newData}>
                          <Checkbox
                            checked={selectedValues?.indexOf(newData) > -1} // Check if this item is selectedValues
                          />
                          <ListItemText primary={newData?.name} />
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText />
                  </FormControl>
                  {staffError && (
                    <span className="form-error">Please select staff</span>
                  )}
                </Grid>
                <Grid item md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel color="primary">Shift Name</InputLabel>
                    <Select
                      error={teamError}
                      value={selectTeam} // This should be an array of selected values
                      onChange={handleSelectTeam}
                      placeholder="Select staff"
                      color="primary"
                      size="medium"
                      label="Shift Name"
                      name="team_name"
                    >
                      <MenuItem value="Shift-A">Shift-A</MenuItem>
                      <MenuItem value="Shift-B">Shift-B</MenuItem>
                      <MenuItem value="Shift-C">Shift-C</MenuItem>
                      <MenuItem value="General">General</MenuItem>
                    </Select>
                    <FormHelperText />
                  </FormControl>
                  {teamError && (
                    <span className="form-error">Please select shift</span>
                  )}
                </Grid>
                {/* <Grid item md={6}>
                <DatePicker label="Start Date" />
              </Grid>
              <Grid item md={6}>
                <DatePicker label="End date" />
              </Grid> */}
              </Grid>
              {/* <Grid item md={12} sx={{ marginY: "15px" }}>
              {selectedValues?.length > 0 && (
                <>
                  {selectedValues?.map((data: any) => {
                    const firstLetter = data.charAt(0).toUpperCase();
                    return (
                      <>
                        <Chip
                          style={{ marginLeft: "5px" }}
                          variant="outlined"
                          label={`${data}`}
                          color="primary"
                          size="small"
                          avatar={<Avatar>{`${firstLetter}`}</Avatar>}
                        />
                      </>
                    );
                  })}
                </>
              )}
            </Grid> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "10px",
                  paddingBottom: "15px",
                  paddingRight: "15px",
                }}
                className="mt-4"
              >
                <Button style={{ cursor: "pointer" }} onClick={handleNewClose}>
                  Cancel
                </Button>
                <Button
                  style={{
                    cursor: "pointer",
                    backgroundColor: primarybg,
                    borderColor: "transparent",
                  }}
                  onClick={() => handleCreateTeam()}
                >
                  Save
                </Button>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default TeamAllocation;
