import React, { useEffect, useRef, useState } from "react";
import Calendar from "../../components/calendar";
import './staffconfig.css'
import { useDispatch, useSelector } from "react-redux";
import { BrowserMultiFormatReader } from "@zxing/library";
import { toast } from 'react-toastify';
import axios from 'axios';
import { getAllRNIncharge, getAllSocialWorkers } from "../../slices/thunk";
import ErrorPopup from "../../components/errorPopup";
import { closeErrorPopup } from "../../slices/staffConfiguration/reducer";
import { getOrgByID } from "../../slices/organization/thunk";
import { saveOrganization } from "../../slices/login/reducer";
import { HttpLogin } from "../../utils/Http";
import { Button } from "primereact/button";
import Modal from 'react-bootstrap/Modal';
import { baseURL, successCode } from "../../configuration/url";
import { FormControl, Input, InputLabel, MenuItem, Select } from "@mui/material";
import calendarMuiImage from '../../assets/images/calendarMuiImage.svg';
import scanimg from '../../assets/images/scan.svg';
import { authorize } from "../../helpers/common";
import { Autocomplete, TextField, Tooltip, Menu } from "@mui/material";
import { secondarybt } from "../../common/primary";
import Loader from "../../components/loader/Loader";
import moment from "moment";
import DateBox from "../../components/calendar/dateBox";
import { Box, Tabs, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import ShiftView from "./ShiftView";
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ width: "83%", backgroundColor: '#f2f5f9', borderLeft: '1px solid #0303031f' }}
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ padding: "14px 24px 24px 24px" }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Q15StaffConfiguration = () => {
    const dispatch = useDispatch<any>()
    const { loading, shiftData, isOpen, errorMsg } = useSelector((state: any) => state.PSConfig)
    const { shiftStartTime } = useSelector((state: any) => state.Org)
    const { organization } = useSelector((state: any) => state.Login)
    const [selectedDate, setSelectedDate] = useState<any>();
    const [Loading, setLoading] = useState<boolean>(false)
    const { orgTimeZone } = useSelector((state: any) => state.LargeScreen)
    let [incharge, setIncharge] = useState<any>('');
    let [newIndex, setNewIndex] = useState<any>('');
    let [newRegister, setNewRegister] = useState<any>('');

    const { rnInchargeList, socialWorkerList } = useSelector((state: any) => state.PSConfig)


    const [selectedTab, setSelectedTab] = useState('Shift-A');
    const { userData } = useSelector((state: any) => state.Login)
    const { orgData } = useSelector((state: any) => state.Org)
    const [noOfShift, setNoOfShift] = useState<any>(0);
    const closePopup = () => {
        dispatch(closeErrorPopup())
    }
    const [value, setValue] = React.useState(0);
    const handleChange = (newValue: number) => {
        setValue(newValue);
    };

    const videoRef = useRef(null);
    const codeReader = new BrowserMultiFormatReader();
    const [scanning, setScanning] = useState(false);

    const getNumberOfShifts = (duration: number): number => {
        if (duration <= 0) {
            throw new Error("Duration must be a positive number.");
        }
        return 24 / duration; // Return the number of shifts
    };
    useEffect(() => {
        const handlestartTime = () => {
            const crtime: any = localStorage.getItem('LTime');
            if (crtime) {
                const formattedDate = moment(crtime).format('YYYY-MM-DD');
                setSelectedDate(formattedDate);
            }
        };
        handlestartTime();


        let numShift = getNumberOfShifts(Number(orgData?.shift?.duration));
        setNoOfShift(numShift);
    }, []);
    useEffect(() => {
        saveOrganization(dispatch)
        getAllRNIncharge(dispatch, 'Registered Nurse', organization)
        getAllSocialWorkers(dispatch, 'Social Worker', organization)
        getOrgByID(dispatch, organization)
    }, [dispatch, selectedDate])

    const handleDateChange = (e: any) => {
        const { header1 } = authorize();
        const newDate = e.target.value;
        setSelectedDate(e.target.value);
    }

    const handleQrClick = (index: any, newSlotRegister: any) => {
        setScanning(!scanning);
        setNewIndex(index);
        setNewRegister(newSlotRegister);
    }

    const [shifts, setShifts] = useState<any>([]);
    const generateShifts = (
        startTimeStr: any,
        duration: any,
        totalShifts: any
    ) => {
        const shifts = [];

        let startTime = new Date(`1970-01-01T${startTimeStr}:00`);

        for (let i = 0; i < totalShifts; i++) {
            let endTime = new Date(
                startTime.getTime() + Number(duration) * 60 * 60 * 1000
            );
            const startTimeFormatted = startTime.toTimeString().slice(0, 5);
            const endTimeFormatted = endTime.toTimeString().slice(0, 5);
            const shiftName = `Shift-${String.fromCharCode(65 + i)}`;
            shifts.push({
                shift_name: shiftName,
                start_time: startTimeFormatted,
                end_time: endTimeFormatted,
            });
            startTime = endTime;
        }
        setShifts(shifts);
    };
    useEffect(() => {
        generateShifts(shiftStartTime, orgData?.shift?.duration, noOfShift);
    }, [orgData])
    const [dateRange, setDateRange] = useState<any>()
    useEffect(() => {
        if (!shifts) return
        setDateRange(shifts[value])
    }, [shifts, value])
    return (
        <React.Fragment >
            <div>
                {Loading && <Loader />}
                {Loading && <Loader />}
                {loading && <Loader />}
                <h5>Q15-Staff Assignment</h5>
                <div style={{ display: 'flex', justifyContent: "space-evenly", padding: "10px", width: "90%", position: "relative", left: "66px" }}>
                    <DateBox selectedDate={selectedDate} setSelectedDate={setSelectedDate} handleDateChange={handleDateChange} countCheck={null} dateRange={dateRange} />
                </div>
                <div>
                    <div style={{ display: "flex", backgroundColor: "#eaf2fa", borderRight: "1px groove #ccd2d8" }}>
                        <div style={{ width: '15%', display: "flex", flexDirection: "column", paddingTop: "20px", alignItems: "center" }}>
                            {Array.from({ length: noOfShift }).map((_, index) => (
                                <button
                                    className={`nav-link ${value === index ? 'active' : ''}`}
                                    id={`Shift-${String.fromCharCode(65 + index)}-tab`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`Shift-${String.fromCharCode(65 + index)}`}
                                    aria-selected={value === index}
                                    onClick={() => handleChange(index)}
                                    style={{ backgroundColor: value === index ? '#cddcfa' : '', color: value === index ? '' : 'black', width: "91%", height: "40px", borderRadius: "4px" }}
                                >
                                    {`Shift-${String.fromCharCode(65 + index)}`}
                                </button>
                            ))}
                        </div>
                        {Array.from({ length: noOfShift }).map((_, index) => (
                            <TabPanel value={value} index={index}>
                                <ShiftView selectedTab={value} selectedDate={selectedDate} noOfShift={noOfShift} rnInchargeList={rnInchargeList} incharge={incharge} setIncharge={setIncharge} socialWorkerList={socialWorkerList} setLoading={setLoading} />
                            </TabPanel>
                        ))}
                    </div>
                </div>
                <ErrorPopup
                    closePopup={closePopup}
                    errorMsg={errorMsg}
                    open={isOpen}
                />
            </div>
        </React.Fragment>
    );
};

export default Q15StaffConfiguration;